import {SeriesArearangeOptions, SeriesAreaOptions, SeriesLineOptions, SeriesColumnOptions} from 'highcharts/highstock';
import React from 'react';

import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {ActivePeriod} from '../../components/PeriodSelector';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {ILocationSummary} from '../../models/Location';

import {BrandColors} from '../../utils/BrandColors';
import {createPointFormatter} from '../../utils/GraphUtils';

import {IGeneratedDataEntry} from './GeneratedData';

interface DemoGraphProps {
  location: ILocationSummary;
  period: ActivePeriod;
  consumption: IConsumptionValue[];
  generatedData: IGeneratedDataEntry[];
  sides: string[];
}
export default (props: DemoGraphProps) => {
  const {period, consumption, generatedData, sides} = props;

  const series: (SeriesLineOptions | SeriesColumnOptions | SeriesArearangeOptions | SeriesAreaOptions)[] = [];

  series.push({
    id: 'solar',
    type: 'line',
    data: consumption.map(value => [value.timestamp, (value.solar || 0) / 1000]),
    name: 'Solar [kW]',
    color: BrandColors.GreenAtlantis, // Atlantis
    yAxis: 0,
    tooltip: {
      pointFormatter: createPointFormatter('Solar', 'kW', 1)
    }
  });
  sides.forEach((side, index) => {
    series.push({
      id: `side${index}`,
      type: 'area',
      data: generatedData.map(value => [value.timestamp, value.values[index] / 1000]),
      name: `${side} [kW]`,
      stacking: 'normal',
      yAxis: 0,
      tooltip: {
        pointFormatter: createPointFormatter(side, 'kW', 1)
      }
    });
  });
  series.push({
    id: 'consumption',
    type: 'area',
    data: consumption.map(value => [value.timestamp, (value.value || 0) / 1000]),
    name: 'Consumption [kW]',
    color: BrandColors.OrangeSunglow, // Sunglow
    stacking: 'normal',
    yAxis: 0,
    tooltip: {
      pointFormatter: createPointFormatter('Consumption', 'kW', 1)
    }
  });
  const options = {
    period,
    series,
    yAxis: [
      {
        title: {text: 'kW'},
        opposite: false,
        showEmpty: false,
        startOnTick: false
      }
    ]
  };
  const [config, actualFrom, actualTo] = createIntervalChart(options);
  return <HighStockChart from={actualFrom} to={actualTo} config={config} />;
};
