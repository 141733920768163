import React from 'react';

import {RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';

import {OnlineStatusIndicator} from '../../components/OnlineStatusIndicator';
import {SortOrder, IPersistedTableSettings} from '../../components/Table';
import {IPowerMessage} from '../../livedata/LiveDataModels';
import {ChargingStation, summaryChargingStationPublicCharging} from '../../models/ChargingStation';
import {CarChargingStationChargingStateMqttMessage} from '../../models/ChargingStatus';
import {OnlineStatus} from '../../models/OnlineStatus';
import {SmartDeviceConnectionStatus} from '../../models/SmartDevice';
import {ITableField, CalculatedStringField, FieldAlignment, ComponentField} from '../../models/Table';
import {T} from '../../utils/Internationalization';

import {ChargingStationNameField} from './ChargingStationNameField';
import ChargingStatusField from './ChargingStatusField';

export interface IChargingStationWithLiveData {
  station: ChargingStation;
  status: (CarChargingStationChargingStateMqttMessage | undefined)[];
  power?: IPowerMessage;
  perSidePower: (IPowerMessage | undefined)[];
  onlineStatus?: OnlineStatus;
  connectionStatus: SmartDeviceConnectionStatus[];
}

function renderActionColumn(
  readOnly: boolean,
  item: IChargingStationWithLiveData,
  onClickedEnable: (item: ChargingStation) => void,
  onClickedDisable: (item: ChargingStation) => void
) {
  return (
    <RowActions>
      {!readOnly && (
        <>
          <RowActionButton
            icon="Ban"
            title={T('chargingStations.disable')}
            onClick={() => onClickedDisable(item.station)}
          />
          <RowActionButton
            icon="PowerOff"
            title={T('chargingStations.enable')}
            onClick={() => onClickedEnable(item.station)}
          />
        </>
      )}
    </RowActions>
  );
}

export function getTableColumns(
  currentLocationId: number | undefined,
  readOnly: boolean,
  onClickedEnable: (item: ChargingStation) => void,
  onClickedDisable: (item: ChargingStation) => void
): ITableField<IChargingStationWithLiveData>[] {
  return [
    new ChargingStationNameField('name', T('chargingStation.name'), currentLocationId),
    new CalculatedStringField(
      'articleNumber',
      T('chargingStation.articleNumber'),
      item => item.station.data.articleCode
    ),
    new CalculatedStringField(
      'serialNumber',
      T('chargingStation.serialNumber'),
      item => item.station.data.serialNumber
    ),
    new CalculatedStringField('paymentMethod', T('chargingStation.paymentMethod'), item =>
      summaryChargingStationPublicCharging({
        paymentTypes: item.station.data.paymentTypes
      })
    ),
    new ChargingStatusField('statusRight', T('chargingStation.status.connectorX', {connector: '1'}), 1),
    new ChargingStatusField('statusLeft', T('chargingStation.status.connectorX', {connector: '2'}), 2),
    new ComponentField('status', T('chargingStation.status'), item =>
      item.onlineStatus === undefined ? undefined : <OnlineStatusIndicator status={item.onlineStatus} />
    ),
    new ComponentField(
      'actions',
      T('chargingStation.actions'),
      item => renderActionColumn(readOnly, item, onClickedEnable, onClickedDisable),
      {align: FieldAlignment.Center, autoInsert: 'end'}
    )
  ];
}

export const rowKey = (station: IChargingStationWithLiveData) => station.station.data.serialNumber;

export const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'name',
  sortOrder: SortOrder.ASCENDING,
  columns: [
    {name: 'serialNumber', visible: true},
    {name: 'name', visible: true},
    {name: 'articleNumber', visible: false},
    {name: 'paymentMethod', visible: false},
    {name: 'minimumCapacity', visible: false},
    {name: 'maximumCapacity', visible: false},
    {name: 'statusRight', visible: true},
    {name: 'statusLeft', visible: true}
  ]
};
