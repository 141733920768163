import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';

import Autosuggest from 'react-autosuggest';

import {useAppContext} from '../../../app/context';
import autocompleteTheme from '../../../components/AutoComplete.module.scss';
import {Button as RsButton, Input} from '../../../components/bootstrap';

import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {ChargingStationOperator} from '../../../models/ChargingStation';
import {IOrganization} from '../../../models/Organization';
import {None} from '../../../utils/Arrays';
import {T} from '../../../utils/Internationalization';

interface OperatorEditorProps {
  value: ChargingStationOperator | undefined;
  onSelected: (value: ChargingStationOperator | undefined) => Promise<any>;
  readOnly: boolean;
}

export function OperatorEditor(props: OperatorEditorProps) {
  const {value, onSelected, readOnly} = props;
  const {api} = useAppContext();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>(None);
  const [selectedValue, setSelectedValue] = useState<IOrganization>();

  useEffect(() => {
    api.operators.getAll().then(orgs => {
      orgs.sort((a, b) => a.name.localeCompare(b.name));
      setOrganizations(orgs);
    });
  }, [api]);
  useEffect(() => {
    setSelectedValue(value ? organizations.find(org => org.id === value.id) : undefined);
  }, [organizations, value]);

  const handleClickedEdit = () => {
    setEditing(true);
  };

  const handleClickedCancel = () => {
    setSelectedValue(undefined);
    setEditing(false);
  };

  const handleClickedSave = () => {
    const value: ChargingStationOperator | undefined = selectedValue
      ? {
          id: selectedValue.id,
          name: selectedValue.name,
          phoneNumber: selectedValue.supportPhoneNumber || ''
        }
      : undefined;
    setLoading(true);
    onSelected(value)
      .then(() => {
        setSelectedValue(undefined);
        setEditing(false);
      })
      .finally(() => setLoading(false));
  };

  const handleOrganizationSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(organizations.find(o => o.id.toString() === e.currentTarget.value));
  };

  if (editing) {
    return (
      <div style={{display: 'flex'}}>
        <Input
          type="select"
          value={selectedValue ? selectedValue.id.toString() : ''}
          onChange={handleOrganizationSelected}
          style={{width: 250}}
        >
          <option key="__none__" value="">
            (No CPO)
          </option>
          {organizations.map(org => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </Input>
        <RsButton color="primary" onClick={handleClickedSave} style={{marginLeft: '0.3em'}}>
          {T('locationConfiguration.field.save')}
          {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
        </RsButton>
        <RsButton onClick={handleClickedCancel} style={{marginLeft: '0.3em'}}>
          {T('locationConfiguration.field.cancelEdit')}
        </RsButton>
      </div>
    );
  } else {
    return (
      <span>
        {value ? value.name : T('generic.na')}
        &nbsp;
        {!readOnly && (
          <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
            <Edit />
          </Button>
        )}
      </span>
    );
  }
}

interface OrganizationAutosuggestInputProps {
  organizations: IOrganization[];
  value: IOrganization | undefined;
  onChange: (value: IOrganization | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  noneLabel?: string;
  clearable?: boolean;
}

function OrganizationAutosuggestInput(props: OrganizationAutosuggestInputProps) {
  const {organizations, value, onChange} = props;

  const [inputValue, setInputValue] = useState<string>(value ? value.name : '');
  const suggestions = useMemo(
    () => organizations.filter(x => x.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [organizations, inputValue]
  );

  const renderSuggestion = useCallback((org: IOrganization) => {
    return <div>{org.name}</div>;
  }, []);

  const inputProps = {
    value: inputValue,
    className: 'form-control',
    onChange: (e: FormEvent<HTMLElement>) => setInputValue((e.currentTarget as HTMLInputElement).value)
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={params => setInputValue(params.value)}
      onSuggestionsClearRequested={() => setInputValue('')}
      onSuggestionSelected={(_, data) => onChange(data.suggestion)}
      getSuggestionValue={org => org.name}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      theme={autocompleteTheme}
    />
  );
}
