import dayjs from 'dayjs';

import 'dayjs/plugin/utc';
import {ChargingSessionExport} from '../../models/ChargingSessionExport';
import {translateChargingStationPaymentType} from '../../models/ChargingStation';
import {
  CalculatedNumberField,
  CalculatedStringField,
  DEFAULT_OPTIONS,
  IFieldOptions,
  IntegerField,
  ITableField,
  NumberField,
  StringField,
  TimestampFieldWithTimezone
} from '../../models/Table';
import {T} from '../../utils/Internationalization';

export function getColumns(timezone: string): ITableField<ChargingSessionExport>[] {
  return [
    new IntegerField('id', T('exportChargingSessions.id')),
    new StringField('uuid', T('exportChargingSessions.uuid')),
    new StringField('serialNumber', T('exportChargingSessions.serialNumber')),
    new IntegerField('position', T('exportChargingSessions.position')),
    new TimestampFieldWithTimezone('from', 'from', T('exportChargingSessions.from'), timezone),
    new TimestampFieldWithTimezone('to', 'to', T('exportChargingSessions.to'), timezone),
    new DurationField(),
    new StringField('status', T('exportChargingSessions.status')),
    new StringField('rfid', T('exportChargingSessions.rfid')),
    new IntegerField('csmsTransactionId', T('exportChargingSessions.transactionId')),
    new CalculatedStringField(
      'paymentType',
      T('exportChargingSessions.paymentType'),
      item => item.paymentType && translateChargingStationPaymentType(item.paymentType, !!item.splitBillingAgreementId)
    ),
    new StringField('paymentMethod', T('exportChargingSessions.paymentMethod')),
    new StringField('paymentStatus', T('exportChargingSessions.paymentStatus')),
    new CalculatedNumberField(
      'discount',
      T('exportChargingSessions.discount'),
      item => (item.discount === undefined ? undefined : item.discount * 100),
      {unit: '%', digitsAfterComma: 0}
    ),
    new NumberField('hostCost', T('exportChargingSessions.hostCost'), {digitsAfterComma: 2}),
    new NumberField('operatorCost', T('exportChargingSessions.operatorCost'), {digitsAfterComma: 2}),
    new NumberField('driverCost', T('exportChargingSessions.driverCost'), {digitsAfterComma: 2}),
    new StringField('currency', T('exportChargingSessions.currency')),
    new NumberField('energy', T('exportChargingSessions.energy'), {unit: 'kWh', digitsAfterComma: 3}),
    new StringField('organizationName', T('exportChargingSessions.organizationName')),
    new NumberField('splitBillingRefund', T('exportChargingSessions.splitBillingRefund'), {
      digitsAfterComma: 2
    })
  ];
}

export class DurationField implements ITableField<ChargingSessionExport> {
  name: string;
  label: string;
  options: IFieldOptions;

  constructor() {
    this.name = 'duration';
    this.label = T('generic.duration');
    this.options = DEFAULT_OPTIONS;
  }

  renderCellContent(item: ChargingSessionExport) {
    return this.toString(item);
  }

  getExportCSVValue(item: ChargingSessionExport) {
    return this.toString(item) || '';
  }

  getExportExcelValue(item: ChargingSessionExport) {
    return this.toString(item) || '';
  }

  toString(item: ChargingSessionExport) {
    if (item.to === undefined) return undefined;

    const duration = dayjs.duration(item.to - item.from);
    const hours = duration.asHours() | 0;
    const minutes = duration.get('minutes');
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }

  sort = (a: ChargingSessionExport, b: ChargingSessionExport): number => {
    const aDuration = a.to === undefined ? undefined : dayjs.duration(a.to - a.from);
    const bDuration = b.to === undefined ? undefined : dayjs.duration(b.to - b.from);
    const aMillis = aDuration ? aDuration.asSeconds() : 0;
    const bMillis = bDuration ? bDuration.asSeconds() : 0;
    return aMillis - bMillis;
  };
}
