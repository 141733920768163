import React, {useCallback, useMemo, useEffect} from 'react';

import {Input} from '../../components/bootstrap';
import PeriodSelector, {
  PeriodSettings,
  Period,
  PeriodRoundingMode,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import Table, {SortOrder, IPersistedTableSettings} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {IDevice, ChannelValueType} from '../../models/Device';
import {DeviceType} from '../../models/DeviceType';
import {LocationFeature} from '../../models/Location';
import {SensorReadingType, getReadingType} from '../../models/SensorReadings';
import {Interval} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {useLocationModules} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {T, plural} from '../../utils/Internationalization';
import {CardCategory, ICardType, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardLocationDetails} from '../CardUtils';
import {CardActions, ColumnChooser} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {getTableFields, rowKey} from './Columns';
import {InputModuleReadingsChart} from './InputModuleReadingsChart';

import {IInputModuleChartData, collectReadings} from './Models';

interface InputModuleValuesSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  moduleSerial?: string;
  series: string[];
}

interface ModuleSelectorProps {
  modules: IDevice[];
  value: string;
  onChange: (value: string) => void;
}
const ModuleSelector = (props: ModuleSelectorProps) => {
  const {modules, value, onChange} = props;
  const options = useMemo(
    () =>
      modules.map(module => (
        <option key={module.serialNumber} value={module.serialNumber}>
          {module.serialNumber}
        </option>
      )),
    [modules]
  );
  const handleChanged = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const hasOptions = options.length > 0;
  const placeholder = hasOptions ? '' : T('inputModules.error.noInputModules');

  return (
    <Input
      type="select"
      name="sensorId"
      value={value}
      onChange={handleChanged}
      disabled={!hasOptions}
      placeholder={placeholder}
    >
      {options}
    </Input>
  );
};

function getEffectiveTableSettings(table: IPersistedTableSettings, channelNames: string[]) {
  if (!table.columns.some(x => x.visible)) {
    return {
      ...table,
      columns: channelNames.map(channel => ({name: channel, visible: true}))
    };
  } else {
    return table;
  }
}

function InputModuleValues(props: ICardProps<InputModuleValuesSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {cardType, moduleSerial, interval, table} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.Appliances);
  const [modules] = useLocationModules(fetch, locationId);
  const inputModules = useMemo(
    () => modules && modules.filter(module => module.type === DeviceType.InputModule),
    [modules]
  );
  const moduleForSerial = inputModules && inputModules.find(module => module.serialNumber === moduleSerial);

  useEffect(() => {
    if (moduleForSerial === undefined && inputModules && inputModules.length > 0) {
      updateSettings({moduleSerial: inputModules[0].serialNumber});
    }
  }, [updateSettings, moduleForSerial, inputModules]);

  const actualPeriod = usePeriodRangeForTimezone(settings, locationTimezone, PeriodRoundingMode.EXCLUSIVE);

  const channels = useMemo(
    () =>
      (moduleForSerial &&
        moduleForSerial.input &&
        moduleForSerial.input.channels.filter(channel => channel.valueType === ChannelValueType.Cumulative)) ||
      None,
    [moduleForSerial]
  );
  const channelNames = useMemo(() => channels.map(channel => `channel${channel.id}`), [channels]);
  const effectiveTableSettings = useMemo(() => getEffectiveTableSettings(table, channelNames), [table, channelNames]);

  const [data, refreshData] = useCardLoader<IInputModuleChartData | undefined>(
    api => {
      const module = moduleForSerial;
      if (!locationId || !module || !module.input || !actualPeriod) {
        return Promise.resolve(undefined);
      }

      return Promise.all(
        channels.map(channel =>
          api.getSensorReadings(
            [getReadingType(channel.type) || SensorReadingType.InputModuleCustom],
            locationId,
            actualPeriod.from,
            actualPeriod.to,
            interval,
            channel.sensor.id,
            channel.id
          )
        )
      ).then(readings => ({
        channels,
        readings: collectReadings(readings),
        period: actualPeriod,
        module
      }));
    },
    [locationId, actualPeriod, moduleForSerial],
    plural('inputModuleReading'),
    undefined
  );
  const {readings = None} = data || {};

  const handleModuleChanged = (moduleSerial: string) => {
    updateSettings({moduleSerial});
  };

  const columns = useMemo(() => getTableFields(channels, locationTimezone || 'UTC'), [channels, locationTimezone]);

  const actions: CustomActions = state => (
    <CardActions>
      {modules !== undefined && isFeatureAvailable && (
        <ModuleSelector modules={inputModules || None} value={moduleSerial || ''} onChange={handleModuleChanged} />
      )}
      {isFeatureAvailable && <PeriodSelector settings={settings} onChanged={updateSettings} />}
      {isFeatureAvailable && <Reload onReload={refreshData} />}
      {state.ready && isFeatureAvailable && (
        <ExportCsv
          name={state.title}
          fields={columns}
          items={readings}
          range={settings}
          location={location}
          settings={settings.table}
        />
      )}
    </CardActions>
  );

  const renderSettings: CustomSettings<InputModuleValuesSettings> = (settings, updateSettings) => {
    const {cardType, moduleSerial, table} = settings;

    const handleCardTypeChanged = (cardType: CardDisplayType) => {
      updateSettings({cardType});
    };

    const handleModuleChanged = (moduleSerial: string) => {
      updateSettings({moduleSerial});
    };

    return (
      <div>
        <CardTypeSelector value={cardType} onChange={handleCardTypeChanged} />
        <ModuleSelector modules={inputModules || None} value={moduleSerial || ''} onChange={handleModuleChanged} />
        <ColumnChooser settings={table} fields={columns} updateSettings={table => updateSettings({table})} />
      </div>
    );
  };

  let error: string | undefined;
  if (inputModules !== undefined && inputModules.length === 0) {
    error = T('inputModules.error.noInputModules');
  }

  if (!isFeatureAvailable) {
    error = T('inputModules.error.noInputModulesFeature');
  }

  return (
    <CardView
      ready={inputModules !== undefined}
      actions={actions}
      customSettings={renderSettings}
      error={error}
      {...cardViewProps(props)}
    >
      {cardType === CardDisplayType.Chart ? (
        data && <InputModuleReadingsChart data={data} tableSettings={effectiveTableSettings} />
      ) : (
        <Table
          fields={columns}
          items={readings}
          rowKey={rowKey}
          noun="inputModuleReading"
          settings={effectiveTableSettings}
          updateSettings={table => updateSettings({table})}
        />
      )}
    </CardView>
  );
}

const DEFAULT_SETTINGS: InputModuleValuesSettings = {
  cardType: CardDisplayType.Chart,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  series: [],
  table: {
    pageSize: 10,
    sortColumn: 'time',
    sortOrder: SortOrder.DESCENDING,
    columns: []
  }
};

const CARD: ICardType<InputModuleValuesSettings> = {
  type: CardTypeKey.InputModuleValues,
  title: 'inputModules.title',
  description: 'inputModules.description',
  categories: [CardCategory.GAS, CardCategory.WATER, CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: InputModuleValues
};
export default CARD;
