import React, {useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col, ModalFooter} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ICustomFile} from '../../models/CustomFile';

import {T} from '../../utils/Internationalization';

import styles from './UploadCustomFileModal.module.scss';

interface IUploadCustomFileModalProps extends IPromiseModalProps<ICustomFile[] | undefined> {
  locationId: number;
}

export const UploadCustomFileModal = (props: IUploadCustomFileModalProps) => {
  const {locationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const handleClose = () => resolve(undefined);

  const [tag, setTag] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist(); // for debugging purposes
    const files = e.currentTarget.files;
    const file = files && files[0];
    setFile(file);
  };

  const handleTagChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setTag(e.currentTarget.value);
  };

  const handleClickedUpload = async () => {
    if (!file) return;

    try {
      const files = await api.uploadFile(locationId, file);
      const uploaded = files[0];
      await api.attachCustomFileToLocation(locationId, {
        tag,
        url: uploaded.fileName
      });
      NotificationManager.success(T('locationConfiguration.customFile.success'));
      api.getCustomFiles(locationId).then(files => resolve(files || []));
    } catch {
      setErrorMessage(T('locationConfiguration.customFile.failed'));
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader>Upload custom file</ModalHeader>
      <ModalBody>
        <FormGroup className={styles.files}>
          <input type="file" name="file" onChange={handleFileChanged} />
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Tag</Label>
          <Col sm={9}>
            <Input value={tag} onChange={handleTagChanged} />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter error={errorMessage}>
        <RsButton onClick={handleClose} className="t-confirm-reject">
          Cancel
        </RsButton>
        <RsButton color="primary" onClick={handleClickedUpload} className="t-confirm-accept" disabled={file === null}>
          Upload
        </RsButton>
      </ModalFooter>
    </Modal>
  );
};
