import React from 'react';

import {Button} from '../../components/bootstrap';

import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {useLocation} from '../../utils/FunctionalData';
import {getCurrentLanguage, T} from '../../utils/Internationalization';
import {dashboardStyle} from '../../utils/Styles';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {cardViewProps, CardView} from '../components/CardView';

import icons from './Icons.module.scss';
import styles from './index.module.scss';

const PartnerInfo = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;
  const locationId = useCardLocationId(settings);
  const [locationDetails] = useLocation(fetch, locationId);

  const partner = (locationDetails || {organization: undefined}).organization;
  if (!partner || partner.internal) return <div />;

  const colors = (partner ? partner.colors : undefined) || {
    font: '#000000',
    background: '#ffffff',
    buttonFont: '#ffffff',
    buttonBackground: '#155a55'
  };
  const {font: fontColor, background: backgroundColor, buttonFont, buttonBackground} = colors;

  return (
    <CardView
      title={T('partnerInfo.contentTitle', {name: ''})}
      style={{backgroundColor}}
      noHeader={true}
      {...cardViewProps(props)}
    >
      {partner ? (
        <div className={styles.infoMain} style={{color: fontColor}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {partner.logo && <img src={partner.logo} className={styles.icon} alt={T('partnerInfo.logo.alt')} />}
            <h3 style={{marginBottom: 0, marginLeft: '1em'}}>{T('partnerInfo.contentTitle', {name: partner.name})}</h3>
          </div>
          <p>{partner.description || partner.descriptions[getCurrentLanguage()]}</p>
          <p>
            <Button
              href={partner.website}
              target="_blank"
              style={{
                color: buttonFont,
                backgroundColor: buttonBackground,
                border: 'none'
              }}
            >
              {T('partnerInfo.learnMore')}
            </Button>
          </p>
          <h3 className={styles.supportTitle}>
            <div className={styles.supportTitleContent}>
              <span className={icons.iconSupport} /> {T('partnerInfo.support')}
            </div>
          </h3>
          <p>{T('partnerInfo.contactUs')}</p>
          <ul className={styles.supportList} style={{color: buttonBackground}}>
            {partner.supportWebsite && (
              <li>
                <span className={icons.iconInternet} />
                <br />
                <a
                  href={partner.supportWebsite}
                  target="_blank"
                  style={{color: buttonBackground}}
                  rel="noopener noreferrer"
                >
                  {T('partnerInfo.visitSupport')}
                </a>
              </li>
            )}
            {partner.supportPhoneNumber && (
              <li>
                <span className={icons.iconTelephone} style={{color: buttonBackground}} />
                <br />
                {partner.supportPhoneNumber}
              </li>
            )}
            {partner.supportEmailAddress && (
              <li>
                <span className={icons.iconMail} />
                <br />
                <a
                  href={`mailto:${partner.supportEmailAddress}`}
                  target="_blank"
                  style={{color: buttonBackground}}
                  rel="noopener noreferrer"
                >
                  {partner.supportEmailAddress}
                </a>
              </li>
            )}
          </ul>
        </div>
      ) : (
        <p>{T('partnerInfo.noPartner')}</p>
      )}
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.PartnerInfo,
  title: 'partnerInfo.title',
  description: 'partnerInfo.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  hidden: true,
  width: 2,
  height: 2,
  defaultSettings: {},
  isVisible: location => {
    if (location === undefined) return false;
    if (dashboardStyle.showPartnerInfo === false) return false;

    const partner = (location || {organization: undefined}).organization;
    return partner !== undefined && !partner.internal;
  },
  locationAware: CardLocationAwareness.Aware,
  cardClass: PartnerInfo
};
export default CARD;
