import React from 'react';
import {ModalHeader as RsModalHeader, ModalHeaderProps} from 'reactstrap';

import {classes} from '../../utils/Styles';

export function ModalHeader(props: ModalHeaderProps) {
  let {className, children, ...otherProps} = props;

  return (
    <RsModalHeader className={classes('dash-modal-header', 'modal-header', className)} {...otherProps}>
      {children}
    </RsModalHeader>
  );
}
