import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {Input, Label, FormGroup, Form} from '../../components/bootstrap';
import {SingleActionPromiseModal} from '../../components/bootstrap/SingleActionPromiseModal';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import API from '../../core/api';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';
import {validateEmail} from '../../utils/Validation';

interface IAddUserModalProps extends IPromiseModalProps<boolean> {
  api: API;
  locationId: number;
}

export function AddUserModal(props: IAddUserModalProps) {
  const {api, locationId} = props;
  const form = useFormState();

  const [isOpen, resolve] = usePromiseModal(props);
  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState<string>();
  const [readOnlyAccessUser, setReadOnlyAccessUser] = useState<boolean>(false);

  const getError = (e: {statusCode: number; error?: string; code?: string}, username: string) => {
    if (e.statusCode === 404) {
      return T('sharedUsers.error.notFound');
    } else if (e.statusCode === 403 && e.error) {
      const details = JSON.parse(e.error) as {error: string};
      if (details.error === 'CANNOT_SHARE_WITH_MULTISITE_USER') {
        return T('sharedUsers.error.cannotShareWithPartnerUsers');
      }
    } else if (e.statusCode === 400) {
      if (e.code === 'user.duplicate') {
        return T('sharedUsers.error.userDuplicated');
      }

      return e.error;
    }

    return T('sharedUsers.error.couldNotAdd', {name: username});
  };

  const validateForm = () => {
    if (form.hasErrors()) {
      form.showErrors();
      return;
    }

    return true;
  };

  const handleClickedAdd = async () => {
    try {
      const isValid = validateForm();
      setError(undefined);

      if (!isValid) {
        console.log('Form validation error');

        // empty string to prevent the modal from closing
        return '';
      }

      const name = hasFeature(AppFeature.SocialLogin) ? emailAddress : username;
      if (hasFeature(AppFeature.SocialLogin)) {
        const type = readOnlyAccessUser ? 'SHARE_READONLY' : 'SHARE_READWRITE';
        await api.locations.inviteUser(locationId, emailAddress, type);
      } else {
        const role = readOnlyAccessUser ? 'OBSERVER' : 'FRIENDLY_PARTY';
        await api.locations.addUser(locationId, username, role);
      }
      const message = T('sharedUsers.added', {name});
      setUsername('');
      setEmailAddress('');
      setReadOnlyAccessUser(false);
      NotificationManager.success(message);
      return undefined;
    } catch (e) {
      const value = hasFeature(AppFeature.SocialLogin) ? emailAddress : username;
      return getError(e as any, value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === Hotkeys.ENTER) {
      event.preventDefault();
      handleClickedAdd().then(errorMessage => {
        if (errorMessage === undefined) resolve(true);
        setError(errorMessage);
      });
    }
  };

  const handleUsernameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  const handleEmailAddressChanged = (value: string) => {
    setEmailAddress(value);
  };

  return (
    <SingleActionPromiseModal
      title={T('sharedUsers.title')}
      action={handleClickedAdd}
      actionText={T('sharedUsers.share.confirm')}
      disabled={hasFeature(AppFeature.SocialLogin) ? !emailAddress : !username}
      isOpen={isOpen}
      resolve={resolve}
      error={error}
    >
      <FormContextProvider value={form}>
        <Form>
          <FormGroup>
            <Label>
              {hasFeature(AppFeature.SocialLogin) ? T('sharedUsers.field.email') : T('sharedUsers.field.username')}
            </Label>
            {hasFeature(AppFeature.SocialLogin) ? (
              <>
                <TextInputGroup
                  autoFocus={true}
                  type="text"
                  value={emailAddress}
                  onKeyPress={handleKeyPress}
                  onChange={handleEmailAddressChanged}
                  name="emailAddress"
                  validate={validateEmail}
                />
                <Checkbox
                  id="readOnlyAccessUser"
                  name="readOnlyAccessUser"
                  checked={readOnlyAccessUser}
                  label={T('sharedUsers.field.readOnlyAccess')}
                  onCheckedChange={() => setReadOnlyAccessUser(!readOnlyAccessUser)}
                  testId="readOnlyAccessUser"
                />
              </>
            ) : (
              <>
                <Input
                  autoFocus={true}
                  type="text"
                  value={username}
                  onKeyPress={handleKeyPress}
                  onChange={handleUsernameChanged}
                  name="username"
                />
                <Checkbox
                  id="readOnlyAccessUser"
                  name="readOnlyAccessUser"
                  checked={readOnlyAccessUser}
                  label={T('sharedUsers.field.readOnlyAccess')}
                  onCheckedChange={() => setReadOnlyAccessUser(!readOnlyAccessUser)}
                  testId="readOnlyAccessUser"
                />
              </>
            )}
          </FormGroup>
        </Form>
      </FormContextProvider>
    </SingleActionPromiseModal>
  );
}
