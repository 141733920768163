import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import Table, {migrateTableSettings} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Plus} from '../../components/ui-lib/icons/small';
import FileImport from '../../components/ui-lib/legacy-icons/FileImport';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import ImportCSVModal, {ImportableField} from '../../modals/ImportCSVModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IChargingRule, ChargingPriorityRuleType, getRuleName} from '../../models/ChargingPriorities';
import {isReadOnly} from '../../models/Location';
import {
  ITableField,
  CalculatedStringField,
  ComponentField,
  FieldAlignment,
  DateCalculatedField
} from '../../models/Table';
import {None} from '../../utils/Arrays';
import {processWhitelist} from '../../utils/DemoMode';
import {useAppSelector, useCardLoader} from '../../utils/Hooks';
import {T, plural, quantity} from '../../utils/Internationalization';
import {validateMatching} from '../../utils/Validation';
import {ICardType, CardTypeKey, CardLocationAwareness, CardCategory, ICardProps} from '../CardType';
import {useCardChargingStationGroup, useUser} from '../CardUtils';
import ChargingStationParentButtonLink from '../ChargingStations/ChargingStationParentButtonLink';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {Spring} from '../components/CardActions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import AssignRFIDTag from './AssignRFIDTag';

type IChargingStationPrivilegesSettings = ICardSettingsWithTable;

interface ImportingTag {
  token: string;
  comment: string;
  expiration?: number;
}

const ChargingRFIDWhitelistCard = (props: ICardProps<IChargingStationPrivilegesSettings>) => {
  const {settings, updateSettings} = props;

  const modals = useModals();
  const {api} = useAppContext();
  const user = useUser();
  const chargingGroup = useCardChargingStationGroup(settings);
  const readOnly = isReadOnly(user, chargingGroup);
  const locationId = chargingGroup && chargingGroup.id;
  const demoMode = useAppSelector(state => state.preferences.demoMode);

  const [items, refresh] = useCardLoader<IChargingRule[]>(
    () =>
      locationId === undefined
        ? Promise.resolve(None)
        : api.getRFIDChargingSettings(locationId).then(whitelist => processWhitelist(whitelist, demoMode)),
    [locationId, demoMode],
    plural('privilege'),
    None
  );

  const fields: ITableField<IChargingRule>[] = useMemo(() => {
    const handleClickedEdit = async (item: IChargingRule) => {
      const rfid = item.rfid;
      if (!locationId || !rfid) return;

      const changed = await modals.show<boolean>(props => (
        <AssignRFIDTag
          locationId={locationId}
          tag={rfid.value}
          title={T('chargingRFIDWhitelist.edit.title')}
          comment={rfid.comment || ''}
          expiration={rfid.expirationTimestamp}
          {...props}
        />
      ));
      if (changed) refresh();
    };

    const handleClickedRemove = async (item: IChargingRule) => {
      if (locationId === undefined || !item.rfid) return;

      const name = getRuleName(item);
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title={T('chargingStationPrivileges.remove.title')}
          message={T('chargingStationPrivileges.remove.message', {name})}
          acceptLabel={T('chargingStationPrivileges.remove.confirm')}
          rejectLabel={T('chargingStationPrivileges.remove.cancel')}
          {...props}
        />
      ));
      if (confirmed !== ConfirmationResult.Accept) return;

      await api.deleteRFIDChargingSettings(locationId, item.rfid.value);
      refresh();
    };

    return [
      new CalculatedStringField('target', T('chargingStationPrivileges.column.rfidTag'), getRuleName),
      new CalculatedStringField(
        'comment',
        T('chargingStationPrivileges.column.comment'),
        rule => (rule.rfid && rule.rfid.comment) || ''
      ),
      new DateCalculatedField(
        'expiration',
        T('chargingStationPrivileges.column.expiration'),
        '',
        rule => rule.rfid && rule.rfid.expirationTimestamp,
        {format: 'DD-MM-YYYY'}
      ),
      new ComponentField(
        'actions',
        T('chargingStationPrivileges.column.actions'),
        rule => (
          <RowActions>
            {!readOnly && (
              <RowActionButton
                icon="Pencil"
                title={T('chargingRFIDWhitelist.edit.title')}
                onClick={() => handleClickedEdit(rule)}
              />
            )}
            {!readOnly && (
              <RowActionButton
                action="delete"
                title="Remove user"
                onClick={() => handleClickedRemove(rule)}
                invisible={rule.targetType === ChargingPriorityRuleType.ServiceLocation}
              />
            )}
          </RowActions>
        ),
        {align: FieldAlignment.Center, autoInsert: 'end'}
      )
    ];
  }, [api, readOnly, modals, locationId, refresh]);

  const handleClickedAddCard = () => {
    if (!chargingGroup || readOnly) return;

    return modals
      .show<boolean>(props => (
        <AssignRFIDTag
          title={T('chargingRFIDWhitelist.add.title')}
          locationId={chargingGroup.id}
          comment=""
          {...props}
        />
      ))
      .then(changed => changed && refresh());
  };

  const handleClickedImport = async () => {
    if (!chargingGroup || readOnly) return;

    const fields: ImportableField[] = [
      {
        field: 'target',
        label: T('chargingStationPrivileges.column.rfidTag'),
        required: true,
        validator: validateMatching(/^[0-9A-Fa-f:]+$/, T('chargingRFIDWhitelist.invalidRFID'))
      },
      {
        field: 'comment',
        label: T('chargingStationPrivileges.column.comment'),
        required: true
      },
      {
        field: 'expiration',
        label: T('chargingStationPrivileges.column.expiration'),
        required: false,
        validator: validateMatching(/^[0-9]{1,2}[-/][0-9]{1,2}[-/][0-9]{4}$/, T('chargingRFIDWhitelist.invalidDate'))
      }
    ];

    const handleImport = (values: {[key: string]: string}[]) => {
      const tags: ImportingTag[] = values.map(value => ({
        token: value.target,
        comment: value.comment,
        expiration: value.expiration ? dayjs(value.expiration.replaceAll('/', '-'), 'D-M-YYYY').valueOf() : undefined
      }));
      const promises = Promise.all(
        tags.map(tag => api.setRFIDChargingSettings(chargingGroup.id, tag.token, tag.expiration, tag.comment))
      );
      return promises.then(() => {
        NotificationManager.success(
          T('chargingRFIDWhitelist.import.success', {
            entries: quantity('rfidCard', tags.length)
          })
        );
        return undefined;
      });
    };

    const changed = await modals.show<boolean>(props => (
      <ImportCSVModal fields={fields} noun="rfidCard" info="" importer={handleImport} {...props} />
    ));
    if (changed) refresh();
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {state.ready && (
        <ExportCsv fields={fields} items={items} settings={settings.table} name={T('chargingRFIDWhitelist.title')} />
      )}
      <Spring />
      {state.ready && !readOnly && (
        <>
          <Button variant="secondary_default" onClick={handleClickedImport} className="tw-gap-2">
            <FileImport width={16} height={16} />
            {T('chargingRFIDWhitelist.import')}
          </Button>
          <Button variant="secondary_default" onClick={handleClickedAddCard} className="tw-gap-2">
            <Plus width={16} height={16} />
            {T('chargingStationPrivileges.addCard')}
          </Button>
        </>
      )}
    </CardActions>
  );

  const titleAddendum = <ChargingStationParentButtonLink location={chargingGroup} />;

  let error: string | undefined;
  if (chargingGroup === undefined) {
    error = T('chargingStationPrivileges.stationGroupRequired');
  }

  return (
    <CardView error={error} titleAddendum={titleAddendum} actions={actions} {...cardViewProps(props)}>
      <Table
        items={items}
        fields={fields}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="rfidCard"
      />
    </CardView>
  );
};

const DefaultSettings: IChargingStationPrivilegesSettings = {
  table: {
    pageSize: 20,
    columns: [
      {name: 'target', visible: true},
      {name: 'comment', visible: true},
      {name: 'discount', visible: true},
      {name: 'expiration', visible: true}
    ]
  }
};

const CARD: ICardType<IChargingStationPrivilegesSettings> = {
  type: CardTypeKey.ChargingRFIDWhitelist,
  title: 'chargingRFIDWhitelist.title',
  description: 'chargingRFIDWhitelist.description',
  locationAware: CardLocationAwareness.Required,
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: DefaultSettings,
  upgradeSettings: migrateTableSettings('table', DefaultSettings.table),
  cardClass: ChargingRFIDWhitelistCard
};
export default CARD;
