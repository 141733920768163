import {AppStore} from '..';
import {CardTypeKey} from '../../cards/CardType';
import API from '../../core/api';
import {AuthUser} from '../../models/AuthUser';
import {IBoard, ICard} from '../../models/Board';

import {ICardSettings} from '../../models/CardSettings';

import {AnyAppAction} from '.';

import {initializeBoards, SimplifiedBoardCards} from './initBoards';

export enum BoardsActions {
  FETCHING = 'BOARDS.FETCHING',
  LOAD_SUCCESS = 'BOARDS.LOAD_SUCCESS',
  LOAD_ERROR = 'BOARDS.LOAD_ERROR',
  UPDATE_BOARD = 'BOARDS.UPDATE_BOARD',
  INITIALIZING_BOARDS = 'BOARDS.INITIALIZING',
  UpdateCardSettings = 'BOARDS.UPDATE_CARD_SETTINGS'
}

export interface ISetBoardsStartAction {
  type: BoardsActions.FETCHING;
  payload: {};
}

export interface ISetBoardsSuccessAction {
  type: BoardsActions.LOAD_SUCCESS;
  payload: IBoard[];
}

export interface ISetBoardsErrorAction {
  type: BoardsActions.LOAD_ERROR;
  payload: any;
}

export interface IUpdateBoardAction {
  type: BoardsActions.UPDATE_BOARD;
  payload: IBoard;
}

export interface IInitializingBoardsAction {
  type: BoardsActions.INITIALIZING_BOARDS;
  payload: {
    name: string;
  };
}

export interface IUpdateCardSettingsAction {
  type: BoardsActions.UpdateCardSettings;
  payload: {boardId: string; cardId: string; settings: any};
}

export function setBoards(boards: IBoard[]): AnyAppAction {
  return {
    type: BoardsActions.LOAD_SUCCESS,
    payload: boards
  };
}

export function updateBoard(board: IBoard): AnyAppAction {
  return {
    type: BoardsActions.UPDATE_BOARD,
    payload: board
  };
}

function fixPartnerCard(board: IBoard) {
  const partnerInfoCard = board.cards.find(card => card.type === CardTypeKey.PartnerInfoMini);
  if (partnerInfoCard === undefined) return;
  if (board.settings === undefined || board.settings.layouts === undefined || board.settings.layouts.lg === undefined) {
    return;
  }

  const layout = board.settings.layouts.lg;
  const layoutForCard = layout.find(x => x.i === `card-${partnerInfoCard.id}`);
  if (layoutForCard !== undefined && layoutForCard.h >= 2) return; // card is fine

  const areTemplateCardsPresent = !SimplifiedBoardCards.some(x => !board.cards.some(y => x.type === y.type));
  if (areTemplateCardsPresent) {
    if (layoutForCard === undefined) {
      board.settings.layouts.lg.push({
        i: `card-${partnerInfoCard.id}`,
        x: 3,
        y: 0,
        w: 1,
        h: 2,
        minW: 1,
        minH: 2
      });
    } else {
      layoutForCard.x = 3;
      layoutForCard.y = 0;
      layoutForCard.w = 1;
      layoutForCard.h = 2;
      layoutForCard.minW = 1;
      layoutForCard.minH = 2;
    }
  }
}

export async function fetchBoards(api: API, store: AppStore, user: AuthUser) {
  store.dispatch({
    type: BoardsActions.FETCHING,
    payload: {}
  });

  try {
    const boards = await initializeBoards(api, store, await api.dashboard.getBoards(), user);
    store.dispatch({
      type: BoardsActions.LOAD_SUCCESS,
      payload: boards
    });
  } catch (err) {
    store.dispatch({
      type: BoardsActions.LOAD_ERROR,
      payload: err
    });
  }
}

export function updateCardSettings<S extends ICardSettings>(
  store: AppStore,
  api: API,
  boardId: string,
  card: ICard<S>,
  settings: Partial<S>
): Promise<IBoard> {
  store.dispatch({
    type: BoardsActions.UpdateCardSettings,
    payload: {boardId, cardId: card.id, settings}
  });

  const newSettings = {...card.settings, ...settings};
  return api.dashboard
    .updateCard(boardId, card.id, {
      name: card.name,
      order: card.order,
      type: card.type,
      version: 1,
      settings: newSettings
    })
    .then(board => {
      if (board === undefined) throw new Error('Could not update board');

      return board;
    });
}

export type AnyBoardsAction =
  | ISetBoardsStartAction
  | ISetBoardsSuccessAction
  | ISetBoardsErrorAction
  | IUpdateBoardAction
  | IInitializingBoardsAction
  | IUpdateCardSettingsAction;
