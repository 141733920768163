import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Modal, ModalBody, ModalHeader, ModalFooter, Form} from '../../../components/bootstrap';
import FormSaveButton from '../../../components/inputs/FormSaveButton';
import {Button} from '../../../components/ui/button';
import {Label} from '../../../components/ui/label';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../../components/ui/tabs';
import {Textarea} from '../../../components/ui/textarea';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {OrganizationLanguage} from '../../../models/Organization';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';

import {EditOrganizationFormState, OrganizationFieldTranslation} from './EditOrganizationModels';

interface TranslationsModalProps extends IPromiseModalProps<void> {
  state: EditOrganizationFormState;
  onChange: (translations: OrganizationFieldTranslation[]) => void;
}
export const TranslationsModal = (props: TranslationsModalProps) => {
  const [isOpen, resolve] = usePromiseModal(props);
  const {state, onChange} = props;

  const [localTranslations, setLocalTranslations] = useState<OrganizationFieldTranslation[]>([]);

  const handleClose = useCallback(() => resolve(), [resolve]);

  // List of default translation languages (Dutch, French, German)
  const defaultLanguages: OrganizationLanguage[] = [
    OrganizationLanguage.Dutch,
    OrganizationLanguage.French,
    OrganizationLanguage.German
  ];

  useEffect(() => {
    if (state.descriptionTranslations.length === 0) {
      const initialTranslations = defaultLanguages.map(language => ({
        language,
        value: ''
      }));
      setLocalTranslations(initialTranslations);
    } else {
      // Use the existing translations if present
      setLocalTranslations(state.descriptionTranslations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.descriptionTranslations]);

  const filteredTranslations = useMemo(() => {
    // const allowedLanguages = ['nl', 'de', 'fr'];
    return localTranslations.filter(tr => defaultLanguages.includes(tr.language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTranslations]);

  const handleDescriptionTranslationChanged = (language: OrganizationLanguage, value: string) => {
    const updatedTranslations = localTranslations.map(t => (t.language === language ? {language, value} : t));
    setLocalTranslations(updatedTranslations);
  };

  const handleSave = async () => {
    onChange(localTranslations);
    resolve();
  };

  return (
    <FormProvider>
      <Modal isOpen={isOpen} toggle={handleClose} size="lg" autoFocus={false}>
        <ModalHeader toggle={handleClose}>{T('organisation.description.translations')}</ModalHeader>
        <ModalBody>
          <Tabs defaultValue={filteredTranslations[0]?.language || 'nl'} className="tw-w-[400px]">
            <TabsList className="tw-grid tw-w-full tw-grid-cols-3">
              {filteredTranslations.map(({language}) => (
                <TabsTrigger key={language} value={language}>
                  {language.toUpperCase()}
                </TabsTrigger>
              ))}
            </TabsList>
            {filteredTranslations.map(({language, value}) => (
              <TabsContent key={language} value={language}>
                <Form>
                  <div className="tw-grid tw-w-full tw-gap-1.5">
                    <Label
                      htmlFor={`description-${language}`}
                      className="tw-flex tw-justify-between tw-items-center tw-font-bold tw-text-base"
                    >
                      <span>{T('organizations.field.description')}</span>
                    </Label>
                    <Textarea
                      id={`description-${language}`}
                      name={`description-${language}`}
                      value={value}
                      onChange={e => handleDescriptionTranslationChanged(language, e.currentTarget.value)}
                    />
                    <p className="tw-text-sm tw-text-muted">{T('validatedInput.optional')}</p>
                  </div>
                </Form>
              </TabsContent>
            ))}
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary_negative" onClick={handleClose}>
            {T('generic.action.cancel')}
          </Button>
          <FormSaveButton onSave={handleSave}>{T('modal.save')}</FormSaveButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
