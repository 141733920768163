import React, {useState, useEffect, useCallback} from 'react';

import {useFormContext} from '../../utils/FormContext';

import {FieldValidator} from '../../utils/Validation';

import FormInputGroup from './FormInputGroup';
import PasswordInput from './PasswordInput';

interface PasswordInputGroupProps {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  validate?: FieldValidator;
  effect?: (value: string, valid: boolean) => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  info?: string;
  showPlaceholder?: boolean;
  hideLabel?: boolean;
  className?: string;
}
export const PasswordInputGroup = (props: PasswordInputGroupProps) => {
  const {name, label, value, onChange, validate, effect, onKeyPress, info, showPlaceholder, hideLabel, className} =
    props;
  const form = useFormContext();
  const error = validate && validate(value, label, false);
  form.setError(name, error);

  useEffect(() => {
    effect && effect(value, error === undefined);
  }, [effect, value, error]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const shownError = form.getShownError(name);
  return (
    <FormInputGroup
      name={name}
      label={!hideLabel && !showPlaceholder ? label : undefined}
      error={shownError}
      info={info}
      className={className}
    >
      <PasswordInput
        value={value}
        name={name}
        onChange={useCallback(e => onChange(e.currentTarget.value), [onChange])}
        onBlur={useCallback(() => form.setErrorVisible(name, error !== undefined), [form, name, error])}
        invalid={shownError !== undefined}
        onKeyPress={onKeyPress}
        placeholder={showPlaceholder ? label : ''}
      />
    </FormInputGroup>
  );
};

export function usePasswordInput(
  name: string,
  label: string,
  initialValue: string,
  validate?: FieldValidator,
  effect?: (value: string, valid: boolean) => void,
  props?: {
    autoFocus?: boolean;
    showPlaceholder?: boolean;
    onKeyPress?: (event: React.KeyboardEvent) => void;
  }
): [JSX.Element, string, () => void] {
  const [value, setValue] = useState(initialValue);
  const clear = useCallback(() => setValue(initialValue), [initialValue]);

  const component = (
    <PasswordInputGroup
      name={name}
      label={label}
      value={value}
      onChange={setValue}
      validate={validate}
      effect={effect}
      {...props}
    />
  );

  return [component, value, clear];
}
