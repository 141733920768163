import {IUpdateCardRequest} from '../core/api';
import {IBoard, ICreateBoardRequest, IUpdateBoardRequest} from '../models/Board';

import {APIClient} from './APIClient';

export class DashboardAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getBoards(): Promise<IBoard[]> {
    const url = `/api/v10/portal/boards`;
    return this.client.doGet(url);
  }

  getBoard(id: string): Promise<IBoard> {
    const url = `/api/v10/portal/boards/${id}`;
    return this.client.doGet(url);
  }

  createBoard(board: ICreateBoardRequest): Promise<IBoard | undefined> {
    const url = `/api/v10/portal/boards`;
    return this.client.doPost(url, board);
  }

  updateBoard(board: IUpdateBoardRequest): Promise<IBoard[] | undefined> {
    const url = `/api/v10/portal/boards/${board.id}`;
    return this.client.doPut(url, board);
  }

  deleteBoard(id: string): Promise<unknown> {
    const url = `/api/v10/portal/boards/${id}`;
    return this.client.doDelete(url);
  }

  createCard(boardId: string, card: {type: string; settings?: any}): Promise<IBoard | undefined> {
    const url = `/api/v10/portal/boards/${boardId}/cards`;
    return this.client.doPost(url, card);
  }

  updateCard(boardId: string, cardId: string, card: IUpdateCardRequest): Promise<IBoard | undefined> {
    const url = `/api/v10/portal/boards/${boardId}/cards/${cardId}`;
    return this.client.doPut(url, card);
  }

  deleteCard(boardId: string, cardId: string): Promise<IBoard | undefined> {
    const url = `/api/v10/portal/boards/${boardId}/cards/${cardId}`;
    return this.client.doDelete(url);
  }

  cloneUserBoards(fromUserEmail: string, toUserEmail: string): Promise<unknown> {
    const url = `/api/v10/portal/boards/cloneUserBoards`;
    return this.client.doPost(url, {fromUserEmail, toUserEmail});
  }
}
