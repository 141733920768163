import React, {useState} from 'react';

import {useFormContext} from '../../utils/FormContext';
import {classes} from '../../utils/Styles';
import {testingClasses} from '../../utils/TestingClasses';
import {Icons} from '../Icon';
import {Button} from '../ui/button';

interface FormSaveButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'danger' | 'warning';
  onSave: () => Promise<unknown>;
}

export default function FormSaveButton(props: FormSaveButtonProps) {
  const {children, className, disabled, color = 'primary', onSave} = props;

  const form = useFormContext();
  const [loading, setLoading] = useState(false);

  const handleClickedSave = async () => {
    form.showErrors();
    if (form.hasErrors()) {
      console.log('Form has errors:', form.state);
      return;
    }

    try {
      setLoading(true);
      await onSave();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={handleClickedSave}
      disabled={loading || disabled}
      className={classes(className, testingClasses.confirmButton, '!tw-shrink-0')}
      data-testid={testingClasses.confirmButton}
    >
      {children}
      {loading && <>&nbsp;{Icons.Loading}</>}
    </Button>
  );
}
