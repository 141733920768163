import {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ContractDetails, getContractVersions, translateContractVersion} from '../../models/Contract';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {validateSpecialChars} from '../../utils/Validation';

interface EditContractModalProps extends IPromiseModalProps<boolean> {
  contract: ContractDetails;
}
export function EditContractModal(props: EditContractModalProps) {
  const {contract} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [error, setError] = useState<string>();
  const [version, setVersion] = useState(contract.version);
  const [notes, setNotes] = useState(contract.notes || '');

  const handleClickedSave = () => {
    return api.contracts
      .update(contract.id, {
        version,
        notes
      })
      .then(() => {
        NotificationManager.success(T('contracts.edit.saved'));
        resolve(true);
      })
      .catch(e => setError(translateError(e, T('contracts.edit.error'))));
  };

  const versions = getContractVersions(contract.contract);

  return (
    <SingleActionModal
      title={T('contracts.edit.version.label')}
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      action={handleClickedSave}
      error={error}
    >
      {versions.length > 1 && (
        <SelectInputGroup
          name="version"
          label={T('contracts.edit.version.label')}
          value={version.toString()}
          onChange={v => setVersion(parseInt(v))}
        >
          {versions.map(version => (
            <option key={version} value={version.toString()}>
              {version} - {translateContractVersion(contract.contract, version)}
            </option>
          ))}
        </SelectInputGroup>
      )}
      <TextInputGroup
        name="notes"
        type="textarea"
        label={T('contracts.edit.notes.label')}
        value={notes}
        onChange={setNotes}
        validate={validateSpecialChars}
      />
    </SingleActionModal>
  );
}
