import React from 'react';

import {SeriesElement} from '../../cards/ElectricityValues/Series';
import {ChartConfig} from '../../components/Chart';
import {KW_DIGITS_AFTER_COMMA} from '../../core/constants';
import {createGasWaterPointFormatter, createPointFormatter} from '../../utils/GraphUtils';
import {getTimezoneOffset} from '../../utils/TimestampUtils';
import HighStockChart from '../HighStockChart';

interface MiniGraphProps {
  from: number;
  to: number;
  timezone: string;
  backgroundColor?: string;
  data: SeriesElement[];
  color: string;
  label: string;
  unit: string;
  bigConsumer: boolean;
  gasWaterMode?: boolean;
  className?: string;
}

export default function MiniGraph(graph: MiniGraphProps) {
  const {from, to, timezone, backgroundColor, data, color, label, unit, bigConsumer, gasWaterMode, className} = graph;

  const digitsAfterComma = bigConsumer ? KW_DIGITS_AFTER_COMMA : 0;

  const config: ChartConfig = {
    time: {
      timezone,
      //      getTimezoneOffset: (timestamp: number) => -dayjs(timestamp).tz(timezone).utcOffset(),
      getTimezoneOffset: getTimezoneOffset.bind(undefined, timezone)
    },
    chart: {
      backgroundColor
    },
    navigator: {
      enabled: false
    },
    series: [
      {
        type: 'spline',
        data,
        color,
        tooltip: {
          pointFormatter: gasWaterMode
            ? createGasWaterPointFormatter(label, unit)
            : createPointFormatter(label, unit, digitsAfterComma)
        }
      }
    ],
    xAxis: [{visible: false}],
    yAxis: [{visible: false}],
    scrollbar: {enabled: false},
    legend: {
      navigation: {enabled: false}
    }
  };

  return <HighStockChart from={from} to={to} config={config} className={className} />;
}
