import React, {useEffect, useMemo} from 'react';

import {useAppContext} from '../../../app/context';
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input as RsInput,
  Label as RsLabel,
  Row
} from '../../../components/bootstrap';
import {ImageInputGroup} from '../../../components/ImageInputField';
import ColorSwatch from '../../../components/inputs/ColorSwatch/ColorSwatch';
import FormInputGroup from '../../../components/inputs/FormInputGroup';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Button} from '../../../components/ui/button';
import {Checkbox} from '../../../components/ui/checkbox';
import {Label} from '../../../components/ui/label';
import {Textarea} from '../../../components/ui/textarea';
import {Languages} from '../../../components/ui-lib/icons/large';
import {useModals} from '../../../modals/ModalContext';
import {IOrganization, OrganizationLanguage} from '../../../models/Organization';
import {FormState} from '../../../utils/FormState';
import {useRefCallback} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';
import {
  validateColor,
  validateEmail,
  validateRequired,
  validateSpecialChars,
  validateUrl
} from '../../../utils/Validation';
import {useUser} from '../../CardUtils';

import styles from './EditOrganizationGeneralTab.module.scss';
import {EditOrganizationFormState, OrganizationFieldTranslation} from './EditOrganizationModels';
import {SearchInstallationPartner} from './SearchInstallationPartner';
import {TranslationsModal} from './TranslationsModal';

interface EditOrganizationGeneralTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
  canEditParent: boolean;
  parentOrganizations: IOrganization[];
}

export default function EditOrganizationGeneralTab(props: EditOrganizationGeneralTabProps) {
  const {formState, state, updateState, canEditParent, parentOrganizations} = props;
  const {api} = useAppContext();

  const user = useUser();
  const modals = useModals();

  const handleParentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateState({parentId: value === 'none' ? undefined : parseInt(value)});
  };

  const handleChangeDescriptionTranslation = (translations: OrganizationFieldTranslation[]) => {
    // Update the state with new translations
    updateState({descriptionTranslations: translations});
  };

  const handleClickTranslations = (): Promise<void> => {
    return modals.show<void>(modalProps => (
      <TranslationsModal state={state} onChange={handleChangeDescriptionTranslation} {...modalProps} />
    ));
  };

  const parentOrganizationOptions = useMemo(() => {
    const result = parentOrganizations.map(org => (
      <option key={org.id} value={org.id}>
        {org.name}
      </option>
    ));
    result.splice(
      0,
      0,
      <option key="none" value="none">
        {T('organization.noParent')}
      </option>
    );
    return result;
  }, [parentOrganizations]);

  const hasEmail = state.supportEmail.trim() !== '';
  const hasPhoneNumber = state.supportPhone.trim() !== '';
  const hasWebsite = state.supportWebsite.trim() !== '';
  const isSupportValid = !!(hasEmail || hasPhoneNumber || hasWebsite);

  useEffect(() => {
    if (isSupportValid !== state.isSupportValid) {
      updateState({isSupportValid, supportInvalidVisible: !isSupportValid});
    }
  }, [isSupportValid, state.isSupportValid, updateState]);

  const supportBlur = useRefCallback(() =>
    updateState({
      supportInvalidVisible: !isSupportValid,
      isSupportValid
    })
  );

  return (
    <Form>
      <Row>
        <Col xl={4}>
          <FormGroup>
            <RsLabel className="!tw-font-bold">{T('organization.parent')}</RsLabel>
            <RsInput
              type="select"
              value={state.parentId === undefined ? 'none' : state.parentId.toString()}
              onChange={handleParentChanged}
              disabled={!canEditParent}
              name="parent"
              className="!tw-w-full"
            >
              {parentOrganizationOptions}
            </RsInput>
          </FormGroup>
          <TextInputGroup
            form={formState}
            name="name"
            label={T('organizations.field.name')}
            value={state.name}
            onChange={name => updateState({name})}
            validate={validateRequired}
            error={formState.getServerError('name')}
          />
          {user.isServiceDesk() && (
            <FormGroup>
              <Checkbox
                id="internal"
                name="internal"
                label={T('organizations.field.internal')}
                defaultChecked={state.internal}
                onCheckedChange={internal => updateState({internal})}
                testId="internal"
              />
            </FormGroup>
          )}
          <TextInputGroup
            form={formState}
            name="website"
            label={T('organizations.field.website')}
            value={state.website}
            onChange={website => updateState({website})}
            validate={validateUrl}
            optional
          />
          <TextInputGroup
            form={formState}
            name="privacyPolicy"
            label={T('organizations.field.privacyPolicy')}
            value={state.privacyPolicyUrl}
            onChange={privacyPolicyUrl => updateState({privacyPolicyUrl})}
            validate={validateUrl}
            optional
          />
          <div className="tw-grid tw-w-full tw-gap-1.5">
            <Label
              htmlFor="description"
              className="tw-flex tw-justify-between tw-items-center tw-font-bold tw-text-base"
            >
              <span>{T('organizations.field.description')}</span>
              <Button
                variant="secondary_default"
                title={T('organisation.description.translations')}
                size="lg"
                onClick={handleClickTranslations}
              >
                <Languages width={30} height={24} />
                <span className="tw-ml-2">{T('organisation.description.button.label.translations')}</span>
              </Button>
            </Label>
            <Textarea
              id="description"
              name="description"
              value={state.description}
              onChange={e => updateState({description: e.currentTarget.value})}
            />
            <p className="tw-text-sm tw-text-muted">{T('validatedInput.optional')}</p>
          </div>
        </Col>
        <Col md={4}>
          <label className="tw-font-bold">{T('organizations.field.backgroundColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="background"
              value={state.backgroundColor}
              onChange={backgroundColor => updateState({backgroundColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.backgroundColor} onChange={backgroundColor => updateState({backgroundColor})} />
          </div>
          <label className="tw-font-bold">{T('organizations.field.fontColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="fontColor"
              value={state.fontColor}
              onChange={fontColor => updateState({fontColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.fontColor} onChange={fontColor => updateState({fontColor})} />
          </div>
          <label className="tw-font-bold">{T('organizations.field.buttonBackgroundColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="buttonBackground"
              value={state.buttonBackgroundColor}
              onChange={buttonBackgroundColor => updateState({buttonBackgroundColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch
              value={state.buttonBackgroundColor}
              onChange={buttonBackgroundColor => updateState({buttonBackgroundColor})}
            />
          </div>
          <label className="tw-font-bold">{T('organizations.field.buttonFontColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="buttonFontColor"
              value={state.buttonFontColor}
              onChange={buttonFontColor => updateState({buttonFontColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.buttonFontColor} onChange={buttonFontColor => updateState({buttonFontColor})} />
          </div>
          <ImageInputGroup
            label={T('organizations.field.logo')}
            api={api}
            value={state.logo}
            onChange={logo => updateState({logo})}
            optional
          />
        </Col>
        <Col md={4}>
          <label className="tw-text-sm tw-text-neutral-600">{T('organizations.supportInfo')}</label>
          <TextInputGroup
            form={formState}
            name="supportEmail"
            label={T('organizations.field.supportEmail')}
            value={state.supportEmail}
            onChange={supportEmail => updateState({supportEmail})}
            validate={validateEmail}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          <TextInputGroup
            form={formState}
            name="supportPhone"
            label={T('organizations.field.supportPhone')}
            value={state.supportPhone}
            onChange={supportPhone => updateState({supportPhone})}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          <TextInputGroup
            form={formState}
            name="supportWebsite"
            label={T('organizations.field.supportWebsite')}
            value={state.supportWebsite}
            onChange={supportWebsite => updateState({supportWebsite})}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          {state.supportInvalidVisible && (
            <div>
              <FormFeedback>{T('organizations.supportError')}</FormFeedback>
            </div>
          )}
          {user.isServiceDesk() && (
            <FormInputGroup name="installerPartner" label={T('organizations.field.installerPartner')}>
              <SearchInstallationPartner
                name="installationPartner"
                value={state.installationPartner}
                onChange={installationPartner => updateState({installationPartner})}
                placeholder="Select partner"
              />
            </FormInputGroup>
          )}
        </Col>
      </Row>
    </Form>
  );
}
