import React from 'react';

import {classes} from '../utils/Styles';
import {testingClasses} from '../utils/TestingClasses';

import {Icon, SMAPPEE_FONT_ICON_MAP} from './Icon';
import styles from './MiniCardHeader.module.scss';

interface MiniCardHeaderProps {
  title: string;
  subtitle?: string;
  icon?: Icon;
  color?: string;
}
export const MiniCardHeader = (props: MiniCardHeaderProps) => {
  const {title, subtitle, icon, color} = props;
  const IconComponent = icon ? SMAPPEE_FONT_ICON_MAP[icon] : null;
  return (
    <div className={styles.header}>
      <div className={styles.value} style={{color}}>
        {
          IconComponent && <IconComponent width={24} height={24} />
          // <>
          //   <i className={icon} />{' '}
          // </>
        }
        <h3 className={classes(testingClasses.cardTitle, styles.title)} data-testid={testingClasses.cardTitle}>
          {title}
        </h3>
      </div>
      {subtitle && (
        <div
          className={classes(styles.subTitle, testingClasses.cardSubtitle)}
          style={{color}}
          data-testid={testingClasses.cardSubtitle}
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};
