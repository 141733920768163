import {useCallback, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {RowActions, Button} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {ISelectOption, SearchableSelectInput} from '../../components/SearchableSelectInput';
import Table from '../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IOrganizationRegion} from '../../models/Organization';
import {ITableField, StringField, CalculatedStringField, ComponentField, FieldAlignment} from '../../models/Table';
import {IUser, getUserTypeDisplayName} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {useOrganizationRegions, useOrganization} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {SelectUserModal} from './SelectUserModal';

interface IManageRegionUsersCardSettings extends ICardSettingsWithTable {
  organization?: number;
  region?: number;
}

const rowKey = (item: IUser, index: number) => index;

function getTableColumns(handleClickedRemove: (user: IUser) => void): ITableField<IUser>[] {
  const columns: ITableField<IUser>[] = [];

  if (!hasFeature(AppFeature.SocialLogin)) {
    columns.push(
      new StringField('userName', T('users.field.username'), {
        alwaysVisible: true,
        autoInsert: 'start'
      })
    );
  }

  return [
    ...columns,
    new StringField('emailAddress', T('users.field.email')),
    new StringField('firstName', T('users.field.firstName')),
    new StringField('lastName', T('users.field.lastName')),
    new CalculatedStringField('role', T('users.field.role'), row => getUserTypeDisplayName(row.type)),
    new ComponentField(
      'actions',
      T('users.field.actions'),
      user => (
        <RowActions>
          <Button size="sm" color="link" withoutPadding title="Remove user" onClick={() => handleClickedRemove(user)}>
            <span className="fal fa-times" />
          </Button>
        </RowActions>
      ),
      {align: FieldAlignment.Center, autoInsert: 'end'}
    )
  ];
}

const ManageRegionUsersCard = (props: ICardProps<IManageRegionUsersCardSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api} = useAppContext();
  const modals = useModals();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organization || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const regionId = settings.region;
  const [regions, setRegions] = useOrganizationRegions(fetch, organizationId);
  const region = regions.find(r => r.id === regionId);

  const handleClickedRemoveUser = async (removingUser: IUser) => {
    if (region === undefined || organizationId === undefined) return;

    const confirmation = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('manageRegionUsers.remove.title')}
        message={T('manageRegionUsers.remove.message', {
          user: removingUser.firstName || '',
          region: region.name || ''
        })}
        {...props}
      />
    ));
    if (confirmation !== ConfirmationResult.Accept) return;

    const regionUsers = region.users.filter(user => user.id !== removingUser.id);
    await api.organizations.updateRegion(organizationId, region.id, {
      users: regionUsers.map(user => ({id: user.id}))
    });

    const newRegion = {...region, users: regionUsers};
    const newRegions = regions.map(region => (region.id === newRegion.id ? newRegion : region));
    setRegions(newRegions);
  };
  const columns = getTableColumns(handleClickedRemoveUser);

  const handleRegionChanged = useCallback(
    (value: string) => {
      updateSettings({region: parseInt(value)});
    },
    [updateSettings]
  );

  const handleClickedAdd = () => {
    modals.show(props => <SelectUserModal onUserSelected={handleAddUserSelected} {...props} />);
  };

  const handleAddUserSelected = async (user: IUser) => {
    if (!region || !organizationId) return T('manageRegionUsers.add.failed');

    try {
      const organizations = await api.getUserOrganizations(user.id);
      const hasOrganization = organizations.find(org => org.id === organizationId);
      if (!hasOrganization) return T('manageRegionUsers.add.notInOrganization');

      const users = [...region.users, user];
      await api.organizations.updateRegion(organizationId, region.id, {users});
      const newRegion: IOrganizationRegion = {...region, users};
      const newRegions = regions.map(region => (region.id === newRegion.id ? newRegion : region));
      setRegions(newRegions);

      NotificationManager.success(T('manageRegionUsers.add.success'));
      return undefined;
    } catch {
      return T('manageRegionUsers.add.failed');
    }
  };

  const regionOptions: ISelectOption[] = useMemo(
    () =>
      regions.map(region => ({
        value: region.id.toString(),
        label: region.name || ''
      })),
    [regions]
  );

  const actions: CustomActions = () => (
    <CardActions>
      <OrganizationInput
        organizations={inputOrganizations.organizations}
        value={organization}
        onChange={org => org && updateSettings({organization: org.id})}
        placeholder={T('organizationUsers.selectOrganization')}
        onUpdateQuery={updateOrganizationInputQuery}
      />
      <SearchableSelectInput
        value={regionId === undefined ? '' : regionId.toString()}
        onChange={handleRegionChanged}
        options={regionOptions}
        placeholder={T('manageRegionUsers.selectRegion')}
      />
      {region && (
        <Button color="secondary" onClick={handleClickedAdd} title={T('manageRegionUsers.add')} style={{marginLeft: 5}}>
          {Icons.Add}
        </Button>
      )}
    </CardActions>
  );

  const items = (region && region.users) || [];
  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={items}
        rowKey={rowKey}
        noun="user"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IManageRegionUsersCardSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'emailAddress', visible: true},
      {name: 'firstName', visible: true},
      {name: 'lastName', visible: true},
      {name: 'role', visible: true}
    ]
  }
};

const CARD: ICardType<IManageRegionUsersCardSettings> = {
  type: CardTypeKey.ManageRegionUsers,
  title: 'manageRegionUsers.title',
  description: 'manageRegionUsers.description',
  categories: [CardCategory.USERS, CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: ManageRegionUsersCard
};
export default CARD;
