import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';
import {Nav, NavItem, NavLink} from 'reactstrap';

import {isAPIResponse} from '../../../api/APIClient';
import {useAppContext} from '../../../app/context';
import {SingleActionModal} from '../../../components/bootstrap';

import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {Contract} from '../../../models/Contract';
import {
  IOrganization,
  IUpdateOrganizationRequest,
  ICreateOrganizationRequest,
  OrganizationLanguage,
  IOdooCompany
} from '../../../models/Organization';
import {AppFeature, hasFeature} from '../../../utils/AppParameters';
import {None} from '../../../utils/Arrays';
import {translateError} from '../../../utils/Errors';
import {useFormState} from '../../../utils/FormState';
import {useLoader} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';

import {useObjectState} from '../../../utils/ObjectState';

import {useUser} from '../../CardUtils';

import EditOrganizationAccountingTab from './EditOrganizationAccountingTab';
import EditOrganizationContractsTab from './EditOrganizationContractsTab';
import EditOrganizationCPOTab from './EditOrganizationCPOTab';
import EditOrganizationGeneralTab from './EditOrganizationGeneralTab';
import {
  EditOrganizationFormState,
  EditOrganizationTab,
  getDefaultFormState,
  getFormStateForOrganization
} from './EditOrganizationModels';
import EditOrganizationPublicChargingTab from './EditOrganizationPublicChargingTab';
import EditOrganizationSelfBillingTab from './EditOrganizationSelfBillingTab';
import EditOrganizationSplitBillingTab from './EditOrganizationSplitBillingTab';

interface Translation {
  language: OrganizationLanguage;
  value: string;
}

function saveTranslations(value: string, translations: Translation[]): {[key: string]: string} {
  const result: {[key: string]: string} = {};
  result[OrganizationLanguage.English] = value;
  translations.forEach(translation => (result[translation.language] = translation.value));
  return result;
}

interface EditOrganizationProps extends IPromiseModalProps<boolean> {
  organization: IOrganization | null;
}

export default function EditOrganization(props: EditOrganizationProps) {
  const {organization} = props;
  const form = useFormState();
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const me = useUser();

  const [parentOrganizations = None] = useLoader(async api => {
    const organizations = await api.organizations.getAll();
    return organizations
      .filter(organization => organization.parentId === undefined)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const [formState, updateFormState] = useObjectState<EditOrganizationFormState>(
    organization ? getFormStateForOrganization(organization) : getDefaultFormState()
  );

  const [tab, setTab] = useState(EditOrganizationTab.General);
  const [alertMessage, setAlertMessage] = useState<string>();

  const handleClickSave = async () => {
    form.clearServerErrors();
    if (!formState.isSupportValid) {
      updateFormState({supportInvalidVisible: true});
      return;
    }
    if (form.hasErrors()) return;

    let savedDescription: string | undefined = formState.description;
    let savedDescriptions: {[key: string]: string} | undefined;
    const alreadyMultilingual = organization ? organization.multilingual : false;
    if (alreadyMultilingual || formState.descriptionTranslations.length > 0) {
      savedDescription = undefined;
      savedDescriptions = saveTranslations(formState.description, formState.descriptionTranslations);
    }

    const odooCompany: IOdooCompany | undefined = formState.linkAccounting
      ? {
          name: formState.name.trim(),
          vat: formState.vat,
          address: formState.address,
          contact: {
            firstName: formState.contactFirstName,
            lastName: formState.contactLastName,
            phone: formState.contactPhone,
            email: formState.contactEmail,
            language: formState.contactLanguage
          },
          billingContact: {
            firstName: formState.billingFirstName,
            lastName: formState.billingLastName,
            phone: formState.billingPhone,
            email: formState.billingEmail,
            language: formState.contactLanguage,
            address: formState.billingAddress
          }
        }
      : undefined;

    if (organization) {
      const organizationData: IUpdateOrganizationRequest = {
        id: organization.id,
        name: formState.name.trim(),
        internal: formState.internal,
        website: formState.website,
        description: savedDescription,
        descriptions: savedDescriptions,
        logo: formState.logo,
        privacyPolicy: formState.privacyPolicyUrl,
        supportEmailAddress: formState.supportEmail,
        supportPhoneNumber: formState.supportPhone,
        supportWebsite: formState.supportWebsite,
        colors: {
          background: formState.backgroundColor,
          font: formState.fontColor,
          buttonBackground: formState.buttonBackgroundColor,
          buttonFont: formState.buttonFontColor
        },
        selfBillFooterDetails: formState.selfBillFooterDetails,
        odooCompany,
        installationPartner: formState.installationPartner || {id: 0}
      };
      if (organization.cpo) {
        organizationData.cpo = {
          ...organization.cpo,
          billReference: formState.cpoBillReference === '' ? undefined : formState.cpoBillReference,
          vatExempt: formState.vatExempt,
          vatExemptNote: formState.vatExemptNote
        };
        if (me.isServiceDesk()) {
          organizationData.cpo.cpoFeePercentage =
            formState.cpoFeePercentage === undefined ? -1 : formState.cpoFeePercentage?.value();

          organizationData.cpo.acConnectorFee =
            formState.acConnectorFee === undefined ? -1 : formState.acConnectorFee?.value();
          organizationData.cpo.dcConnectorFee =
            formState.dcConnectorFee === undefined ? -1 : formState.dcConnectorFee?.value();

          if (organizationData.cpo.roamingBeta !== undefined || formState.roamingBeta) {
            organizationData.cpo.roamingBeta = formState.roamingBeta;
          }
          if (hasFeature(AppFeature.PaymentTerminals)) {
            organizationData.cpo.paymentTerminalFee =
              formState.paymentTerminalFee === undefined ? -1 : formState.paymentTerminalFee?.value();
          }
        }
      }
      if (organization.splitBillingEmployer) {
        organizationData.splitBillingEmployer = {
          ...organization.splitBillingEmployer,
          billReference: formState.splitBillingBillReference === '' ? undefined : formState.splitBillingBillReference
        };
      }
      if (organization.chargingCustomer) {
        organizationData.chargingCustomer = {
          ...organization.chargingCustomer,
          billReference: formState.emspBillReference === '' ? undefined : formState.emspBillReference
        };
        if (me.isServiceDesk()) {
          organizationData.chargingCustomer.perSessionFee = formState.emspSessionFee?.value();
        }
      }
      await api.organizations
        .update(organizationData)
        .then(() => {
          resolve(true);
          NotificationManager.success(T('organizations.update.success'));
        })
        .catch(error => {
          if (isAPIResponse(error)) {
            console.log(error);
            if (error.code === 'invalid.vat') {
              form.setServerError('vat', T('validator.invalidVAT'));
              return;
            } else if (error.statusCode === 409) {
              form.setServerError('name', T('organizations.nameInUse', {name: formState.name}));
              return;
            }
          }

          var message = translateError(error, T('organizations.update.failed'));
          setAlertMessage(message);
        });
    } else {
      const organization: ICreateOrganizationRequest = {
        parentId: formState.parentId,
        name: formState.name.trim(),
        internal: formState.internal,
        website: formState.website,
        description: savedDescription,
        descriptions: savedDescriptions,
        logo: formState.logo,
        privacyPolicy: formState.privacyPolicyUrl,
        gdpr: false,
        supportEmailAddress: formState.supportEmail,
        supportPhoneNumber: formState.supportPhone,
        supportWebsite: formState.supportWebsite,
        colors: {
          background: formState.backgroundColor,
          font: formState.fontColor,
          buttonBackground: formState.buttonBackgroundColor,
          buttonFont: formState.buttonFontColor
        },
        odooCompany,
        installationPartner: formState.installationPartner
      };

      try {
        await api.organizations.create(organization);
        resolve(true);
        NotificationManager.success(T('organizations.create.success'));
      } catch (error) {
        if ((error as any).status === 409) {
          setAlertMessage(T('organizations.nameInUse', {name: formState.name}));
          NotificationManager.error(T('organizations.nameInUse', {name: formState.name}));
        } else {
          setAlertMessage(T('organizations.create.failed'));
          NotificationManager.error(T('organizations.create.failed'));
        }
      }
    }
  };

  const isNew = organization === null;

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={isNew ? T('organizations.create.title') : T('organizations.update.title')}
      action={handleClickSave}
      actionText={isNew ? T('organizations.create.action') : T('organizations.update.action')}
      size="xl"
      error={alertMessage}
    >
      <div className="tw-mt-2 tw-mb-4">
        <Nav tabs>
          <NavItem>
            <NavLink active={tab === EditOrganizationTab.General} onClick={() => setTab(EditOrganizationTab.General)}>
              {T('organizations.tab.general')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={tab === EditOrganizationTab.Accounting}
              onClick={() => setTab(EditOrganizationTab.Accounting)}
            >
              {T('organizations.tab.accounting')}
            </NavLink>
          </NavItem>
          {organization && organization.contracts && organization.contracts.includes(Contract.ChargingHost) && (
            <NavItem>
              <NavLink
                active={tab === EditOrganizationTab.SelfBilling}
                onClick={() => setTab(EditOrganizationTab.SelfBilling)}
              >
                {T('organizations.tab.selfBilling')}
              </NavLink>
            </NavItem>
          )}
          {organization && organization.cpo && (
            <NavItem>
              <NavLink active={tab === EditOrganizationTab.CPO} onClick={() => setTab(EditOrganizationTab.CPO)}>
                {T('organizations.tab.cpo')}
              </NavLink>
            </NavItem>
          )}
          {organization && organization.chargingCustomer && (
            <NavItem>
              <NavLink
                active={tab === EditOrganizationTab.PublicCharging}
                onClick={() => setTab(EditOrganizationTab.PublicCharging)}
              >
                {T('organizations.tab.publicCharging')}
              </NavLink>
            </NavItem>
          )}
          {organization && organization.splitBillingEmployer && (
            <NavItem>
              <NavLink
                active={tab === EditOrganizationTab.SplitBilling}
                onClick={() => setTab(EditOrganizationTab.SplitBilling)}
              >
                {T('organizations.tab.splitBilling')}
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              active={tab === EditOrganizationTab.Contracts}
              onClick={() => setTab(EditOrganizationTab.Contracts)}
            >
              {T('organizations.tab.contracts')}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      {tab === EditOrganizationTab.General && (
        <EditOrganizationGeneralTab
          formState={form}
          state={formState}
          updateState={updateFormState}
          canEditParent={organization === null}
          parentOrganizations={parentOrganizations}
        />
      )}
      {tab === EditOrganizationTab.Accounting && (
        <EditOrganizationAccountingTab
          formState={form}
          state={formState}
          updateState={updateFormState}
          contracts={organization?.contracts || None}
        />
      )}
      {tab === EditOrganizationTab.SelfBilling && (
        <EditOrganizationSelfBillingTab formState={form} state={formState} updateState={updateFormState} />
      )}
      {tab === EditOrganizationTab.CPO && (
        <EditOrganizationCPOTab formState={form} state={formState} updateState={updateFormState} />
      )}
      {tab === EditOrganizationTab.SplitBilling && (
        <EditOrganizationSplitBillingTab formState={form} state={formState} updateState={updateFormState} />
      )}
      {tab === EditOrganizationTab.PublicCharging && (
        <EditOrganizationPublicChargingTab formState={form} state={formState} updateState={updateFormState} />
      )}
      {tab === EditOrganizationTab.Contracts && (
        <EditOrganizationContractsTab contracts={organization?.contracts || None} />
      )}
    </SingleActionModal>
  );
}
