import React, {useCallback, useState, useMemo, useEffect} from 'react';

import {getCardType} from '..';
import {useBoardContext} from '../../app/boardcontext';
import {useAppContext} from '../../app/context';
import {Alert, Button as RsButton, Input, Modal, ModalBody, ModalFooter, ModalHeader} from '../../components/bootstrap';

import FormInputGroup from '../../components/inputs/FormInputGroup';
import LocationInputGroup from '../../components/inputs/LocationInputGroup';
import {Button} from '../../components/ui/button';
import {LockLocked} from '../../components/ui-lib/icons/small';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {UserRights} from '../../models/AuthUser';
import {addCardToLayout, IUpdateBoardRequest} from '../../models/Board';
import {ICardSettings} from '../../models/CardSettings';
import {ILocation, isChargingParent} from '../../models/Location';
import {hasPartnerFunctionality} from '../../models/User';
import {updateBoard} from '../../redux/actions/boards';
import {None} from '../../utils/Arrays';
import {useLocationUsingAPI} from '../../utils/FunctionalData';
import {useAppSelector, useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';
import {testingClasses} from '../../utils/TestingClasses';
import {useCardContext} from '../CardContext';
import {CardLocationAwareness} from '../CardType';

import {CustomSettings} from './CardView';

interface CardSettingsProps<S extends ICardSettings> extends IPromiseModalProps<boolean> {
  settings: S;
  title: string;
  hasName?: boolean;
  customSettings?: CustomSettings<S>;
}

export default function CardSettings<S extends ICardSettings>(props: CardSettingsProps<S>) {
  const {title, hasName, settings, customSettings} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api, store} = useAppContext();
  const {card, updateSettings} = useCardContext();
  const {me, locations} = useAppSelector(state => ({
    me: state.me.me,
    locations: state.locations.locations
  }));
  const {board} = useBoardContext();
  const [organizations = None] = useLoader(api => api.getUserOrganizations(me.userId), []);

  const cardType = getCardType(card.type);
  const [locationChanged, setLocationChanged] = useState(false);
  const [changedSettings, setChangedSettings] = useState<Partial<S>>({});
  const editingSettings = useMemo(() => ({...settings, ...changedSettings}), [settings, changedSettings]);

  const updateEditingSettings = useCallback((updates: Partial<S>) => {
    setChangedSettings(settings => ({...settings, ...updates}));
  }, []);

  const handleChangeName = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateEditingSettings({name: event.currentTarget.value} as Partial<S>);
  };

  const handleChangeLocation = (locationId: number | undefined) => {
    setLocationChanged(true);
    updateEditingSettings({locationId} as Partial<S>);
  };

  const handleResetLocation = () => {
    setLocationChanged(false);
    updateEditingSettings({locationId: undefined} as Partial<S>);
  };

  const handleClickSave = () => {
    if (!board) return;

    updateSettings(changedSettings);
    resolve(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === Hotkeys.ENTER) handleClickSave();
  };

  const handleClickRemove = () => {
    if (!board) return;

    const {id: boardId} = board;
    api.dashboard.deleteCard(boardId, card.id).then(board => {
      if (board === undefined) return;

      store.dispatch(updateBoard(board));
      resolve(true);
    });
  };

  const handleClickedDuplicate = () => {
    if (!board) return;

    const {id: boardId} = board;
    const {type} = card;
    api.dashboard.createCard(boardId, {type, settings: editingSettings}).then(board => {
      if (!board) return;

      if (board.settings === undefined) {
        board.settings = {layouts: undefined, custom: true, version: 2};
      }

      addCardToLayout(board, board.cards[board.cards.length - 1]);
      api.dashboard.updateBoard(board as IUpdateBoardRequest);

      store.dispatch(updateBoard(board));
      resolve(false);
    });
  };

  const {name, locationId} = editingSettings;
  const hasMultiLocations =
    locations.length > 1 || (locations.length === 1 && isChargingParent(locations[0].functionType));
  const isPrivileged = me.hasLicense || hasPartnerFunctionality(me, organizations);

  // Regular users don't have access to locked location

  const allowLockedLocation = cardType.locationAware !== CardLocationAwareness.Unaware;
  const allowChargingStations = cardType.locationAware !== CardLocationAwareness.RequiresRegular;
  const isValid = cardType.validateSettings ? cardType.validateSettings(editingSettings) : true;

  const customSettingsView = customSettings && customSettings(editingSettings, updateEditingSettings);

  const [loadedLocation] = useLocationUsingAPI(api, locationId);
  const [location, setLocation] = useState<ILocation | undefined>();

  useEffect(() => {
    setLocation(loadedLocation);

    // cleanup function:
    return () => {
      setLocation(undefined);
    };
  }, [loadedLocation]);
  const handleLocationSelected = (id: number) => {
    handleChangeLocation(id);
    return api.locations.get(id).then(setLocation);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => resolve(false)} size="lg" autoFocus={false}>
      <ModalHeader toggle={() => resolve(false)}>
        {title ? T('card.settings.titleFor', {title}) : T('card.settings.title')}
      </ModalHeader>
      <ModalBody>
        {hasName !== false && (
          <FormInputGroup name="title" label={T('card.settings.name.title')}>
            <Input
              className={testingClasses.cardTitleInput}
              type="text"
              value={name || ''}
              onChange={handleChangeName}
              onKeyPress={handleKeyPress}
              placeholder={T('card.settings.name.hint', {title})}
              autoFocus
              data-testid={testingClasses.cardTitleInput}
            />
          </FormInputGroup>
        )}
        {allowLockedLocation && (hasMultiLocations || isPrivileged) && (
          <LocationInputGroup
            label={
              <>
                {T('card.settings.location.title')}
                <LockLocked height={16} width={16} className="tw-inline-flex tw-ml-2" />
              </>
            }
            location={location}
            locationId={locationId}
            onSelected={handleLocationSelected}
            onClear={handleResetLocation}
            allowChargingStations={allowChargingStations}
          />
        )}

        {locationChanged && typeof settings !== 'undefined' && (
          <Alert fixed={false} style={{marginTop: '15px', marginBottom: '5px'}}>
            {T('card.settings.location.reload')}
          </Alert>
        )}

        {customSettingsView}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="tertiary_negative"
          size="lg"
          title={T('card.settings.remove')}
          onClick={handleClickRemove}
          className="!tw-rounded-none !tw-px-0"
        >
          {T('card.settings.remove')}
        </Button>
        <div style={{flex: 1}} />
        <Button
          variant="secondary_default"
          size="lg"
          title={T('card.settings.duplicate')}
          onClick={handleClickedDuplicate}
          disabled={!isValid}
          className="!tw-ml-0 !tw-mr-3"
        >
          {T('card.settings.duplicate')}
        </Button>
        <Button
          variant="primary_default"
          size="lg"
          title={T('card.settings.save')}
          onClick={handleClickSave}
          disabled={!isValid}
          className="!tw-mr-0 tw-ml-3"
        >
          {T('card.settings.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
