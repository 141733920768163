import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {T} from '../../../utils/Internationalization';

interface DisplayImagesFieldProps {
  readOnly: boolean;
  handleClickEditDisplayImages: () => void;
}
export const DisplayImagesField = (props: DisplayImagesFieldProps) => {
  const {readOnly = true, handleClickEditDisplayImages} = props;
  return (
    <span>
      {T('chargingStationConfiguration.property.displayImages.configureImages')}
      &nbsp;
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickEditDisplayImages}>
          <Edit />
        </Button>
      )}
    </span>
  );
};
