import * as React from 'react';
import type {SVGProps} from 'react';

const SvgEmailSent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    width={512}
    height={371.13}
    viewBox="0 0 512 371.13"
    {...props}
  >
    <path d="M397 141.12c63.51 0 115 51.5 115 115.01 0 63.5-51.49 115-115 115s-115.02-51.5-115.02-115c0-63.51 51.51-115.01 115.02-115.01zM28.8 0h389.26c15.73 0 28.52 12.88 28.5 28.53l-.1 95.75c-7.58-2.84-15.46-5.04-23.59-6.55l.07-77.07-125.82 98.89 9.17 8.99c-3.04 2.56-5.94 5.24-8.75 8.04l-.09.1c-3.27 3.27-6.37 6.72-9.32 10.29l-10.89-10.69-42.14 35.87c-4.49 3.77-11.46 4.22-16.5.12l-44.24-36.1L39.45 282.69h219.27a140.08 140.08 0 0 0 6.71 23.6H28.49C12.74 306.29 0 293.42 0 277.76L.24 28.52C.27 12.84 13.05 0 28.8 0zm-5.19 261.9 130.45-122.08L23.82 41.69l-.21 220.21zM42.65 23.6l183.96 141.87L400.69 23.6H42.65zm358.01 180.04 49.96 51.69-51.52 53.32-16.07-15.44 25.81-26.71-65.47.07V244.3l65.53-.07-24.3-25.15 16.06-15.44z" />
  </svg>
);

export default SvgEmailSent;
