import React from 'react';
import {NavLink as RsNavLink, NavLinkProps} from 'reactstrap';

import './NavLink.scss';
import {classes} from '../../utils/Styles';

export function NavLink(props: NavLinkProps) {
  let {active, className, children, ...otherProps} = props;

  // cheated here -> not clear why TypeScript is complaining here
  return (
    <RsNavLink active={active} className={classes('dash-nav-Link', active ? 'active' : '', className)} {...otherProps}>
      {children}
    </RsNavLink>
  );
}
