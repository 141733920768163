import React, {useMemo} from 'react';

import {Icon} from '../../components/Icon';
import {SelectInput} from '../../components/inputs/SelectInput';
import {MiniCardHeader} from '../../components/MiniCardHeader';
import {getPeriodRangeForTimezone, Period, PeriodRoundingMode} from '../../components/PeriodSelector';
import {VerticalLayout} from '../../components/VerticalLayout';
import {useCarChargingStatuses, createConnectorStatusRequest} from '../../livedata/LiveCarChargingStatus';
import {getSidePower} from '../../livedata/LiveChargingUtils';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {
  getChargingStatusColor,
  isCharging,
  getChargingStatusColors,
  translateCarChargerStatus
} from '../../models/ChargingStatus';
import {Interval} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {extractLoadFromConsumption} from '../../utils/ConsumptionUtils';
import {useChargingStationForLocation} from '../../utils/FunctionalData';
import {useLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {ICardProps, ICardType, CardTypeKey, CardCategory, CardLocationAwareness} from '../CardType';
import {useCardLocation, useCardChargingStationGroup} from '../CardUtils';
import {CustomSettings} from '../components/CardView';
import {MiniCard, miniCardProperties} from '../MiniCard';

import {MiniConnectorGraph, IMiniConnectorGraphData} from './MiniConnectorGraph';

interface IChargingConnectorQuickViewSettings extends ICardSettings {
  connector: number;
}

let refreshKey = 1;
const MyCardView = (props: ICardProps<IChargingConnectorQuickViewSettings>) => {
  const {fetch, settings} = props;
  const {connector} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const [child] = useChargingStationForLocation(fetch, location);
  const chargingStationGroup = useCardChargingStationGroup(settings);
  const chargingStationGroupUuid = chargingStationGroup && chargingStationGroup.uuid;

  const [graphData] = useLoader<IMiniConnectorGraphData>(async () => {
    if (!child || !locationId || !locationTimezone || child.serviceLocationId !== locationId) {
      return undefined;
    }

    const period = getPeriodRangeForTimezone(
      {
        period: Period.DAYS_14,
        interval: Interval.DAY
      },
      locationTimezone,
      undefined,
      PeriodRoundingMode.ROUND_DOWN_GRAPH
    );
    const channelIndices = child.getChannelIndexes(connector);
    if (channelIndices === undefined) return undefined;

    const consumption = await fetch(
      'data',
      api => api.getElectricityConsumption(locationId, period),
      plural('electricityValue')
    );
    const seriesData = extractLoadFromConsumption(consumption, channelIndices, 0.001);
    return {
      ...period,
      data: seriesData,
      timezone: locationTimezone,
      connector,
      refresh: refreshKey++
    };
  }, [connector, child, locationId, locationTimezone]);

  const statusRequests = useMemo(() => {
    if (!child || !chargingStationGroupUuid) return [];

    const request = createConnectorStatusRequest(chargingStationGroupUuid, child, connector);
    return request ? [request] : [];
  }, [child, chargingStationGroupUuid, connector]);

  const statuses = useCarChargingStatuses(statusRequests, true, true);
  const state =
    child &&
    (child.data.trackingSerialNumber === undefined ? undefined : statuses.getState(child.data.trackingSerialNumber));
  const status = state && state.state[connector - 1];
  const power = state && state.power;
  const perSidePower = (state && state.perSidePower) || None;

  const customSettings: CustomSettings<IChargingConnectorQuickViewSettings> = (settings, updateSettings) => {
    const {connector} = settings;
    const connectors = [1, 2];
    return (
      <SelectInput value={connector.toString()} onChange={value => updateSettings({connector: parseInt(value)})}>
        {connectors.map(connector => (
          <option key={connector} value={connector.toString()}>
            {T('chargingConnectorQuickView.connector', {
              connector: connector.toString()
            })}
          </option>
        ))}
      </SelectInput>
    );
  };

  const ultra = child ? child.isUltra() : false;
  let subtitle = (status && translateCarChargerStatus(status, ultra)) || '...';
  if (status && isCharging(status, ultra) && child) {
    subtitle += ` ${getSidePower(power, perSidePower, child, connector)}`;
  }

  const colors = getChargingStatusColors(getChargingStatusColor(status, ultra));
  let title = T('chargingConnectorQuickView.headerTitle', {
    position: connector.toString()
  });
  if (settings.locationId && location) title += ` (${location.name})`;

  return (
    <MiniCard
      borderColor={colors.border}
      backgroundColor={colors.background}
      settingsHandleColor={colors.foreground}
      customSettings={customSettings}
      {...miniCardProperties(props)}
    >
      <VerticalLayout>
        <MiniCardHeader title={title} subtitle={subtitle} icon={Icon.Car} color={colors.foreground} />
        <div style={{flexGrow: 1, flexShrink: 1}}>
          {graphData && location && (
            <MiniConnectorGraph key={graphData.refresh} data={graphData} color={colors.foreground} />
          )}
        </div>
      </VerticalLayout>
    </MiniCard>
  );
};

const DEFAULT_CARD_SETTINGS: IChargingConnectorQuickViewSettings = {
  connector: 1
};
const CARD: ICardType<IChargingConnectorQuickViewSettings> = {
  type: CardTypeKey.ChargingConnectorQuickView,
  title: 'chargingConnectorQuickView.title',
  description: 'chargingConnectorQuickView.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  width: 1,
  height: 1,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.RequiresChargingStation,
  cardClass: MyCardView
  //  initSettingsPanel: ChargingConnectorQuickViewSettings,
};
export default CARD;
