import * as React from 'react';

import {useAppContext} from '../../../app/context';
import {IOrganization} from '../../../models/Organization';
import {IPricingPolicy} from '../../../models/PricingPolicy';
import {ConfigurePricingPolicy} from '../configurePricingPolicy/ConfigurePricingPolicy';

interface ConnectPricingPolicyContextProps {
  organization: ConnectPricingPolicyProviderProps['organization'];
  pricingPolicies: IPricingPolicy[];
  selectedPricingPolicy?: IPricingPolicy;
  chargingSettings?: ConnectPricingPolicyProviderProps['chargingSettings'];
  handleSelectPricingPolicy(policy?: IPricingPolicy): void;
  handleAddNewPolicy(): void;
  handleEditPolicy(policy: IPricingPolicy): void;
}

const ConnectPricingPolicyContext = React.createContext<ConnectPricingPolicyContextProps | null>(null);
ConnectPricingPolicyContext.displayName = 'ConnectPricingPolicyContext';

interface ConnectPricingPolicyProviderProps {
  chargingSettings?: {
    pricingPolicy?: IPricingPolicy;
    parentPricingPolicy?: IPricingPolicy;
  };
  organization?: Pick<IOrganization, 'id' | 'name' | 'currency'>;
  onSelect?(policy: IPricingPolicy): void;
  children: React.ReactNode;
}

// import { ConnectPricingPolicyProvider } from "path-to-context/ConnectPricingPolicyContext"
// use <ConnectPricingPolicyProvider> as a wrapper around the part you need the context for
function ConnectPricingPolicyProvider({
  chargingSettings,
  onSelect,
  organization,

  children
}: ConnectPricingPolicyProviderProps) {
  const [pricingPolicies, setPricingPolicies] = React.useState<IPricingPolicy[]>([]);
  const [configuringPolicy, setConfiguringPolicy] = React.useState<boolean>(false);
  const [editingPolicy, setEditingPolicy] = React.useState<IPricingPolicy | undefined>(undefined);
  const {api} = useAppContext();

  const [selectedPricingPolicy, setSelectedPricingPolicy] = React.useState<IPricingPolicy | undefined>(
    chargingSettings?.pricingPolicy
  );

  React.useEffect(() => {
    if (chargingSettings?.pricingPolicy?.current || !organization?.id || !chargingSettings?.pricingPolicy?.id) return;

    api.pricingPolicies.get(organization?.id, chargingSettings?.pricingPolicy.id).then(() => {
      setSelectedPricingPolicy(chargingSettings?.pricingPolicy);
    });
  }, [api, chargingSettings?.pricingPolicy, organization?.id]);

  React.useEffect(() => {
    async function getPricingPolicies() {
      if (!organization?.id) return;

      const policies = await api.pricingPolicies.getForOrganization(organization?.id);
      setPricingPolicies(policies);
    }

    getPricingPolicies();
  }, [api, organization, organization?.id]);

  React.useEffect(() => {
    setSelectedPricingPolicy(chargingSettings?.pricingPolicy);
  }, [chargingSettings?.pricingPolicy]);

  const handleSelectPricingPolicy = React.useCallback(
    policy => {
      setSelectedPricingPolicy(policy);
      onSelect?.(policy);
    },
    [onSelect]
  );

  const onSaveAddPolicy = React.useCallback(
    (policy: IPricingPolicy) => {
      setPricingPolicies(policies => [...policies.filter(p => p.id !== policy.id), policy]);
      handleSelectPricingPolicy(policy);
      setEditingPolicy(undefined);
      setConfiguringPolicy(false);
    },
    [handleSelectPricingPolicy]
  );

  const handleAddNewPolicy = React.useCallback(() => {
    setEditingPolicy(undefined);
    setConfiguringPolicy(true);
  }, []);

  const handleEditPolicy = React.useCallback((policy: IPricingPolicy) => {
    setEditingPolicy(policy);
    setConfiguringPolicy(true);
  }, []);

  if (!organization) return null;

  return (
    <ConnectPricingPolicyContext.Provider
      value={{
        chargingSettings,
        handleSelectPricingPolicy,
        organization,
        pricingPolicies,
        selectedPricingPolicy,
        handleAddNewPolicy,
        handleEditPolicy
      }}
    >
      <ConfigurePricingPolicy
        isOpen={configuringPolicy}
        policy={editingPolicy}
        organization={organization}
        onClose={() => setConfiguringPolicy(false)}
        onSave={onSaveAddPolicy}
      />
      {children}
    </ConnectPricingPolicyContext.Provider>
  );
}

// import { useConnectPricingPolicy } fron "path-to-context/ConnectPricingPolicyContext"
// within functional component
// const { sessionToken, ...ConnectPricingPolicyContext } = useConnectPricingPolicy()
function useConnectPricingPolicy(): ConnectPricingPolicyContextProps {
  const context = React.useContext(ConnectPricingPolicyContext);

  if (!context) {
    throw new Error('You should use useConnectPricingPolicy within an ConnectPricingPolicyContext');
  }

  return context;
}

export {ConnectPricingPolicyProvider, useConnectPricingPolicy};
