import React, {ReactNode} from 'react';

import {FormProvider} from '../../utils/FormContext';
import {testingClasses} from '../../utils/TestingClasses';
import {ModalErrorMessage} from '../ModalErrorMessage';

import {Modal, ModalHeader, ModalBody, ModalFooter} from '.';

interface NotificationModalProps {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  error?: string;
  loading?: boolean;
  note?: ReactNode;
}

export function NotificationModal(props: NotificationModalProps) {
  const {isOpen, onToggle, title, children, size = 'md', error, loading} = props;

  return (
    <FormProvider>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        autoFocus={false}
        size={size}
        className={loading ? testingClasses.loading : ''}
        data-testid={testingClasses.loading}
      >
        <ModalHeader toggle={onToggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        {error && (
          <ModalFooter>
            <ModalErrorMessage message={error} /> : note
          </ModalFooter>
        )}
      </Modal>
    </FormProvider>
  );
}
