import cn from 'classnames';
import React, {ReactNode} from 'react';

import {ExtractProps} from '../../utils/Types';

import {Icon} from '../Icon';
import {IconButton} from '../IconButton';

import styles from './RowActions.module.scss';

interface IRowActionsProps {
  children: ReactNode;
  className?: string;
}
export const RowActions = (props: IRowActionsProps) => (
  <div className={(cn(styles['row-actions']), props.className ?? '')}>{props.children}</div>
);

interface IRowActionButtonProps {
  title: string;
  icon?: keyof typeof Icon;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
  invisible?: boolean;
  action?: 'default' | 'delete';
}

export function RowActionButton(props: Omit<ExtractProps<typeof IconButton>, 'icon'> & IRowActionButtonProps) {
  const {title, icon, disabled, onClick, className, invisible, ...rest} = props;

  return (
    <IconButton
      {...rest}
      icon={icon ?? 'QuestionCircle'}
      size="sm"
      color="link"
      withoutPadding
      title={title}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        marginRight: '15px',
        visibility: invisible ? 'hidden' : 'visible'
      }}
      className={className}
    />
  );
}
