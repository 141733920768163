import {ActivePeriod} from '../../components/PeriodSelector';
import {IDevice, IDeviceInputChannel} from '../../models/Device';
import {ISensorReadingsMulti} from '../../models/SensorReadings';

export interface InputModuleValue {
  timestamp: number;
  values: (number | null)[];
}

export interface IInputModuleChartData {
  channels: IDeviceInputChannel[];
  readings: InputModuleValue[];
  period: ActivePeriod;
  module: IDevice;
}

export function collectReadings(readings: ISensorReadingsMulti[]): InputModuleValue[] {
  const results = new Map<number, InputModuleValue>();
  const pattern: (number | null)[] = readings.map(() => null);
  readings.forEach((reading, index) => {
    for (let usage of reading.usages) {
      for (let interval of usage.intervals) {
        let result = results.get(interval.timestamp);
        if (result === undefined) {
          result = {
            timestamp: interval.timestamp,
            values: [...pattern]
          };
          results.set(interval.timestamp, result);
        }
        if (interval.value !== undefined) result.values[index] = interval.value;
      }
    }
  });
  const items = [...results.values()];
  items.sort((a, b) => a.timestamp - b.timestamp);
  return items;
}
