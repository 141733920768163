import React, {useCallback, useMemo} from 'react';

import {RowActionButton} from '../../components/bootstrap/RowActions';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SelectInput} from '../../components/inputs/SelectInput';
import Table from '../../components/Table';
import RowActions from '../../components/Table/RowActions';
import {UserRights} from '../../models/AuthUser';
import {BillingType, IBilling, PaymentState, translatePaymentState} from '../../models/Billing';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {Contract, translateContract} from '../../models/Contract';
import {IOrganization} from '../../models/Organization';
import {
  CalculatedCurrencyField,
  CalculatedStringField,
  ComponentField,
  DateCalculatedField,
  ITableField,
  StringField
} from '../../models/Table';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useUserLocale} from '../CardUtils';
import {CardActions} from '../components/CardActions';
import {CardView, cardViewProps} from '../components/CardView';

interface InvoicesSettings extends ICardSettingsWithTable {
  organizationId?: number;
}

const rowKey = (row: IBilling) => `${row.type}${row.id}`; // type + id

const Billings = (props: ICardProps<InvoicesSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const [paymentStatusFilter, setPaymentStatusFilter] = React.useState('');
  const [servicesFilter, setServicesFilter] = React.useState('');
  const intl = useUserLocale();
  const [invoices] = useCardLoader<IBilling[]>(
    api => {
      if (organizationId === undefined) return Promise.resolve(undefined);
      return api.billings.getBillings(organizationId);
    },
    [organizationId],
    plural('invoice'),
    None
  );

  const filteredInvoices = useMemo(() => {
    let result = invoices;
    result = result.filter(invoice => invoice.status !== 'DRAFT' && invoice.status !== 'DELETED');
    if (paymentStatusFilter.length) {
      result = result.filter(invoice => invoice.paymentState === paymentStatusFilter);
    }

    if (servicesFilter) {
      if (servicesFilter === 'SelfBills') {
        result = result.filter(invoice => invoice.type === BillingType.SelfBilling);
      } else {
        result = result.filter(invoice => invoice.contractType === servicesFilter);
      }
    }
    return result;
  }, [invoices, paymentStatusFilter, servicesFilter]);

  const columns = useMemo<ITableField<IBilling>[]>(() => {
    return [
      new CalculatedStringField('type', T('invoices.field.type'), row => {
        return row.type;
      }),
      new StringField('invoiceNumber', T('invoices.field.invoiceId')),
      new CalculatedStringField('status', T('invoices.field.invoiceStatus'), row => {
        return row.status;
      }),
      new CalculatedStringField('paymentState', T('invoices.field.paymentStatus'), row => {
        return translatePaymentState(row.paymentState);
      }),
      new CalculatedCurrencyField(
        'totalAmount',
        T('invoices.field.amount'),
        item => item.totalAmount?.value,
        item => item.totalAmount?.currency || 'EUR'
      ),
      new DateCalculatedField('date', T('invoices.field.date'), intl, invoice =>
        Math.floor(new Date(invoice?.date).getTime())
      ),
      new CalculatedStringField('contractType', T('invoices.field.service'), row =>
        translateContract(row.contractType)
      ),
      new StringField('reference', T('invoices.field.reference')),
      new ComponentField('actions', T('generic.actions'), item => (
        <RowActions style={{justifyContent: 'flex-start'}}>
          {item.links.bill && (
            <RowActionButton
              icon="Pdf"
              title={T('invoices.actions.downloadInvoice')}
              onClick={() => handleDownload(item.links.bill, T('invoices.actions.downloadInvoice'))}
            />
          )}
          {item.links.csv && (
            <RowActionButton
              icon="Csv"
              title={T('invoices.actions.downloadCsv')}
              onClick={() => handleDownload(item.links.csv, T('invoices.actions.downloadCsv'))}
            />
          )}
          {item.links.sessions && (
            <RowActionButton
              icon="Report"
              title={T('invoices.actions.downloadSessions')}
              onClick={() => handleDownload(item.links.sessions, T('invoices.actions.downloadSessions'))}
            />
          )}
        </RowActions>
      ))
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organizationId: org ? org.id : undefined});
    },
    [updateSettings]
  );

  const handleDownload = (url: string, label: string) => {
    const link = document.createElement('a');
    link.download = label;
    link.style.display = 'none';
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const cardActions = () => (
    <CardActions>
      {inputOrganizations.showInput && (
        <OrganizationInput
          name="organization"
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={handleChangeOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      <SelectInput value={paymentStatusFilter || ''} onChange={setPaymentStatusFilter} style={{maxWidth: '15rem'}}>
        <option value="">{T('invoices.paymentStatus.any')}</option>
        <option value={PaymentState.Paid}>{T('invoices.paymentStatus.paid')}</option>
        <option value={PaymentState.NotPaid}>{T('invoices.paymentStatus.unpaid')}</option>
      </SelectInput>
      <SelectInput value={servicesFilter || ''} onChange={setServicesFilter} style={{maxWidth: '15rem'}}>
        <option value="">{T('invoices.services.any')}</option>
        <option value={Contract.ChargingHost}>{T('organizations.contracts.chargingHost')}</option>
        <option value="SelfBills">{`${T('organizations.contracts.chargingHost')} (${T(
          'invoices.type.selfBilling'
        )})`}</option>
        <option value={Contract.eMSPBusiness}>{T('organizations.contracts.emspBusiness')}</option>
        <option value={Contract.eMSPPersonal}>{T('organizations.contracts.emspPersonal')}</option>
        <option value={Contract.SplitBilling}>{T('organizations.contracts.splitBilling')}</option>
      </SelectInput>
    </CardActions>
  );

  return (
    <CardView actions={cardActions} {...cardViewProps(props)}>
      <Table
        items={filteredInvoices}
        fields={columns}
        hasPaging={true}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noDefaultSort={true}
        rowKey={rowKey}
        noun="invoice"
      />
    </CardView>
  );
};

const defaultSettings: InvoicesSettings = {
  table: {
    pageSize: 10,
    columns: [
      //{name: 'type', visible: true},
      {name: 'invoiceNumber', visible: true},
      //{name: 'status', visible: true},
      {name: 'paymentState', visible: true},
      {name: 'totalAmount', visible: true},
      {name: 'contractType', visible: true},
      {name: 'date', visible: true},
      {name: 'reference', visible: true},
      {name: 'links', visible: true}
    ]
  }
};

const CARD: ICardType<InvoicesSettings> = {
  type: CardTypeKey.Invoices,
  title: 'invoices.title',
  description: 'invoices.description',
  locationAware: CardLocationAwareness.Unaware,
  categories: [CardCategory.EV],
  rights: UserRights.ServiceDesk | UserRights.PartnerAdmin | UserRights.Partner,
  width: 4,
  height: 2,
  defaultSettings,
  cardClass: Billings
};
export default CARD;
