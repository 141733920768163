import {NotificationManager} from 'react-notifications';

import {isAPIResponse} from '../api/APIClient';

import {T} from './Internationalization';

export const enum ServerErrorCode {
  InvalidOrganization = 'invalid.organization',
  UserRequired = 'user.required',
  UserInvalid = 'user.invalid',
  UsernameExists = 'username.exists',
  OrganizationRequired = 'organization.required',
  OrganizationNoAccess = 'organization.noAccess', // user is not part of / has no access to organization
  OrganizationDuplicateName = 'organization.duplicate',
  FromRequired = 'from.required',
  ChargingStationSerialRequired = 'chargingStationSerial.required',
  RFIDRequired = 'rfid.required',
  RefundRequired = 'refund.required',
  InvoiceRequired = 'invoice.required',
  EmailRequired = 'email.required',
  ChargingStationSerialInvalid = 'chargingStationSerial.invalid',
  RefundRateRequired = 'refund.rate.required',
  RefundBankRequired = 'refund.bank.required',
  RefundBankBICRequired = 'refund.bank.bic.required',
  RefundBankIBANRequired = 'refund.bank.iban.required',
  RefundBankIBANUsed = 'refund.bank.iban.used',
  RefundInvoiceAddressRequired = 'refund.invoice.address.required',
  RefundInvoiceStreetNameRequired = 'refund.invoice.streetName.required',
  RefundInvoiceStreetNumberRequired = 'refund.invoice.streetNumber.required',
  RefundInvoicePostalCodeRequired = 'refund.invoice.postalCode.required',
  RefundInvoiceMunicipalityRequired = 'refund.inoice.municipality.required',
  PeriodOverlaps = 'period.overlaps',
  HasSessions = 'hasSessions',
  SepaMissing = 'sepa.missing',
  BillingInfoMissing = 'billing.info.missing',
  ContractActivationUnavailable = 'contract.activation.unavailable',
  SessionBilled = 'session.billed',
  NoPositionForLocation = 'location.noPosition',

  UserNotRegistered = 'user.not.registered',
  BankAccountMandateNotUnique = 'bank.account.mandate.unique',
  InvalidCountry = 'invalid.country',
  PartnerNotEditable = 'partner.not.editable',
  InvalidBillingCountry = 'invalid.billing.country',
  InvalidVat = 'invalid.vat',
  InvalidBankAccount = 'invalid.bank.account',
  BankAccountMissing = 'bank.account.missing',
  FieldsMissing = 'fields.missing',
  IbanDuplicate = 'iban.duplicate',
  DuplicateToken = 'duplicate.token',
  DeviceNotFound = 'device.notFound',
  OptimileFailed = 'optimile.failed',
  PartnerNotFound = 'partner.not.found',
  ContractUnavailable = 'contract.unavailable',
  LocationNoAddress = 'location.noAddress',
  SepaPurposeUnknown = 'sepa.purpose.unknown',
  OrganizationNotFound = 'organization.notFound',
  OrganizationNotLinkedToOdoo = 'organization.notLinkedToOdoo',
  OrganizationLinkedToOdoo = 'organization.linkedToOdoo',
  SourceNotLinkedToOdoo = 'source.notLinkedToOdoo',
  CompanyNameRequired = 'companyName.required',
  ModeInvalid = 'mode.invalid',

  InvalidOCPPUrlFormat = 'invalid.ocpp.url.format',
  InvalidOCPPUrlProtocol = 'invalid.ocpp.url.protocol',
  InvalidOCPPUrlHost = 'invalid.ocpp.url.host',
  InvalidOCPPUrlInsecure = 'invalid.ocpp.url.insecure',

  ParentNotFound = 'parent.notFound',
  ParentCannotUseChargingPark = 'parent.cannotUseChargingPark',
  ParentCannotUseChargingStation = 'parent.cannotUseChargingStation',
  ParentCannotUseDefault = 'parent.cannotUseDefault',
  ParentIncompatiblePhaseType = 'parent.incompatiblePhaseType',
  ParentMultipleGrids = 'parent.multipleGrids',

  CPOVatInvalid = 'cpo.vat.invalid',
  CPOEmailInvalid = 'cpo.email.invalid',
  CPOPhoneInvalid = 'cpo.phone.invalid',
  CPODataInvalid = 'cpo.data.invalid',

  RoamingPriceFixedTooHigh = 'roaming.price.fixed.tooHigh',
  RoamingPriceEnergyTooHigh = 'roaming.price.energy.tooHigh',
  RoamingPriceHourlyTooHigh = 'roaming.price.hourly.tooHigh',
  SpecificPricingInvalid = 'specific.pricing.invalid',
  SortTimedComponents = 'Time components must be sorted',

  TargetOrganizationNotLinkedToOdoo = 'organization.notLinkedToOdoo',
  TargetOrganizationLinkedToOdoo = 'organization.linkedToOdoo',
  SourceOrganizationNotLinkedToOdoo = 'source.notLinkedToOdoo',
  ContractDuplicate = 'contract.duplicate',
  ContractExists = 'contract.alreadyExists',

  SepaRequiredByOtherContracts = 'sepa.required',
  EMSPHasCards = 'emsp.has.cards',
  EMSPHasBillableSessions = 'emsp.has.billableSessions',
  SplitBillingHasAgreements = 'splitBilling.has.agreements',
  SplitBillingHasUnbilled = 'splitBilling.has.unbilled',
  PaymentPackageHasStations = 'paymentPackage.has.stations',
  PaymentPackageHasUnbilled = 'paymentPackage.has.unbilled',
  ContractHasBills = 'contract.has.bills',
  VATValidationVaild = 'vat.validation.failed',

  VatInvalid = 'invalid.vat',

  NilmInvalid = 'nilm.invalid',
  FunctionTypeUnsupported = 'functionType.unsupported',

  TooManyResults = 'search.tooManyResults',

  // TODO: add translations for these
  ModuleSwapOtherStationMissing = 'module.swap.other.station.missing',
  ModuleSwapNoOtherModule = 'module.swap.no.other.module',
  ModuleSwapOtherModuleMissing = 'module.swap.other.module.missing',

  PricingGroupInUse = 'discount.group.in.use',
  RFIDCardInUse = 'rfid.card.inUse',
  RFIDCardUnknown = 'rfid.card.unknown',

  ScanFailedChargingStationNotActive = 'scan.failed.charging.station.not.active',
  ScanFailedNoSuchController = 'scan.failed.no.such.controller',
  ControllerAlreadyInUse = 'controller.already.in.use',
  PositionRequired = 'position.required',
  PositionAlreadyInUse = 'position.already.in.use',
  TariffRequired = 'tariff.required',
  CSMSRequired = 'csms.required',
  PricingPolicyNotFound = 'pricing.policy.not.found',
  PricingPolicyDiscountNegative = 'pricing.policy.discount.negative',
  PricingPolicyDiscountTooHigh = 'pricing.policy.discount.tooHigh',
  PricingPolicyStillAssigned = 'pricing.policy.still.assigned',
  OperatorNotFound = 'operator.not.found',
  OperatorCurrencyMissing = 'operator.currency.missing',
  CSMSNotSupported = 'csms.not.supported',
  RoamingTariffRequired = 'roaming.tariff.required',
  OperatorRequired = 'operator.required',
  QRCodeAlreadyInUse = 'qr.code.already.in.use',
  AddingChargeControllerNotSupported = 'add.charge.controller.not.supported',
  AddingLEDControllerNotSupported = 'add.led.controller.not.supported',
  ModuleTypeNotSupported = 'module.type.not.supported',
  ChargingStationNotFound = 'charging.station.not.found',
  CardNotEnabledForSmappeeCharging = 'card.not.enable.for.smappee.charging',
  CardNotOwnedByUser = 'card.not.owned.by.user',

  ChargingControllerAlreadyExists = 'charging.controller.already.exists',
  ChargingStationModulePositionRequired = 'charging.station.module.position.required',
  ChargingStationModulePositionAlreadyInUse = 'charging.station.module.position.already.in.use',
  ChargingStationModuleSerialNumberInconsistent = 'charging.station.module.serial.number.inconsistent',
  MissingCpo = 'missing.cpo',
  IncompatibleCpo = 'incompatible.cpo',
  ChargingStationTariffRequired = 'charging.station.tariff.required',
  PricingPolicyRequired = 'pricingPolicy.required',

  SessionInvalidPaymentType = 'session.invalid.paymenttype',
  SessionInvalidMeterReadings = 'session.invalid.meterreadings',
  SessionAlreadyBilled = 'session.already.billed',
  AgreementForOtherStation = 'agreement.for.incorrect.station',
  CardAlreadyActivated = 'card.alreadyActivated',

  EmailExists = 'email.exists',
  SSOInUse = 'sso.id.in.use',
  MicrosoftEmailExists = 'ms.email.exists',
  MicrosoftTokenMissingEmail = 'ms.token.missing.email',
  GoogleTokenMissingEmail = 'google.token.missing.email',
  AppleTokenMissingEmail = 'apple.token.missing.email'
}

export function translateError(error: unknown, defaultError?: string, context?: {[key: string]: string}): string {
  if (isAPIResponse(error)) {
    if (error.success) {
      return ''; // all is OK, what's the problem...?
    }
    if (error.localizedError) {
      return error.localizedError;
    }
    if (error.code) {
      const translated = translateErrorCode(error.code, context);
      if (translated) {
        return translated;
      }
    }
    if (error.statusCode === 403) {
      return T('errors.noPermission');
    }
  }
  return defaultError || T('errors.unknown');
}

export function reportError(error: unknown, defaultError?: string) {
  NotificationManager.error(translateError(error, defaultError));
}

export function translateErrorCode(code: string, context?: {[key: string]: string}): string | undefined {
  switch (code) {
    case 'Odoo service not initialized':
      return T('odoo.unavailable.error');
    case ServerErrorCode.SortTimedComponents:
      return T('pricingPolicies.error.timedComponentsSorted');
    case ServerErrorCode.ParentNotFound:
      return T('locationConfiguration.setParent.notFound');
    case ServerErrorCode.ParentCannotUseChargingPark:
      return T('locationConfiguration.setParent.cannotUseChargingPark');
    case ServerErrorCode.ParentCannotUseChargingStation:
      return T('chargingStationConfiguration.setParent.cannotLinkToStation');
    case ServerErrorCode.ParentCannotUseDefault:
      return T('locationConfiguration.setParent.cannotUseDefault');
    case ServerErrorCode.ParentIncompatiblePhaseType:
      return T('locationConfiguration.setParent.incompatiblePhaseType');
    case ServerErrorCode.ParentMultipleGrids:
      return T('locationConfiguration.setParent.multipleGrids');
    case ServerErrorCode.HasSessions:
      return T('splitBilling.delete.hasSessions');
    case ServerErrorCode.SessionBilled:
      return 'Cannot fix session after it has been billed';
    case ServerErrorCode.UsernameExists:
      return T('users.error.alreadyInUse', context);
    case ServerErrorCode.CPOVatInvalid:
      return T('chargingStationConfiguration.error.cpo.vat.invalid');
    case ServerErrorCode.CPOEmailInvalid:
      return T('chargingStationConfiguration.error.cpo.email.invalid');
    case ServerErrorCode.CPOPhoneInvalid:
      return T('chargingStationConfiguration.error.cpo.phone.invalid');
    case ServerErrorCode.CPODataInvalid:
      return T('chargingStationConfiguration.error.cpo.data.invalid');
    case ServerErrorCode.RoamingPriceFixedTooHigh:
      return T('chargingStationConfiguration.fixedTariff.tooHigh');
    case ServerErrorCode.RoamingPriceEnergyTooHigh:
      return T('chargingStationConfiguration.energyTariff.tooHigh');
    case ServerErrorCode.RoamingPriceHourlyTooHigh:
      return T('chargingStationConfiguration.timeTariff.tooHigh');
    case ServerErrorCode.SpecificPricingInvalid:
      return T('pricingPolicies.error.specificPriceInvalid');
    case ServerErrorCode.SepaRequiredByOtherContracts:
      return T('contracts.delete.error.sepa.required');
    case ServerErrorCode.EMSPHasCards:
      return T('contracts.delete.error.emsp.has.cards');
    case ServerErrorCode.EMSPHasBillableSessions:
      return T('contracts.delete.error.emsp.has.billableSessions');
    case ServerErrorCode.SplitBillingHasAgreements:
      return T('contracts.delete.error.splitBilling.has.agreements');
    case ServerErrorCode.SplitBillingHasUnbilled:
      return T('contracts.delete.error.splitBilling.has.unbilled');
    case ServerErrorCode.PaymentPackageHasStations:
      return T('contracts.delete.error.paymentPackage.has.stations');
    case ServerErrorCode.PaymentPackageHasUnbilled:
      return T('contracts.delete.error.paymentPackage.has.unbilled');
    case ServerErrorCode.ContractHasBills:
      return T('contracts.delete.error.contract.has.bills');
    case ServerErrorCode.TargetOrganizationNotLinkedToOdoo:
      return T('contracts.move.error.mustHaveOdooPartner');
    case ServerErrorCode.TargetOrganizationLinkedToOdoo:
      return T('contracts.move.error.mustNotHaveOdooPartner');
    case ServerErrorCode.SourceOrganizationNotLinkedToOdoo:
      return T('contracts.move.error.sourceNotLinkedToOdoo');
    case ServerErrorCode.ContractDuplicate:
      return T('contracts.move.error.duplicate');
    case ServerErrorCode.ContractExists:
      return T('contracts.error.exists');
    case ServerErrorCode.VATValidationVaild:
      return T('organizationRegistration.error.vatValidationFailed');
    case ServerErrorCode.InvalidOCPPUrlFormat:
      return T('chargingStationConfiguration.ocpp.url.invalid.format');
    case ServerErrorCode.InvalidOCPPUrlProtocol:
      return T('chargingStationConfiguration.ocpp.url.invalid.protocol');
    case ServerErrorCode.InvalidOCPPUrlHost:
      return T('chargingStationConfiguration.ocpp.url.invalid.host');
    case ServerErrorCode.InvalidOCPPUrlInsecure:
      return T('chargingStationConfiguration.ocpp.url.invalid.insecure');
    case ServerErrorCode.UserNotRegistered:
      return T('errors.userNotRegisteredForService');
    case ServerErrorCode.BankAccountMandateNotUnique:
      return T('errors.bank.account.mandate.unique');
    case ServerErrorCode.InvalidCountry:
      return T('organizationRegistration.error.countryNotSupported');
    case ServerErrorCode.PartnerNotEditable:
      return T('errors.partner.not.editable');
    case ServerErrorCode.InvalidBillingCountry:
      return T('organizationRegistration.error.countryNotSupported');
    case ServerErrorCode.InvalidVat:
      return T('validator.invalidVAT');
    case ServerErrorCode.InvalidBankAccount:
      return T('validator.invalidIBAN');
    case ServerErrorCode.BankAccountMissing:
      return T('errors.bank.account.missing');
    case ServerErrorCode.FieldsMissing:
      return T('errors.fields.missing');
    case ServerErrorCode.IbanDuplicate:
      return T('organizationRegistration.billing.iban.duplicate');
    case ServerErrorCode.DuplicateToken:
      return T('errors.duplicate.token');
    case ServerErrorCode.DeviceNotFound:
      return T('errors.device.notFound');
    case ServerErrorCode.OptimileFailed:
      return T('errors.optimile.failed');
    case ServerErrorCode.PartnerNotFound:
      return T('errors.partner.not.found');
    case ServerErrorCode.ContractUnavailable:
      return T('errors.contract.unavailable');
    case ServerErrorCode.LocationNoAddress:
      return T('errors.location.noAddress');
    case ServerErrorCode.SepaPurposeUnknown:
      return T('errors.sepa.purpose.unknown');
    case ServerErrorCode.OrganizationNotFound:
      return T('errors.organization.notFound');
    case ServerErrorCode.OrganizationNotLinkedToOdoo:
      return T('contracts.move.error.mustHaveOdooPartner');
    case ServerErrorCode.OrganizationLinkedToOdoo:
      return T('contracts.move.error.mustNotHaveOdooPartner');
    case ServerErrorCode.OrganizationDuplicateName:
      return T('organization.error.nameExists');
    case ServerErrorCode.SourceNotLinkedToOdoo:
      return T('contracts.move.error.sourceNotLinkedToOdoo');
    case ServerErrorCode.CompanyNameRequired:
      return T('errors.companyName.required');
    case ServerErrorCode.ModeInvalid:
      return T('contracts.move.error.mode.invalid');
    case ServerErrorCode.NoPositionForLocation:
      return T('errors.location.noPosition');
    case ServerErrorCode.PricingGroupInUse:
      return T('pricingGroups.delete.error.inUse');
    case ServerErrorCode.RFIDCardUnknown:
      return T('pricingGroups.add.errors.invalidToken');
    case ServerErrorCode.RFIDCardInUse:
      return T('pricingGroups.add.errors.tokenInUse');
    case ServerErrorCode.PricingPolicyRequired:
      return T('chargingStationConfiguration.property.pleaseSelectPricingPolicy');
    case ServerErrorCode.PricingPolicyStillAssigned:
      return T('pricingPolicies.delete.cpo.failed');
    case ServerErrorCode.MissingCpo:
      return T('chargingStation.move.missing.cpo');
    case ServerErrorCode.IncompatibleCpo:
      return T('chargingStation.move.incompatible.cpo');
    case ServerErrorCode.SessionInvalidPaymentType:
      return T('chargingSessions.error.invalidPaymentType');
    case ServerErrorCode.SessionInvalidMeterReadings:
      return T('chargingSessions.error.invalidMeterReadings');
    case ServerErrorCode.SessionAlreadyBilled:
      return T('chargingSessions.error.alreadyBilled');
    case ServerErrorCode.AgreementForOtherStation:
      return T('chargingSessions.error.agreementForOtherStation');
    case ServerErrorCode.EmailExists:
      return T('errors.email.exists');
    case ServerErrorCode.SSOInUse:
      return T('errors.sso.inUse');
    case ServerErrorCode.MicrosoftEmailExists:
      return T('errors.sso.emailExists');
    case ServerErrorCode.MicrosoftTokenMissingEmail:
    case ServerErrorCode.GoogleTokenMissingEmail:
    case ServerErrorCode.AppleTokenMissingEmail:
      return T('errors.sso.noEmail');
    case ServerErrorCode.CardAlreadyActivated:
      return T('errors.card.alreadyActivated');
    default:
      console.log(`Unrecognized error code: ${code}`);
      return undefined;
  }
}
