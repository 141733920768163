import {Configuration, PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import * as React from 'react';

import {getAuthParameters, hasFeature, AppFeature} from '../utils/AppParameters';

import {AppleAuthProvider} from './AppleAuthProvider';

const {google, microsoft, apple, redirectUrl} = getAuthParameters();

const microsoftConfig: Configuration = {
  auth: {
    clientId: microsoft
  }
};

const pca = new PublicClientApplication(microsoftConfig);

function SocialLoginProviders({children}: {children: React.ReactNode}) {
  return (
    <GoogleOAuthProvider clientId={google}>
      <MsalProvider instance={pca}>
        <AppleAuthProvider clientId={apple} redirectUrl={redirectUrl}>
          {children}
        </AppleAuthProvider>
      </MsalProvider>
    </GoogleOAuthProvider>
  );
}

function AuthProviders({children}: {children: React.ReactNode}) {
  const AuthProviders = hasFeature(AppFeature.SocialLogin) ? SocialLoginProviders : React.Fragment;

  return <AuthProviders>{children}</AuthProviders>;
}

export {AuthProviders};
