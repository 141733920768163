import React, {useState} from 'react';

import {InputProps as RsInputProps} from 'reactstrap';

import {Button as RsButton, Input} from '../bootstrap';
import {EyeInvisible, EyeVisible} from '../ui-lib/icons/small';

interface PasswordInputProps {
  placeholder?: string;
  valid?: boolean;
  invalid?: boolean;
  name?: string;
  isHidden?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  className?: string;
}
export default function PasswordInput(props: PasswordInputProps & RsInputProps) {
  const {
    placeholder,
    valid,
    invalid,
    name = 'password',
    isHidden = false,
    value,
    onChange,
    onKeyPress,
    onBlur,
    className,
    ...otherProps
  } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClickedVisible = () => {
    setPasswordVisible(visible => !visible);
  };

  return (
    <div style={{display: isHidden ? 'none' : 'flex', flexDirection: 'row', position: 'relative'}}>
      <Input
        type={passwordVisible ? 'text' : 'password'}
        name={name}
        bsSize="lg"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        placeholder={placeholder}
        valid={valid}
        invalid={invalid}
        style={{flexGrow: 1}}
        className={className}
        {...otherProps}
      />
      <RsButton
        color="link"
        onClick={handleClickedVisible}
        tabIndex={-1}
        className={className}
        style={{position: 'absolute', width: 16, height: 22, top: 0, right: 0}}
      >
        {!isHidden && passwordVisible ? <EyeVisible /> : <EyeInvisible />}
      </RsButton>
    </div>
  );
}
