import {Layouts, Layout} from 'react-grid-layout';

import {AppStore} from '..';
import {getCardType} from '../../cards';
import {CardTypeKey, ICardType} from '../../cards/CardType';
import {IFluviusMeasurementCampaignCardSettings} from '../../cards/custom/FluviusMeasurementCampaigns';
import {IElectricityCardSettings} from '../../cards/ElectricityValues/Settings';
import {ILiveElectricityValuesSettings} from '../../cards/LiveElectricityValues';
import {LiveValuesMiniMode} from '../../cards/LiveValuesMini/LiveValuesMiniModels';
import {Period} from '../../components/PeriodSelector';
import {SortOrder} from '../../components/Table';
import API from '../../core/api';
import {AuthUser} from '../../models/AuthUser';
import {IBoard} from '../../models/Board';
import {BoardIconKey} from '../../models/BoardIcon';
import {CardDisplayType, HighOrLowLevel} from '../../models/CardSettings';
import {Contract} from '../../models/Contract';
import {ColumnGroupKey} from '../../models/Table';
import {Interval} from '../../models/UsageValue';
import {UserType} from '../../models/User';
import {T} from '../../utils/Internationalization';

import {BoardsActions} from './boards';

export interface ICardInitSettings {
  initId: string;
  type: CardTypeKey;
  settings?: any;
  x?: number;
  y?: number;
  width?: 1 | 2 | 3 | 4;
  height?: 1 | 2 | 3 | 4;
}

async function initializeBoard(
  api: API,
  store: AppStore,
  currentBoards: IBoard[],
  name: string,
  template: string | undefined,
  icon: BoardIconKey,
  cards: ICardInitSettings[],
  custom: boolean = false,
  revision: number,
  version?: 2
): Promise<IBoard[]> {
  store.dispatch({
    type: BoardsActions.INITIALIZING_BOARDS,
    payload: {name}
  });

  // Create board if it doesn't already exist
  let board = template ? currentBoards.find(board => board.settings?.template === template) : undefined;
  if (board === undefined) {
    // try with board name instead
    board = currentBoards.find(board => board.name.toLowerCase() === name.toLowerCase());
  }
  if (board === undefined) {
    board = await api.dashboard.createBoard({
      name,
      icon,
      settings: {
        custom,
        layouts: {},
        version,
        templateRevision: revision,
        template
      },
      version: 1
    });
    if (board === undefined) throw new Error(`Could not create board ${name}`);

    return initializeExistingBoard(api, board, cards, version);
  } else if ((board.settings?.version || 0) < (revision ?? 2)) {
    return initializeExistingBoard(api, board, cards, version);
  } else {
    return Promise.resolve(currentBoards);
  }
}

export async function initializeExistingBoard(api: API, board: IBoard, cards: ICardInitSettings[], version?: 2) {
  if (cards.length > 0) {
    await deleteUnknownCards(api, board);
    const idMap = await createMissingCards(api, board, cards);
    return setLayouts(api, board, cards, idMap);
  } else {
    return api.dashboard.getBoards();
  }
}

function deleteUnknownCards(api: API, board: IBoard): Promise<unknown> {
  return Promise.all(
    board.cards
      .filter(card => !(card.settings && card.settings.initId))
      .map(card => api.dashboard.deleteCard(board.id, card.id))
  );
}

async function createMissingCards(
  api: API,
  board: IBoard,
  cards: ICardInitSettings[]
): Promise<{[key: string]: string}> {
  const idMap: {[key: string]: string} = {};
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    let existing = board.cards.find(c => (c.settings ? c.settings.initId === card.initId : false));
    if (!existing) {
      board = await api.dashboard
        .createCard(board.id, {
          type: card.type,
          settings: {...card.settings, initId: card.initId}
        })
        .then(board => {
          if (!board) throw new Error('Could not create card');
          return board;
        });
      existing = board.cards.find(c => (c.settings ? c.settings.initId === card.initId : false));
    }
    if (existing !== undefined) idMap[card.initId] = existing.id;
  }
  return idMap;
}

async function setLayouts(
  api: API,
  board: IBoard,
  cards: ICardInitSettings[],
  idMap: {[key: string]: string}
): Promise<IBoard[]> {
  const layoutSpecs: ILayoutSpec[] = [];
  cards.forEach(card => {
    const id = idMap[card.initId];
    if (!id) return;

    layoutSpecs.push({
      id,
      type: getCardType(card.type),
      x: card.x,
      y: card.y,
      w: card.width,
      h: card.height
    });
  });
  const layouts =
    board.settings?.version === 2 ? generateCompactLayout(layoutSpecs) : generateDefaultLayouts(layoutSpecs);

  return api.dashboard
    .updateBoard({
      id: board.id,
      icon: board.icon,
      name: board.name,
      order: board.order,
      settings: {...board.settings, layouts, custom: false}
    })
    .then(updatedBoard => {
      if (!updatedBoard) {
        throw new Error(`Could not update board ${board.name}`);
      }

      return updatedBoard;
    });
}

interface ILayoutSpec {
  id: string;
  type: ICardType<any>;
  x?: number;
  y?: number;
  w?: 1 | 2 | 3 | 4;
  h?: 1 | 2 | 3 | 4;
}
function generateDefaultLayouts(cards: ILayoutSpec[]): Layouts {
  const lg: Layout[] = [];
  let x = 0;
  let y = 0;

  cards.forEach(card => {
    const {w = 1, h = 1, id} = card;
    lg.push({w, h, x, y, i: `card-${id}`, moved: false, static: false});
    if (w === 2) {
      y++;
    } else {
      x++;
      if (x === 2) {
        x = 0;
        y++;
      }
    }
  });

  return {lg};
}

const COLS_LG = 4;
const COLS_XS = 2;
function generateCompactSubLayout(cards: ILayoutSpec[], cols: number): Layout[] {
  const layouts: Layout[] = [];
  let x = 0;
  let y = 0;
  for (let card of cards) {
    const id = `card-${card.id}`;
    layouts.push({
      x: card.x || x,
      y: card.y || y,
      w: card.w || card.type.width,
      h: card.h || card.type.height,
      i: id
    });

    if (card.x === undefined || card.y === undefined) {
      x += card.w || card.type.width;
      if (x >= cols) {
        y += card.h || card.type.height;
        x = 0;
      }
    }
  }
  return layouts;
}
export function generateCompactLayout(cards: ILayoutSpec[]): Layouts {
  return {
    lg: generateCompactSubLayout(cards, COLS_LG),
    xs: generateCompactSubLayout(cards, COLS_XS)
  };
}

export const SimplifiedBoardCards: ICardInitSettings[] = [
  {
    initId: 'liveConsumption',
    type: CardTypeKey.LiveValuesMini,
    x: 0,
    y: 0,
    settings: {mode: LiveValuesMiniMode.Consumption}
  },
  {
    initId: 'liveSolar',
    type: CardTypeKey.LiveValuesMini,
    x: 1,
    y: 0,
    settings: {mode: LiveValuesMiniMode.Solar}
  },
  {
    initId: 'liveAlwaysOn',
    type: CardTypeKey.LiveValuesMini,
    x: 2,
    y: 0,
    settings: {mode: LiveValuesMiniMode.AlwaysOn}
  },
  {
    initId: 'partner',
    type: CardTypeKey.PartnerInfoMini,
    x: 3,
    y: 0,
    width: 1,
    height: 2
  },
  {
    initId: 'electricity',
    type: CardTypeKey.ElectricityValues,
    x: 0,
    y: 1,
    width: 3,
    height: 3
  },
  {initId: 'suggestions', type: CardTypeKey.SuggestionsMini, x: 3, y: 2},
  {initId: 'map', type: CardTypeKey.LocationMapMini, x: 3, y: 3}
];

export const EVBoardCards: ICardInitSettings[] = [
  {initId: 'overview', type: CardTypeKey.ChargingStationOverview, width: 4},
  {initId: 'stations', type: CardTypeKey.ChargingStations, width: 2},
  {
    initId: 'configuration',
    type: CardTypeKey.ChargingStationConfiguration,
    width: 2
  },
  {initId: 'transactions', type: CardTypeKey.ChargingTransactions, width: 4},
  {
    initId: 'consumption',
    type: CardTypeKey.ChargingStationConsumption,
    width: 4
  },
  {initId: 'whitelisting', type: CardTypeKey.ChargingRFIDWhitelist, width: 4},
  {
    initId: 'automations',
    type: CardTypeKey.ChargingStationAutomations,
    width: 4
  },
  {initId: 'splitBilling', type: CardTypeKey.SplitBilling, width: 4}
];

function initializeSimplifiedBoard(store: AppStore, api: API, currentBoards: IBoard[]): Promise<IBoard[]> {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'My board',
    'simplified',
    BoardIconKey.Usage,
    SimplifiedBoardCards,
    undefined,
    0,
    2
  );
}

async function initializeEmptyBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(api, store, currentBoards, 'My board', undefined, BoardIconKey.Usage, [], true, 0);
}

async function initializePartnerLocationsBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Locations',
    undefined,
    BoardIconKey.Location,
    [
      {
        initId: 'locationMap',
        type: CardTypeKey.Locations,
        settings: {cardType: CardDisplayType.Map},
        width: 2
      },
      {
        initId: 'locationList',
        type: CardTypeKey.Locations,
        settings: {cardType: CardDisplayType.Table},
        width: 2
      },
      {
        initId: 'locationConfiguration',
        type: CardTypeKey.LocationConfiguration,
        settings: {}
      },
      {initId: 'messages', type: CardTypeKey.Messages, settings: {}}
    ],
    true,
    0
  );
}

async function initializePartnerElectricityBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Electricity',
    undefined,
    BoardIconKey.Electricity,
    [
      {
        initId: 'electricityChart',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: true},
              {name: 'alwaysOn', visible: true},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: true},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 2
      },
      {
        initId: 'electricityTable',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: true},
              {name: 'alwaysOn', visible: true},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: true},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 2
      },
      {
        initId: 'live',
        type: CardTypeKey.LiveElectricityValues,
        settings: {
          cardType: HighOrLowLevel.Low,
          table: {
            pageSize: 10,
            sortColumn: 'index',
            sortOrder: 'A',
            columns: [
              {name: 'name', visible: true},
              {name: 'phase', visible: true},
              {name: 'location', visible: false},
              {name: 'ctType', visible: false},
              {name: 'active', visible: true},
              {name: 'reactive', visible: true},
              {name: 'powerFactor', visible: true},
              {name: 'apparent', visible: true},
              {name: 'current', visible: true},
              {name: 'phaseVoltage', visible: true},
              {name: 'lineVoltage', visible: true},
              {name: 'reversed', visible: false}
            ]
          }
        },
        width: 2
      }
    ],
    true,
    0
  );
}

async function initializePartnerCompareBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Compare Locations',
    undefined,
    BoardIconKey.ChartArea,
    [
      {
        initId: 'compare1',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: false},
              {name: 'alwaysOn', visible: false},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: false},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 1
      },
      {
        initId: 'compare2',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: false},
              {name: 'alwaysOn', visible: false},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: false},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 1
      },
      {
        initId: 'compare3',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: false},
              {name: 'alwaysOn', visible: false},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: false},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 1
      },
      {
        initId: 'compare4',
        type: CardTypeKey.ElectricityValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'timestamp',
            sortOrder: 'D',
            columns: [
              {name: 'value', visible: true},
              {name: 'solar', visible: false},
              {name: 'alwaysOn', visible: false},
              {name: 'import', visible: false},
              {name: 'export', visible: false},
              {name: 'self_sufficiency', visible: false},
              {name: 'self_consumption', visible: false},
              {group: 'channels.active', visible: true},
              {group: 'channels.reactive', visible: false},
              {group: 'channels.powerFactor', visible: false},
              {group: 'channels.apparent', visible: false},
              {group: 'channels.current', visible: false},
              {group: 'phase.voltage', visible: false},
              {group: 'line.voltage', visible: false}
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>,
        width: 1
      }
    ],
    true,
    0
  );
}

async function initializePartnerConfigurationBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Configuration',
    undefined,
    BoardIconKey.Tachometer,
    [
      {
        initId: 'live',
        type: CardTypeKey.LiveElectricityValues,
        settings: {
          cardType: HighOrLowLevel.Low,
          table: {
            pageSize: 10,
            sortColumn: 'index',
            sortOrder: 'A',
            columns: [
              {name: 'name', visible: true},
              {name: 'phase', visible: true},
              {name: 'location', visible: true},
              {name: 'ctType', visible: true},
              {name: 'active', visible: true},
              {name: 'reactive', visible: true},
              {name: 'powerFactor', visible: true},
              {name: 'apparent', visible: true},
              {name: 'current', visible: true},
              {name: 'phaseVoltage', visible: true},
              {name: 'lineVoltage', visible: true},
              {name: 'reversed', visible: true}
            ]
          }
        },
        width: 2
      },
      {
        initId: 'phasorDisplay',
        type: CardTypeKey.PhasorDisplay,
        settings: {},
        width: 1
      },
      {
        initId: 'smartDevices',
        type: CardTypeKey.SmartDevices,
        settings: {},
        width: 1
      }
    ],
    true,
    0
  );
}

async function initializePartnerUserManagementBoard(
  store: AppStore,
  api: API,
  currentBoards: IBoard[],
  withUsers: boolean
) {
  const cards: ICardInitSettings[] = [];
  if (withUsers) {
    cards.push({
      initId: 'users',
      type: CardTypeKey.Users,
      settings: {},
      width: 1
    });
    cards.push({
      initId: 'organizationUsers',
      type: CardTypeKey.OrganizationUsers,
      settings: {},
      width: 1
    });
  }
  cards.push({
    initId: 'sharedUsers',
    type: CardTypeKey.SharedUsers,
    settings: {},
    width: 1
  });
  return initializeBoard(api, store, currentBoards, 'User Management', undefined, BoardIconKey.Award, cards, true, 0);
}

async function initializePartnerSwitchBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Switch',
    undefined,
    BoardIconKey.Cube,
    [
      {
        initId: 'switchChart',
        type: CardTypeKey.SwitchValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5
        }
      },
      {
        initId: 'switchTable',
        type: CardTypeKey.SwitchValues,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.DAYS_7,
          interval: Interval.MINUTES_5
        }
      }
    ],
    true,
    0
  );
}

async function initializePartnerNILMBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'NILM',
    undefined,
    BoardIconKey.Usage,
    [
      {
        initId: 'appliances',
        type: CardTypeKey.Appliances,
        settings: {}
      },
      {
        initId: 'events',
        type: CardTypeKey.Events,
        settings: {}
      },
      {
        initId: 'liveValues',
        type: CardTypeKey.LiveValues,
        settings: {}
      }
    ],
    true,
    0
  );
}

async function initializePartnerGasBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Gas',
    undefined,
    BoardIconKey.Gas,
    [
      {
        initId: 'gasGraph',
        type: CardTypeKey.GasValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5
        }
      },
      {
        initId: 'gasTable',
        type: CardTypeKey.GasValues,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5
        }
      },
      {
        initId: 'gasReadingsGraph',
        type: CardTypeKey.GasWaterReadings,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'time',
            sortOrder: SortOrder.DESCENDING,
            columns: [
              {name: 'tmp', visible: true},
              {name: 'hum', visible: true},
              {name: 'bat', visible: true},
              {name: 'val1', visible: true},
              {name: 'val2', visible: true}
            ]
          }
        }
      },
      {
        initId: 'gasReadingsTable',
        type: CardTypeKey.GasWaterReadings,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'time',
            sortOrder: SortOrder.DESCENDING,
            columns: [
              {name: 'tmp', visible: true},
              {name: 'hum', visible: true},
              {name: 'bat', visible: true},
              {name: 'val1', visible: true},
              {name: 'val2', visible: true}
            ]
          }
        }
      }
    ],
    true,
    0
  );
}

async function initializePartnerWaterBoard(store: AppStore, api: API, currentBoards: IBoard[]) {
  return initializeBoard(
    api,
    store,
    currentBoards,
    'Water',
    undefined,
    BoardIconKey.Water,
    [
      {
        initId: 'waterGraph',
        type: CardTypeKey.WaterValues,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5
        }
      },
      {
        initId: 'waterTable',
        type: CardTypeKey.WaterValues,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5
        }
      },
      {
        initId: 'waterReadingsGraph',
        type: CardTypeKey.GasWaterReadings,
        settings: {
          cardType: CardDisplayType.Chart,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'time',
            sortOrder: SortOrder.DESCENDING,
            columns: [
              {name: 'tmp', visible: true},
              {name: 'hum', visible: true},
              {name: 'bat', visible: true},
              {name: 'val1', visible: true},
              {name: 'val2', visible: true}
            ]
          }
        }
      },
      {
        initId: 'waterReadingsTable',
        type: CardTypeKey.GasWaterReadings,
        settings: {
          cardType: CardDisplayType.Table,
          period: Period.THIS_WEEK,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            sortColumn: 'time',
            sortOrder: SortOrder.DESCENDING,
            columns: [
              {name: 'tmp', visible: true},
              {name: 'hum', visible: true},
              {name: 'bat', visible: true},
              {name: 'val1', visible: true},
              {name: 'val2', visible: true}
            ]
          }
        }
      }
    ],
    true,
    0
  );
}

async function initializeFluviusConfiguratorCampaignBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Nieuwe meetcampagne',
    'fluviusCampaigns',
    BoardIconKey.Location,
    [
      {
        initId: 'campaigns',
        type: CardTypeKey.FluviusMeasurementCampaigns,
        width: 2,
        settings: {
          name: 'Nieuwe meetcampagne',
          table: {
            pageSize: 10,
            columns: [
              {name: 'region', visible: true},
              {name: 'set', visible: true},
              {name: 'type', visible: true},
              {name: 'status', visible: true},
              {name: 'name', visible: true},
              {name: 'vpkOrCabinId', visible: true},
              {name: 'city', visible: true},
              {name: 'reason', visible: true},
              {name: 'notes', visible: true}
            ]
          }
        } as Partial<IFluviusMeasurementCampaignCardSettings>
      }
    ],
    0
  );
}

async function initializeFluviusConfiguratorLocationsBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Meetcampagne zoeken',
    'fluviusLocations',
    BoardIconKey.Location,
    [
      {initId: 'locations', type: CardTypeKey.Locations},
      {initId: 'configuration', type: CardTypeKey.LocationConfiguration},
      {
        initId: 'liveElectricity',
        type: CardTypeKey.LiveElectricityValues,
        settings: {
          table: {
            pageSize: 10,
            columns: [
              {name: 'name', visible: true},
              {name: 'phase', visible: true},
              {name: 'location', visible: true},
              {name: 'ctType', visible: false},
              {name: 'active', visible: true},
              {name: 'reactive', visible: true},
              {name: 'powerFactor', visible: true},
              {name: 'apparent', visible: false},
              {name: 'current', visible: true},
              {name: 'phaseVoltage', visible: true},
              {name: 'lineVoltage', visible: false},
              {name: 'reversed', visible: false}
            ]
          }
        } as Partial<ILiveElectricityValuesSettings>
      }
    ],
    0
  );
}

async function initializeFluviusViewerCampaignsBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Meetcampagnes',
    'fluviusViewerCampaigns',
    BoardIconKey.Location,
    [
      {
        initId: 'locationsMap',
        type: CardTypeKey.Locations,
        settings: {cardType: CardDisplayType.Map}
      },
      {
        initId: 'locationsList',
        type: CardTypeKey.Locations,
        settings: {cardType: CardDisplayType.Table}
      },
      {
        initId: 'configuration',
        type: CardTypeKey.LocationConfiguration
      }
    ],
    0
  );
}

async function initializeFluviusViewerLiveBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Live meetwaarden',
    'fluviusViewerLive',
    BoardIconKey.Electricity,
    [
      {
        initId: 'liveElectricityValues',
        type: CardTypeKey.LiveElectricityValues,
        settings: {
          table: {
            pageSize: 10,
            columns: [
              {name: 'name', visible: true},
              {name: 'phase', visible: true},
              {name: 'location', visible: true},
              {name: 'ctType', visible: false},
              {name: 'active', visible: true},
              {name: 'reactive', visible: true},
              {name: 'powerFactor', visible: true},
              {name: 'apparent', visible: false},
              {name: 'current', visible: true},
              {name: 'phaseVoltage', visible: true},
              {name: 'lineVoltage', visible: false},
              {name: 'reversed', visible: false}
            ]
          }
        } as Partial<ILiveElectricityValuesSettings>
      },
      {initId: 'phasorDisplay', type: CardTypeKey.PhasorDisplay}
    ],
    0
  );
}

async function initializeFluviusViewerHistoricalBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Historische meetwaarden',
    'fluviusHistorical',
    BoardIconKey.ChartLine,
    [
      {
        initId: 'currents',
        type: CardTypeKey.ElectricityValues,
        settings: {
          name: 'Stromen (A)',
          level: HighOrLowLevel.Low,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            columns: [
              {
                group: ColumnGroupKey.ChannelsCurrent,
                visible: true
              }
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>
      },
      {
        initId: 'voltages',
        type: CardTypeKey.ElectricityValues,
        settings: {
          name: 'Spanningen (V)',
          level: HighOrLowLevel.Low,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            columns: [
              {
                group: ColumnGroupKey.PhaseVoltage,
                visible: true
              },
              {
                group: ColumnGroupKey.LineVoltage,
                visible: true
              }
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>
      },
      {
        initId: 'activePower',
        type: CardTypeKey.ElectricityValues,
        settings: {
          name: 'Actief vermogen (kW)',
          level: HighOrLowLevel.Low,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            columns: [
              {
                group: ColumnGroupKey.ChannelsActive,
                visible: true
              }
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>
      },
      {
        initId: 'reactivePower',
        type: CardTypeKey.ElectricityValues,
        settings: {
          name: 'Reactief vermogen (kVAr)',
          level: HighOrLowLevel.Low,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            columns: [
              {
                group: ColumnGroupKey.ChannelsReactive,
                visible: true
              }
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>
      },
      {
        initId: 'dataTable',
        type: CardTypeKey.ElectricityValues,
        width: 2,
        settings: {
          name: 'Tabel spanningen en stromen',
          cardType: CardDisplayType.Table,
          interval: Interval.MINUTES_5,
          table: {
            pageSize: 10,
            columns: [
              {
                group: ColumnGroupKey.ChannelsCurrent,
                visible: true
              },
              {
                group: ColumnGroupKey.PhaseVoltage,
                visible: true
              },
              {
                group: ColumnGroupKey.LineVoltage,
                visible: true
              }
            ],
            grouped: true
          }
        } as Partial<IElectricityCardSettings>
      }
    ],
    0
  );
}

async function initializeFluviusViewerHarmonicsBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Harmonischen',
    'fluviusHarmonics',
    BoardIconKey.ChartBar,
    [
      {initId: 'liveHarmonics', type: CardTypeKey.LiveHarmonics, width: 2},
      {initId: 'harmonics', type: CardTypeKey.Harmonics, width: 2}
    ],
    0
  );
}

async function initializeFluviusTechnicianCampaignsBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Meetcampagnes',
    'fluviusTechnicians',
    BoardIconKey.Location,
    [
      {
        initId: 'locations',
        type: CardTypeKey.Locations,
        settings: {
          cardType: CardDisplayType.Map
        }
      },
      {initId: 'configuration', type: CardTypeKey.LocationConfiguration}
    ],
    0
  );
}

function initializeFluviusTechnicianConnectionsBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    'Controle aansluiting',
    'fluviusConnections',
    BoardIconKey.Smappee,
    [
      {
        initId: 'liveElectricity',
        type: CardTypeKey.LiveElectricityValues,
        settings: {
          table: {
            pageSize: 10,
            columns: [
              {name: 'name', visible: true},
              {name: 'phase', visible: true},
              {name: 'location', visible: true},
              {name: 'ctType', visible: false},
              {name: 'active', visible: true},
              {name: 'reactive', visible: true},
              {name: 'powerFactor', visible: true},
              {name: 'apparent', visible: false},
              {name: 'current', visible: true},
              {name: 'phaseVoltage', visible: true},
              {name: 'lineVoltage', visible: false},
              {name: 'reversed', visible: false}
            ]
          }
        } as Partial<ILiveElectricityValuesSettings>
      },
      {initId: 'phasorDisplay', type: CardTypeKey.PhasorDisplay}
    ],
    0
  );
}

function initializeSplitBillingBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    T('organizationRegistration.splitBillingBoardName'),
    'splitBilling',
    BoardIconKey.Car,
    [
      {
        initId: 'splitBilling',
        type: CardTypeKey.SplitBilling,
        width: 4
      }
    ],
    0
  );
}

function initializePublicChargingBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    T('organizationRegistration.publicChargingBoardName'),
    'publicCharging',
    BoardIconKey.Car,
    [
      {
        initId: 'publicCharging',
        type: CardTypeKey.PublicChargingTokens,
        width: 2
      },
      {
        initId: 'sessions',
        type: CardTypeKey.ChargingSessions,
        width: 4
      }
    ],
    0
  );
}

function initializeCPOBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    T('organizationRegistration.cpoBoardName'),
    'cpo',
    BoardIconKey.Car,
    [
      {initId: 'overview', type: CardTypeKey.CPOChargingSquares, width: 4},
      {initId: 'sessions', type: CardTypeKey.CPOChargingSessions, width: 4},
      {initId: 'stations', type: CardTypeKey.ChargingStations, width: 2},
      {
        initId: 'configuration',
        type: CardTypeKey.ChargingStationConfiguration,
        width: 2
      },
      {
        initId: 'transactions',
        type: CardTypeKey.ChargingTransactions,
        width: 4
      },
      {
        initId: 'consumption',
        type: CardTypeKey.ChargingStationConsumption,
        width: 4
      },
      {
        initId: 'whitelisting',
        type: CardTypeKey.ChargingRFIDWhitelist,
        width: 4
      },
      {
        initId: 'automations',
        type: CardTypeKey.ChargingStationAutomations,
        width: 4
      },
      {
        initId: 'pricinggroups',
        type: CardTypeKey.PricingGroups,
        width: 2
      },
      {
        initId: 'pricingpolicies',
        type: CardTypeKey.PricingPolicies,
        width: 2
      },
      {
        initId: 'chargingstationpriorities',
        type: CardTypeKey.ChargingStationPriorities,
        width: 4
      }
    ],
    1
  );
}

function initializeServicesBoard(initializer: BoardInitializer) {
  return initializer.addBoard(
    T('organizationRegistration.servicesBoardName'),
    'services',
    BoardIconKey.Bookmark,
    [
      {
        initId: 'services',
        type: CardTypeKey.Services
      },
      {
        initId: 'invoices',
        type: CardTypeKey.Invoices,
        width: 4
      }
    ],
    1
  );
}

class BoardInitializer {
  store: AppStore;
  api: API;
  currentBoards: IBoard[];

  constructor(store: AppStore, api: API, currentBoards: IBoard[]) {
    this.store = store;
    this.api = api;
    this.currentBoards = currentBoards;
  }

  addBoard(
    name: string,
    template: string | undefined,
    icon: BoardIconKey,
    cards: ICardInitSettings[],
    revision: number,
    custom: boolean = false
  ) {
    return initializeBoard(this.api, this.store, this.currentBoards, name, template, icon, cards, custom, revision);
  }
}

export async function initializeBoards(
  api: API,
  store: AppStore,
  currentBoards: IBoard[],
  user: AuthUser
): Promise<IBoard[]> {
  const initializer = new BoardInitializer(store, api, currentBoards);
  const organizations = await api.getUserOrganizations(user.userId);
  const role = user.role;
  let boards: IBoard[] = [];

  if (boards.length === 0 && organizations.some(org => org.name.toLowerCase() === 'fluvius')) {
    if (user.username.endsWith('_conf')) {
      // configurator
      await initializeFluviusConfiguratorCampaignBoard(initializer);
      return initializeFluviusConfiguratorLocationsBoard(initializer);
    } else if (user.username.endsWith('_tech')) {
      // technieker
      await initializeFluviusTechnicianCampaignsBoard(initializer);
      return initializeFluviusTechnicianConnectionsBoard(initializer);
    } else if (user.username.endsWith('_vnet') || user.username.endsWith('_vstu')) {
      // viewer
      await initializeFluviusViewerCampaignsBoard(initializer);
      await initializeFluviusViewerLiveBoard(initializer);
      await initializeFluviusViewerHistoricalBoard(initializer);
      return initializeFluviusViewerHarmonicsBoard(initializer);
    }
  }

  if (user.isRegularUser()) {
    if (boards.length === 0) {
      boards = await initializeSimplifiedBoard(store, api, currentBoards);
    }
  } else if (user.isPartnerUser()) {
    boards = currentBoards;

    if (organizations.some(org => org.contracts && org.contracts.includes(Contract.SplitBilling))) {
      boards = await initializeSplitBillingBoard(initializer);
    }
    if (organizations.some(org => org.contracts && org.contracts.includes(Contract.eMSPBusiness))) {
      boards = await initializePublicChargingBoard(initializer);
    }
    if (organizations.some(org => org.contracts && org.contracts.includes(Contract.ChargingHost))) {
      boards = await initializeCPOBoard(initializer);
    }
    if (organizations.some(org => org.contracts && org.contracts.length > 0)) {
      boards = await initializeServicesBoard(initializer);
    }

    if (boards.length === 0 && organizations.some(org => org.contracts === undefined || org.contracts.length === 0)) {
      await initializePartnerLocationsBoard(store, api, currentBoards);
      await initializePartnerElectricityBoard(store, api, currentBoards);
      await initializePartnerCompareBoard(store, api, currentBoards);
      await initializePartnerConfigurationBoard(store, api, currentBoards);
      await initializePartnerUserManagementBoard(store, api, currentBoards, role === UserType.PartnerAdmin);
      await initializePartnerSwitchBoard(store, api, currentBoards);
      await initializePartnerNILMBoard(store, api, currentBoards);
      await initializePartnerGasBoard(store, api, currentBoards);
      boards = await initializePartnerWaterBoard(store, api, currentBoards);
    }
  } else {
    boards = await initializeEmptyBoard(store, api, currentBoards);
  }
  return boards;
}
