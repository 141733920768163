import {useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {RowActions} from '../../components/bootstrap';

import Table, {migrateTableSettings} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Clear, Edit, Plus} from '../../components/ui-lib/icons/small';
import {cn} from '../../lib/utils';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IChargingPriority} from '../../models/ChargingPriorities';
import {
  ITableField,
  CalculatedStringField,
  IntegerField,
  ComponentField,
  FieldAlignment,
  StringField
} from '../../models/Table';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {T, plural} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardLocationAwareness, CardCategory, ICardProps} from '../CardType';
import {useCardChargingStationGroup, useUser} from '../CardUtils';
import ChargingStationParentButtonLink from '../ChargingStations/ChargingStationParentButtonLink';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import {AddToken} from './AddToken';
import {EditTokenPriority} from './EditTokenPriority';

type IChargingStationPrivilegesSettings = ICardSettingsWithTable;

function ChargingStationPrioritiesCard(props: ICardProps<IChargingStationPrivilegesSettings>) {
  const {settings, updateSettings} = props;

  const modals = useModals();
  const {api} = useAppContext();
  const chargingGroup = useCardChargingStationGroup(settings);
  const locationId = chargingGroup && chargingGroup.id;
  const user = useUser();
  const canEdit = !user.isReadOnly();

  const [items, refresh, setItems] = useCardLoader<IChargingPriority[]>(
    api => {
      if (locationId === undefined) return Promise.resolve(undefined);
      return api.chargingPriorities.getChargingPriorities(locationId);
    },
    [locationId],
    plural('privilege'),
    None
  );
  const defaultSettings = items.find(item => item.token === undefined);

  const fields: ITableField<IChargingPriority>[] = useMemo(() => {
    const handleClickedEdit = async (item: IChargingPriority) => {
      if (!locationId) return;

      const updated = await modals.show<IChargingPriority[] | null>(props => (
        <EditTokenPriority locationId={locationId} rule={item} {...props} />
      ));
      if (updated) setItems(updated);
    };

    const handleClickedRemove = async (item: IChargingPriority) => {
      if (locationId === undefined || item.token === undefined) return;

      const name = item.token || T('chargingStationPriorities.target.location');
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title={T('chargingStationPrivileges.remove.title')}
          message={T('chargingStationPrivileges.remove.message', {name})}
          acceptLabel={T('chargingStationPrivileges.remove.confirm')}
          rejectLabel={T('chargingStationPrivileges.remove.cancel')}
          {...props}
        />
      ));
      if (confirmed !== ConfirmationResult.Accept) return;

      const updated = await api.chargingPriorities.delete(locationId, item.id);
      setItems(updated);
    };

    return [
      new CalculatedStringField(
        'target',
        T('chargingStationPriorities.token'),
        priority => priority.token || T('chargingStationPriorities.target.location')
      ),
      new IntegerField('priority', T('chargingStationPrivileges.column.priority'), {unit: '%'}),
      new StringField('comment', T('chargingStationPrivileges.column.comment')),
      new ComponentField(
        'actions',
        T('chargingStationPrivileges.column.actions'),
        rule => (
          <RowActions>
            {canEdit && (
              <Button
                style={{color: 'black'}}
                variant="ghost_action_btn"
                size="lg"
                title={T('users.edit.title.update')}
                onClick={() => handleClickedEdit(rule)}
                className={cn('tw-mx-1 tw-p-0 tw-w-4 tw-h-4')}
              >
                <Edit width={16} height={16} />
              </Button>
            )}
            {canEdit && (
              <Button
                variant="ghost_action_btn"
                size="lg"
                title={T('users.remove.title')}
                onClick={() => handleClickedRemove(rule)}
                style={{
                  visibility: rule.token === undefined ? 'hidden' : 'visible'
                }}
                className={cn('tw-mx-1 tw-p-0 tw-w-4 tw-h-4')}
              >
                <Clear width={16} height={16} />
              </Button>
            )}
          </RowActions>
        ),
        {align: FieldAlignment.Center, autoInsert: 'end'}
      )
    ];
  }, [api, modals, locationId, canEdit, setItems]);

  const handleClickedAddUser = () => {
    if (!chargingGroup || !defaultSettings) return;

    modals
      .show<
        IChargingPriority[] | null
      >(props => <AddToken serviceLocation={chargingGroup.id} priority={defaultSettings.priority || 50} {...props} />)
      .then(updated => updated && setItems(updated));
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {/* <Spring /> */}
      {state.ready && canEdit && (
        <Button
          variant="secondary_default"
          size="lg"
          title={T('chargingStationPriorities.add')}
          onClick={handleClickedAddUser}
        >
          <span>
            <Plus className="!tw-mr-2" />
          </span>
          {T('chargingStationPriorities.add')}
        </Button>
      )}
    </CardActions>
  );

  const titleAddendum = <ChargingStationParentButtonLink location={chargingGroup} />;

  let error: string | undefined;
  if (chargingGroup === undefined) {
    error = T('chargingStationPrivileges.stationGroupRequired');
  }

  return (
    <CardView error={error} titleAddendum={titleAddendum} actions={actions} {...cardViewProps(props)}>
      <Table
        items={items}
        fields={fields}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="privilege"
      />
    </CardView>
  );
}

const DefaultSettings: IChargingStationPrivilegesSettings = {
  table: {
    pageSize: 20,
    columns: [
      {name: 'target', visible: true},
      {name: 'priority', visible: true},
      {name: 'comment', visible: true}
    ]
  }
};

const CARD: ICardType<IChargingStationPrivilegesSettings> = {
  type: CardTypeKey.ChargingStationPriorities,
  title: 'chargingStationPriorities.title',
  description: 'chargingStationPriorities.description',
  locationAware: CardLocationAwareness.Required,
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DefaultSettings,
  upgradeSettings: migrateTableSettings('table', DefaultSettings.table),
  cardClass: ChargingStationPrioritiesCard
};
export default CARD;
