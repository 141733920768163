import {ChargingCardInvitation, Invitation} from '../../models/Invitation';
import {RFIDCard} from '../../models/RFIDCard';

export interface ChargingCardEntry {
  id: string;
  value: string;
  name: string;
  username?: string;
  userFirstName?: string;
  userLastName?: string;
  employeeNumber?: string;
  card?: RFIDCard;
  invitation?: ChargingCardInvitation;
}

export function getChargingCardEntries(cards: RFIDCard[], invitations: ChargingCardInvitation[]): ChargingCardEntry[] {
  const cardEntries: ChargingCardEntry[] = cards.map(card => ({
    id: `C${card.id}`,
    value: card.value,
    name: card.name,
    username: card.username,
    userFirstName: card.userFirstName,
    userLastName: card.userLastName,
    employeeNumber: card.employeeNumber,
    card
  }));
  const invitationEntries: ChargingCardEntry[] = invitations.map(invitation => ({
    id: `I${invitation.id}`,
    value: invitation.rfid.value,
    name: invitation.rfid.name,
    userFirstName: invitation.invitee?.firstName,
    userLastName: invitation.invitee?.lastName,
    emailAddress: invitation.invitee?.emailAddress,
    employeeNumber: invitation.rfid.employeeNumber,
    invitation
  }));
  return cardEntries.concat(invitationEntries);
}
