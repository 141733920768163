import React, {useMemo, useEffect} from 'react';

import {Input, Label, FormGroup} from '../../components/bootstrap';
import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import PeriodSelector, {
  PeriodSettings,
  PeriodRoundingMode,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import {Period} from '../../components/PeriodSelector';
import Table from '../../components/Table';
import {ApplianceSource} from '../../models/Appliance';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {LocationFeature} from '../../models/Location';
import {ITableField, TimestampFieldWithTimezone, CalculatedNumberField} from '../../models/Table';
import {Interval, IUsageInterval, unitIsPowerInsteadOfConsumption, showAsLineGraph} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {BrandColors} from '../../utils/BrandColors';
import {useAppliances, useSwitchReadings} from '../../utils/FunctionalData';
import {createPointFormatter} from '../../utils/GraphUtils';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardLocationDetails} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';

import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';
import {SeriesOptionsType} from '../ElectricityValues/ElectricityValuesColumns';
import {SeriesElement} from '../ElectricityValues/Series';

import styles from './index.module.scss';

interface ISwitchValuesSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  applianceId?: string;
  series: string[];
}

function getTableColumns(unit: string, timezone?: string): ITableField<IUsageInterval>[] {
  return [
    new TimestampFieldWithTimezone('time', 'timestamp', T('switchValues.field.time'), timezone || 'UTC'),
    new CalculatedNumberField('activePower', T('switchValues.field.activePower', {unit}), item => item.value, {
      unit
    })
  ];
}
const rowKey = (item: IUsageInterval) => item.timestamp;

const SwitchValues = (props: ICardProps<ISwitchValuesSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {cardType, applianceId, interval} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.HomeControl);

  const [appliances, refreshAppliances] = useAppliances(fetch, locationId);
  const switches = useMemo(
    () => (appliances || None).filter(appliance => appliance.id && appliance.source === ApplianceSource.SWITCH),
    [appliances]
  );
  const currentSwitch = switches.find(plug => plug.id === applianceId);

  const activePeriod = usePeriodRangeForTimezone(settings, locationTimezone, PeriodRoundingMode.EXCLUSIVE);
  const [data, refreshReadings] = useSwitchReadings(fetch, locationId, currentSwitch && applianceId, activePeriod);

  useEffect(() => {
    if (!currentSwitch) {
      if (switches.length === 0 && appliances !== undefined) {
        updateSettings({applianceId: undefined});
      }
      if (switches.length > 0) {
        updateSettings({applianceId: switches[0].id});
      }
    }
  }, [updateSettings, currentSwitch, switches, appliances]);

  const handleChangePlug = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateSettings({applianceId: event.currentTarget.value});
  };

  const handleRefresh = () => {
    refreshAppliances();
    refreshReadings();
  };

  const hasSwitches = switches.length > 0;
  const switchOptions = useMemo(
    () =>
      switches.map(plug => (
        <option key={plug.id} value={plug.id}>
          {plug.name}
        </option>
      )),
    [switches]
  );
  const switchesPlaceholder = hasSwitches ? '' : T('switchValues.noSwitches');

  const unit = unitIsPowerInsteadOfConsumption(interval) ? 'W' : 'Wh';
  const columns = useMemo(() => getTableColumns(unit, locationTimezone), [unit, locationTimezone]);

  const actions: CustomActions = state => (
    <CardActions>
      {switches.length === 1 && isFeatureAvailable && <p className={styles.singlePlug}>{switches[0].name}</p>}
      {switches.length > 1 && isFeatureAvailable && (
        <Input
          type="select"
          name="plugId"
          value={applianceId}
          onChange={handleChangePlug}
          disabled={!hasSwitches}
          placeholder={switchesPlaceholder}
        >
          {switchOptions}
        </Input>
      )}
      {isFeatureAvailable && (
        <PeriodSelector
          settings={settings}
          onChanged={updateSettings}
          allowedIntervals={[Interval.MINUTES_5, Interval.HOUR, Interval.DAY, Interval.MONTH]}
        />
      )}
      {isFeatureAvailable && <Reload onReload={handleRefresh} />}
      {state.ready && data && isFeatureAvailable && (
        <ExportCsv
          name={state.title}
          fields={getTableColumns(unit, locationTimezone)}
          items={data.readings}
          range={settings}
          location={location}
          settings={settings.table}
        />
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (switches.length === 0) error = T('switchValues.noSwitches');

  const renderSettings: CustomSettings<ISwitchValuesSettings> = (settings, updateSettings) => {
    const {cardType} = settings;

    const handleCardTypeChanged = (cardType: CardDisplayType) => {
      updateSettings({cardType});
    };

    const handlePlugChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
      updateSettings({applianceId: event.currentTarget.value});
    };

    return (
      <div>
        <CardTypeSelector value={cardType} onChange={handleCardTypeChanged} />
        <FormGroup>
          <Label for="plugId">{T('switchValues.switch')}</Label>
          <Input
            type="select"
            name="plugId"
            value={applianceId || ''}
            onChange={handlePlugChanged}
            disabled={!switchOptions}
            placeholder={switchesPlaceholder}
          >
            {switchOptions}
          </Input>
        </FormGroup>
      </div>
    );
  };

  const chart = useMemo(() => {
    if (data === undefined || data.readings.length === 0) return <div />;

    const type = showAsLineGraph(interval) ? 'line' : 'column';

    const activeData: SeriesElement[] = [];
    const reactiveData: SeriesElement[] = [];

    for (let item of data.readings) {
      let {timestamp, value: activePowerValue = 0} = item;
      const reactivePowerValue = 0;

      activeData.push([timestamp, activePowerValue]);
      reactiveData.push([timestamp, reactivePowerValue]);
    }

    const activePower: SeriesOptionsType = {
      name: `${T('switchValues.field.activePower')} [${unit}]`,
      color: BrandColors.OrangeSunglow, // Sunglow
      type,
      data: activeData,
      tooltip: {
        pointFormatter: createPointFormatter(T('switchValues.field.activePower'), unit, 2)
      }
    } as SeriesOptionsType;

    const [config, actualFrom, actualTo] = createIntervalChart({
      period: data.period,
      series: [activePower],
      yAxis: [{title: {text: unit}}],
      noDataText: T('switchValues.noData')
    });

    return <HighStockChart className={styles.stretch} from={actualFrom} to={actualTo} config={config} />;
  }, [data, interval, unit]);

  if (!isFeatureAvailable) {
    error = T('switchValues.error.noSwitchValues');
  }

  return (
    <CardView
      ready={appliances !== undefined}
      actions={actions}
      error={error}
      customSettings={renderSettings}
      {...cardViewProps(props)}
    >
      {cardType === CardDisplayType.Chart && location && chart}
      {cardType === CardDisplayType.Table && data && (
        <Table
          fields={columns}
          items={data.readings}
          rowKey={rowKey}
          noun="electricityValue"
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
        />
      )}
    </CardView>
  );
};

function sanitizeSettings(settings: any): any | undefined {
  if (typeof settings.plugId === 'string' || settings.plugId === null) {
    return {plugId: undefined, applianceId: settings.plugId};
  }
}

const DEFAULT_SETTINGS: ISwitchValuesSettings = {
  cardType: CardDisplayType.Chart,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  series: [],
  table: {
    pageSize: 10,
    columns: [
      {name: 'time', visible: true},
      {name: 'activePower', visible: true}
    ]
  }
};
const CARD: ICardType<ISwitchValuesSettings> = {
  type: CardTypeKey.SwitchValues,
  title: 'switchValues.title',
  description: 'switchValues.description',
  categories: [CardCategory.ELECTRICITY, CardCategory.HOMECONTROL],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: SwitchValues,
  upgradeSettings: sanitizeSettings
};
export default CARD;
