import React, {useMemo} from 'react';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../components/ui/select';
import {IDevice} from '../../../models/Device';
import {getCTHubLocations} from '../../../models/DeviceType';

interface CTHubInputSelectorProps {
  readOnly: boolean;
  invalid: boolean;
  value: string;
  device: IDevice;
  onChange: (ctHubInput: string) => void;
}
export const CTHubInputSelector = (props: CTHubInputSelectorProps) => {
  const {readOnly, value, device, onChange} = props;

  const ctHubInputOptions = useMemo(
    () =>
      getCTHubLocations(device.type).map(x => (
        <SelectItem key={x} value={x}>
          {x}
        </SelectItem>
      )),
    [device.type]
  );

  return (
    <Select name="cthubinput" value={value} onValueChange={onChange} disabled={readOnly}>
      <SelectTrigger className="tw-pl-1 !tw-text-base">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>{ctHubInputOptions}</SelectGroup>
      </SelectContent>
    </Select>
  );
};
