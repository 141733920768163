import React, {useMemo, useEffect, useState, useCallback} from 'react';
import {NotificationManager} from 'react-notifications';
import {Nav, NavItem} from 'reactstrap';

import {LoadManagementSettings} from '../../api/LoadManagementAPI';
import {useAppContext} from '../../app/context';
import {
  Button as RsButton,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink
} from '../../components/bootstrap';
import {buttonColors} from '../../components/bootstrap/Button';
import {OnlineStatusIndicator} from '../../components/OnlineStatusIndicator';
import {migrateTableSettings, SortOrder} from '../../components/Table';
import {SettingsTable, PropertyEntry} from '../../components/Table/SettingsTable';
import {
  useLiveChargingStatus,
  createCarChargingStatusRequest,
  useLiveChargerPower
} from '../../livedata/LiveCarChargingStatus';
import {ConfirmationResult, ConfirmationPromiseModal} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ChargingStationPaymentType, IChargingStation} from '../../models/ChargingStation';
import {isCharging, isChargingPaused} from '../../models/ChargingStatus';
import {isChargingController} from '../../models/DeviceType';
import {IHighLevelConfiguration, PhaseType} from '../../models/HighLevelConfiguration';
import {ILocation, ILocationSummary, LocationFunctionType} from '../../models/Location';
import {Phase, getPhaseIndex} from '../../models/Phase';
import {IConfigurationPropertyValue, IBaseSmartDevice} from '../../models/SmartDevice';
import {UserType} from '../../models/User';
import {None} from '../../utils/Arrays';
import {
  useChargingStationForLocation,
  useLocation,
  useLocationActivationCode,
  useDeviceActivationCode
} from '../../utils/FunctionalData';
import {useAppSelector, useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {
  useCardChargingStation,
  useUser,
  useCardChargingStationGroup,
  useCardChargingStationGroupId,
  useCardLocation
} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {ChargingStationPaymentTypeSettings, EditPaymentTypesModal} from './EditPaymentTypesModal';
import {
  ConnectorsState,
  ConnectorState,
  PropertiesState,
  StationDetails
} from './models/ChargingStationConfiguration.model';
import {getProperties, Tab} from './properties';

type ChargingStationConfigurationSettings = ICardSettingsWithTable;

const ledControllerBrightness = 'etc.smart.device.type.car.charger.led.config.brightness';
const minCurrentPropertyName = 'etc.smart.device.type.car.charger.config.min.current';
const maxCurrentPropertyName = 'etc.smart.device.type.car.charger.config.max.current';
const maxPowerPropertyName = 'etc.smart.device.type.car.charger.config.max.power';
const minExcessPercentagePropertyName = 'etc.smart.device.type.car.charger.config.min.excesspct';

function getLedControllerBrightness(device: IBaseSmartDevice) {
  const property = device.configurationProperties.find(property => property.spec.name === ledControllerBrightness);
  const value = property && property.values[0].Integer;
  return value === null ? undefined : value;
}

function getPhaseMapping(mapping: Phase[]) {
  if (mapping.length === 2) {
    const remaining = [Phase.L1, Phase.L2, Phase.L3].filter(x => !mapping.includes(x));
    mapping.push(...remaining);
  }
  return mapping.map(phase => getPhaseIndex(phase)).join('');
}

function getPhaseAssignments(config: IHighLevelConfiguration): string[] {
  const result: string[] = [];
  for (var highLevel of config.measurements) {
    if (highLevel.appliance === undefined || highLevel.actuals.length === 0) {
      continue;
    }
    if (highLevel.appliance.type !== 'CAR_CHARGER') continue;

    let position = highLevel.actuals[0].midBusAddress;
    if (position === undefined) position = 1; // EV Wall Home has only 1 connector

    const phaseMapping = highLevel.actuals.map(item => item.phase);
    while (position >= result.length - 1) result.push('012');
    result[position] = getPhaseMapping(phaseMapping);
  }
  return result;
}

function getSmartDeviceConfigurationProperty(
  smartDevice: IBaseSmartDevice,
  propertyName: string
): IConfigurationPropertyValue | undefined {
  const property = smartDevice.configurationProperties.find(property => property.spec.name === propertyName);
  return property && property.values[0];
}

function getStationSmartDevicePropertyValues(chargingStation: IChargingStation): {
  [key: string]: Partial<ConnectorState>;
} {
  const connectorStates: {[key: string]: Partial<ConnectorState>} = {};

  chargingStation.modules.forEach(device => {
    if (!isChargingController(device) || !device.smartDevice) {
      return;
    }

    const smartDevice = device.smartDevice;
    if (!smartDevice) return;

    const deviceId = smartDevice.id;
    const connectorState: Partial<ConnectorState> = {};

    const minCurrentProperty = getSmartDeviceConfigurationProperty(smartDevice, minCurrentPropertyName);
    const maxCurrentProperty = getSmartDeviceConfigurationProperty(smartDevice, maxCurrentPropertyName);
    const maxPowerProperty = getSmartDeviceConfigurationProperty(smartDevice, maxPowerPropertyName);
    const minExcessPercentageProperty = getSmartDeviceConfigurationProperty(
      smartDevice,
      minExcessPercentagePropertyName
    );

    connectorState.minCurrent =
      (minCurrentProperty && minCurrentProperty.Quantity && minCurrentProperty.Quantity.value) || 0;
    connectorState.maxCurrent =
      (maxCurrentProperty && maxCurrentProperty.Quantity && maxCurrentProperty.Quantity.value) || 0;
    connectorState.maxPower = (maxPowerProperty && maxPowerProperty.Quantity && maxPowerProperty.Quantity.value) || 0;
    connectorState.maxPowerRange =
      smartDevice.configurationProperties.find(property => property.spec.name === maxPowerPropertyName)?.spec
        ?.possibleValues?.range?.to?.Quantity?.value || 0;
    connectorState.minExcessPercentage = (minExcessPercentageProperty && minExcessPercentageProperty.Integer) || 0;

    connectorStates[deviceId] = connectorState;
  });
  return connectorStates;
}

const ChargingStationConfiguration = (props: ICardProps<ChargingStationConfigurationSettings>) => {
  const [tab, setTab] = React.useState<string>(Tab.GENERAL);
  const {fetch, settings, updateSettings} = props;

  /* === Context === */

  const {api, store} = useAppContext();
  const modals = useModals();
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();
  const isPartnerAdmin = me.role === UserType.PartnerAdmin;

  /* === State === */

  const {locations} = useAppSelector(state => ({
    locations: state.locations.locations
  }));
  const chargingStationLocations = locations?.filter(loc => loc.chargingStation !== undefined);

  const [state, setState] = useState<PropertiesState>({
    available: true,
    paymentTypes: [],
    brightness: 100,
    currentLimit: 32,
    behindVoltageTransformer: false,
    floor: '',
    publiclyVisible: true,
    cableLocked: false,
    offlineChargingAllowed: false,
    failSafeCurrent: 6,
    restrictedAccess: false,
    connectors: new ConnectorsState({})
  });
  const [actionsOpened, setActionsOpened] = useState(false);

  /* === Data loading === */

  const cardLocation = useCardLocation(settings);
  const chargingStationLocation = useCardChargingStation(settings);
  const [chargingStation, refreshChargingStation] = useChargingStationForLocation(fetch, chargingStationLocation);
  const locationId = chargingStationLocation && chargingStationLocation.id;

  const chargingStationGroup = useCardChargingStationGroup(settings);
  const chargingStationGroupUuid = chargingStationGroup && chargingStationGroup.uuid;

  const parentId = useCardChargingStationGroupId(settings);

  const [location, refreshLocation] = useLocation(fetch, locationId);
  const getStationWriteAccess = (locations: ILocationSummary[], location: ILocation | undefined) => {
    if (!locations && !location) return false;
    return locations.find(el => el.chargingStation?.serialNumber === location?.chargingStation?.serialNumber)
      ?.writeAccess;
  };
  const readOnly = !getStationWriteAccess(chargingStationLocations, location) && !isServiceDesk && !isPartnerAdmin;
  const [activationCode, refreshActivationCode] = useLocationActivationCode(fetch, locationId);
  const [deviceActivationCode, refreshDeviceActivationCode] = useDeviceActivationCode(
    fetch,
    location && location.serialNumber
  );

  const operator = chargingStation?.data?.operator;
  const [stationDetails, refreshStationDetails] = useLoader<StationDetails>(() => {
    if (!location || !chargingStation || location.id !== chargingStation?.data.serviceLocation?.id) {
      return Promise.resolve(undefined);
    }

    const controllers = chargingStation.getControllers();
    const loadManagementSettings = Promise.all(
      controllers.map(c => api.loadManagement.getLoadManagementSettings(location.id, c.smartDevice!.id))
    );
    const highLevelConfigPromise = api.getHighLevelConfiguration(location.id).catch(e => {
      const result: IHighLevelConfiguration = {
        locationId: location.id,
        underConstruction: false,
        phaseType: PhaseType.Star,
        nilm: false,
        measurements: []
      };
      return result;
    });
    const chargingHubId = location.parentId === undefined ? location.id : location.parentId;
    const chargingHubPromise = api.locations.get(chargingHubId);

    return Promise.all([loadManagementSettings, highLevelConfigPromise, chargingHubPromise]).then(
      ([settings, highLevelConfiguration, chargingHub]) => {
        const chargingParent = location.parentId === undefined ? undefined : chargingHub;
        const result: {[key: string]: LoadManagementSettings} = {};
        settings.forEach((setting, i) => (result[controllers[i].smartDevice!.id] = setting));
        return {
          chargingStation,
          loadManagementSettings: result,
          controllers,
          highLevelConfiguration,
          chargingHub,
          chargingParent,
          location
        };
      }
    );
  }, [chargingStation, location?.id]);

  const [, status] = useLiveChargerPower(chargingStationGroupUuid, chargingStation);

  const updateState = useCallback((updates: Partial<PropertiesState>) => {
    setState(state => ({...state, ...updates}));
  }, []);
  const updateStateUsing = useCallback((updater: (state: PropertiesState) => Partial<PropertiesState>) => {
    setState(state => ({...state, ...updater(state)}));
  }, []);
  const updateConnectorState = useCallback(
    (deviceId: string, updates: Partial<ConnectorState>) => {
      updateStateUsing(state => ({
        connectors: state.connectors.updatedWith(deviceId, updates)
      }));
    },
    [updateStateUsing]
  );

  useEffect(() => {
    if (!stationDetails) return;

    const phaseAssignments = getPhaseAssignments(stationDetails.highLevelConfiguration);
    updateStateUsing(state => {
      let connectors = state.connectors;
      stationDetails.controllers.forEach(controller => {
        connectors = connectors.updatedWith(controller.smartDevice!.id, {
          phaseAssignment: phaseAssignments[controller.position || 0]
        });
      });
      return {connectors};
    });
  }, [stationDetails, updateStateUsing]);

  const liveStatusRequest = useMemo(() => {
    if (!chargingStation || !chargingStationGroupUuid) return;

    return createCarChargingStatusRequest(chargingStationGroupUuid, chargingStation);
  }, [chargingStation, chargingStationGroupUuid]);

  const carChargingStatus = useLiveChargingStatus(liveStatusRequest);

  useEffect(() => {
    if (!liveStatusRequest) return;

    updateStateUsing(state => {
      let connectors = state.connectors;

      liveStatusRequest.channels.forEach((side, index) => {
        const status = carChargingStatus[index];
        if (!status || status.percentageLimit === undefined) return;
        const deviceId = side.deviceId;

        connectors = connectors.updatedWith(deviceId, {
          chargingSpeed: status.percentageLimit,
          charging: isCharging(status, chargingStation == undefined ? false : chargingStation.isUltra(), false),
          paused: isChargingPaused(status.chargingState)
        });
      });

      const status = carChargingStatus[0];
      const result: Partial<PropertiesState> = {connectors};
      if (status && status.available !== undefined) {
        result.available = status.available;
      }

      return result;
    });
  }, [chargingStation, carChargingStatus, liveStatusRequest, updateStateUsing]);

  /* === Logic === */

  useEffect(() => {
    if (chargingStation) {
      const ledController = chargingStation.getLedController();
      const brightness = ledController?.smartDevice && getLedControllerBrightness(ledController.smartDevice);
      const connectors = getStationSmartDevicePropertyValues(chargingStation.data);

      updateState({
        available: chargingStation.data.available,
        paymentTypes: chargingStation.data.paymentTypes,
        tariffs: chargingStation.data.tariffs,
        csms: chargingStation.data.csms,
        cableLocked: chargingStation.data.cableLocked,
        floor: chargingStation.data.level,
        publiclyVisible: chargingStation.data.visible,
        restrictedAccess: chargingStation.data.restrictedAccess,
        offlineChargingAllowed: chargingStation.data.offlineCharging?.enabled || false,
        failSafeCurrent: chargingStation.data.offlineCharging?.failSafe || 6,
        brightness,
        connectors: new ConnectorsState(connectors),
        behindVoltageTransformer: chargingStation.data.behindVoltageTransformer || false
      });
    } else {
      updateState({
        available: true,
        paymentTypes: []
      });
    }
  }, [chargingStation, updateState]);

  const handleClickedEditPaymentTypes = useCallback(
    async (state: PropertiesState) => {
      if (!stationDetails) return;

      const chargingStation = stationDetails.chargingStation;
      const serialNumber = chargingStation.data.serialNumber;
      const settings: ChargingStationPaymentTypeSettings = {
        visible: state.publiclyVisible,
        restrictedAccess: state.restrictedAccess,
        paymentTypes: state.paymentTypes,
        csms: state.csms,
        tariffs: state.tariffs,
        thirdParty: chargingStation.isThirdParty()
      };

      const savePaymentTypeSettings = async (updatedSettings: ChargingStationPaymentTypeSettings) => {
        await api.chargingStations.update(parentId, serialNumber, updatedSettings);
        return refreshLocation(true);
      };

      const updatedSettings = await modals.show<ChargingStationPaymentTypeSettings | undefined>(props => {
        return (
          <EditPaymentTypesModal
            stationName={chargingStation.name}
            parentId={parentId}
            address={stationDetails.chargingHub.address}
            settings={settings}
            operator={operator}
            save={savePaymentTypeSettings}
            chargingSettings={location?.chargingSettings}
            hasWhitelistedTokens={chargingStation.data.hasWhitelistedTokens}
            hasSplitBillingAgreements={chargingStation.data.hasSplitBillingAgreements}
            {...props}
          />
        );
      });
      if (updatedSettings === undefined) return;

      try {
        updateState({
          paymentTypes: updatedSettings.paymentTypes,
          csms: updatedSettings.csms,
          publiclyVisible: updatedSettings.visible
        });
        refreshChargingStation();
        NotificationManager.success(T('chargingStationConfiguration.paymentMethod.updated'));
      } catch {
        NotificationManager.error(T('chargingStationConfiguration.propertyUpdateFailed'));
      }
    },
    [modals, api, parentId, refreshLocation, stationDetails, location, operator, updateState, refreshChargingStation]
  );

  const handleReleaseConnector = async (deviceId: string) => {
    if (!locationId) return;

    return api
      .runSmartDeviceAction(locationId, deviceId, 'releaseConnector', [])
      .then(() => NotificationManager.success(T('chargingStationConfiguration.connectorReleased')));
  };

  const properties = useMemo<PropertyEntry<PropertiesState>[]>(
    () =>
      stationDetails
        ? getProperties(
            api,
            store,
            modals,
            stationDetails,
            readOnly,
            isServiceDesk,
            activationCode,
            deviceActivationCode,
            {
              updateState,
              updateConnectorState,
              refreshActivationCode,
              refreshLocation,
              refreshDeviceActivationCode,
              refreshChargingStation,
              refreshStationDetails,
              handleClickedEditPaymentTypes
            }
          )
        : None,
    [
      api,
      updateState,
      updateConnectorState,
      stationDetails,
      store,
      modals,
      refreshActivationCode,
      refreshLocation,
      refreshDeviceActivationCode,
      refreshChargingStation,
      refreshStationDetails,
      readOnly,
      handleClickedEditPaymentTypes,
      activationCode,
      isServiceDesk,
      deviceActivationCode
    ]
  );

  const handleClickedReload = () => {
    refreshLocation(true);
    refreshChargingStation(true);
    refreshActivationCode();
    refreshDeviceActivationCode();
    refreshStationDetails(true);
  };

  const handleClickedDelete = async () => {
    if (!cardLocation) return;

    const confirmed = await modals.show(props => (
      <ConfirmationPromiseModal
        title={T('chargingStationConfiguration.deleteStation.title')}
        message={T('chargingStationConfiguration.deleteStation.message')}
        acceptLabel={T('chargingStationConfiguration.deleteStation.confirm')}
        rejectLabel={T('generic.action.cancel')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      if (chargingStationGroup && chargingStation) {
        await api.deleteChargingStationInstallation(chargingStationGroup.id, chargingStation.serviceLocationId!);
      } else {
        await api.deleteLocation(cardLocation.id);
      }
      NotificationManager.success(T('chargingStationConfiguration.deleteStation.success'));
    } catch {
      NotificationManager.error(T('chargingStationConfiguration.deleteStation.error'));
    }
  };

  const handleClickedComplete = () => {
    if (!location) return Promise.resolve();

    return api
      .completeLoads(location.id)
      .then(() => NotificationManager.success('Configuration completed'))
      .catch(() => NotificationManager.error('Could not complete configuration'));
  };

  const handleClickedRestore = async () => {
    if (!cardLocation) return;

    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locations.restoreLocation.title', {
          name: cardLocation.name || ''
        })}
        message={T('locations.restoreLocation.message')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.restoreLocation(cardLocation.id);
      refreshLocation(true);
      refreshChargingStation(true);
      NotificationManager.success(T('locations.restoreLocation.restored'));
    } catch {
      NotificationManager.error(T('locations.restoreLocation.failed'));
    }
  };

  const obsoleteTimestamp = location && location.obsoleteTimestamp;

  const carChargers = (chargingStation && chargingStation.getControllers()) || [];
  const carChargersWithRelease = carChargers.filter(
    charger =>
      charger.smartDevice &&
      charger.smartDevice.type &&
      charger.smartDevice.type.actions.some(action => action.name === 'releaseConnector')
  );

  const actions: CustomActions = actionState => (
    <CardActions>
      <Reload onReload={handleClickedReload} />
      {actionState.ready && status !== undefined && <OnlineStatusIndicator status={status} />}
      <Spring />

      {!readOnly && (
        <>
          {stationDetails && !state.paymentTypes.includes(ChargingStationPaymentType.RFID) ? (
            <RsButton color="primary" onClick={() => handleClickedEditPaymentTypes(state)}>
              {T('locationConfiguration.actions.setPublic')}
            </RsButton>
          ) : null}
          <Dropdown isOpen={actionsOpened} toggle={() => setActionsOpened(!actionsOpened)} color="secondary">
            <DropdownToggle caret className={buttonColors.secondary}>
              {T('locationConfiguration.actions')}
            </DropdownToggle>
            <DropdownMenu>
              {isServiceDesk && actionState.ready && (
                <DropdownItem onClick={handleClickedComplete}>
                  {T('liveElectricityValues.configuration.commit')}
                </DropdownItem>
              )}
              {obsoleteTimestamp === undefined && (
                <DropdownItem onClick={handleClickedDelete}>
                  {T('chargingStationConfiguration.deleteStation')}
                </DropdownItem>
              )}
              {isServiceDesk && obsoleteTimestamp !== undefined && (
                <DropdownItem onClick={handleClickedRestore}>{T('locations.restoreLocation')}</DropdownItem>
              )}
              {actionState.ready &&
                carChargersWithRelease.map((charger, index) => (
                  <DropdownItem key={index} onClick={() => handleReleaseConnector(charger.smartDevice!.id)}>
                    {carChargers.length <= 1
                      ? T('chargingStationConfiguration.releaseConnector')
                      : T('chargingStationConfiguration.releaseConnectorX', {
                          index: (charger.position || 1).toString()
                        })}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </>
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (location !== undefined && location?.chargingStation === undefined) {
    error = T('chargingStationConfiguration.notInstalled');
  } else if (cardLocation === undefined) {
    error = T('chargingStationConfiguration.errors.cardLocationNotFoundOrUnavailable');
  } else {
    error = undefined;
  }

  const connectorNumbers = chargingStation?.connectorNumbers || [1];

  return (
    <CardView actions={actions} error={error} {...cardViewProps(props)}>
      <Nav tabs>
        <div
          style={{
            display: 'flex'
          }}
        >
          <NavItem>
            <NavLink
              style={{display: 'inline-flex'}}
              className="mb-2"
              active={tab === Tab.GENERAL}
              onClick={() => setTab(Tab.GENERAL)}
            >
              {T('chargingStationConfiguration.tab.general')}
            </NavLink>
            <NavLink
              style={{display: 'inline-flex'}}
              className="mb-2"
              active={tab === Tab.CONFIGURATION}
              onClick={() => setTab(Tab.CONFIGURATION)}
            >
              {T('chargingStationConfiguration.tab.configuration')}
            </NavLink>
            {connectorNumbers.map(connector => (
              <NavLink
                style={{display: 'inline-flex'}}
                className="mb-2"
                active={tab === Tab.CONNECTORX + connector}
                onClick={() => setTab(Tab.CONNECTORX + connector)}
              >
                {connectorNumbers.length === 1
                  ? T('chargingStationConfiguration.tab.connector')
                  : T('chargingStationConfiguration.tab.connectorX', {connector: connector.toString()})}
              </NavLink>
            ))}
            <NavLink
              style={{display: 'inline-flex'}}
              className="mb-2"
              active={tab === Tab.SESSION_ACTIVATION}
              onClick={() => setTab(Tab.SESSION_ACTIVATION)}
            >
              {T('chargingStationConfiguration.tab.sessionActivation')}
            </NavLink>
          </NavItem>
        </div>
      </Nav>
      <div style={{height: '88%'}}>
        <SettingsTable
          filter=""
          properties={properties.filter(property => (property.tab || Tab.GENERAL) === tab)}
          state={state}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
        />
      </div>
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: ChargingStationConfigurationSettings = {
  table: {
    pageSize: 20,
    sortColumn: 'order',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'order', visible: false},
      {name: 'key', visible: true}
    ]
  }
};

const CARD: ICardType<ChargingStationConfigurationSettings> = {
  type: CardTypeKey.ChargingStationConfiguration,
  title: 'chargingStationConfiguration.title',
  description: 'chargingStationConfiguration.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.EV],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.RequiresChargingStation,
  upgradeSettings: migrateTableSettings('table', DEFAULT_CARD_SETTINGS.table),
  cardClass: ChargingStationConfiguration
};
export default CARD;
