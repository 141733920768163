import {AppFeature, hasFeature} from '../utils/AppParameters';
import {T} from '../utils/Internationalization';

import {ChargingStationModel} from './ChargingStationModel';
import {EVChargingStationControllerState, CarChargerIECStatus} from './ChargingStatus';
import {DeviceType, isChargingController} from './DeviceType';
import {ILoadUpdateChannel} from './Load';
import {Phase} from './Phase';
import {SmartDeviceConnectionStatus, ISmartDevice, IBaseSmartDevice, SmartDeviceTypeCategory} from './SmartDevice';
import {IUser} from './User';

export function getChargingStationControllers(station: IChargingStation): ChargingStationModule[] {
  return station.modules.filter(module => module.type === DeviceType.ACChargingController && module.smartDevice);
}

export interface ChargingStationModule {
  id: number;
  active: boolean;
  type: DeviceType;
  serialNumber: string;
  position?: number;
  label?: string;
  firmwareVersion: string;
  isReplaceable?: boolean;
  replacementCandidateSerialNumbers?: string[];
  smartDevice?: IStationSmartDevice;
}

export interface IStationSmartDevice extends IBaseSmartDevice {
  serviceLocation: number;
  carCharger?: ChargingStationControllerDevice;
}

export interface UltraStatus {
  emergency: boolean;
  timeout: boolean;
  stoppedByCloud: boolean;
  stateOfCharge?: number; // percentage
  evseErrorCodeDetails?: UltraErrorDetails;
}

export interface UltraErrorDetails {
  isCableTemperatureTooHigh: boolean;
  isInverterTemperatureTooHigh: boolean;
  isCableTemperatureWarning: boolean;
  isInverterTemperatureWarning: boolean;
  isIMDTriggered: boolean;
  isEVErrorOccurred: boolean;
  isInverterOffline: boolean;
  isCommunicationWithAkkaBoardMissing: boolean;
  isOvervoltageDeviceNeedsReplacement: boolean;
}

export interface ChargingStationControllerDevice {
  channelIndices: (number | null)[];
  connectionStatus: SmartDeviceConnectionStatus;
  iecStatus?: CarChargerIECStatus;
  chargingStateUpdateChannel: ILoadUpdateChannel;
  powerUpdateChannel?: ILoadUpdateChannel;
  chargingMode: ChargingMode;
  ultraStatus?: UltraStatus;
}

export const enum ChargingMode {
  Normal = 'NORMAL',
  Smart = 'SMART',
  Stop = 'PAUSED'
}

export const enum SmartChargingMode {
  Standard = 'STANDARD',
  Smart = 'SMART',
  Solar = 'SOLAR'
}

export function getActionForChargingMode(mode: ChargingMode): string {
  switch (mode) {
    case ChargingMode.Normal:
      return 'normalChargingMode';
    case ChargingMode.Smart:
      return 'smartChargingMode';
    case ChargingMode.Stop:
      return 'pauseCharging';
    default:
      return 'normalChargingMode';
  }
}

export const enum ChargingStationPaymentType {
  RFID = 'RFID',
  App = 'APP',
  Free = 'FREE_OF_CHARGE',
  Whitelist = 'WHITELIST',
  SplitBilling = 'SPLITBILLING',
  PaymentTerminal = 'PAYMENT_TERMINAL',
  Any = 'any' // Client side only. Reason: SelectItem can't contain empty strings as default value.
}

export const enum ChargingStationPaymentTypeFilter {
  Free = 'FREE_OF_CHARGE',
  RFID = 'RFID',
  App = 'APP',

  RFIDSmappee = 'RFID_SMAPPEE',
  RFIDExternal = 'RFID_EXTERNAL',
  RFIDWhitelist = 'RFID_WHITELIST',
  RFIDSplitBilling = 'RFID_SPLITBILLING'
}

export function summaryChargingStationPublicCharging({paymentTypes}: {paymentTypes: ChargingStationPaymentType[]}) {
  const summary: string[] = [];
  if (paymentTypes.includes(ChargingStationPaymentType.Free)) {
    summary.push(T('paymentMethod.free'));
  }
  if (paymentTypes.includes(ChargingStationPaymentType.RFID)) {
    summary.push(T('paymentMethod.publicCharging'));
  } else if (paymentTypes.includes(ChargingStationPaymentType.App)) {
    summary.push(T('paymentMethod.app'));
  }
  if (paymentTypes.includes(ChargingStationPaymentType.Whitelist)) {
    summary.push(T('paymentMethod.whitelist'));
  }
  if (paymentTypes.includes(ChargingStationPaymentType.SplitBilling)) {
    summary.push(T('paymentMethod.splitBilling'));
  }
  return summary.join(', ');
}

export function translateChargingStationPaymentType(type: ChargingStationPaymentType, splitBilling: boolean) {
  if (splitBilling) return T('paymentMethod.splitBilling');

  switch (type) {
    case ChargingStationPaymentType.RFID:
      return T('paymentMethod.rfid');
    case ChargingStationPaymentType.App:
      return T('paymentMethod.app');
    case ChargingStationPaymentType.Free:
      return T('paymentMethod.free');
    case ChargingStationPaymentType.Whitelist:
      return T('paymentMethod.whitelist');
    case ChargingStationPaymentType.SplitBilling:
      return T('paymentMethod.splitBilling');
    case ChargingStationPaymentType.PaymentTerminal:
      return T('paymentMethod.paymentTerminal');
    default:
      return '?';
  }
}

interface ChargingStationPart {
  position: number;
  serialNumber: string;
  serviceLocation: {
    id: number;
    uuid: string;
  };
  trackingSerialNumber?: string;
}

export interface IChargingStation {
  articleCode: string;
  model: ChargingStationModel;
  name: string;
  serialNumber: string;
  trackingSerialNumber?: string; // can be absent if the charging station is not installed
  trackingSerialNumbers?: string[]; // multiple in case of a dual ultra station
  parts?: ChargingStationPart[];
  active: boolean;
  available: boolean;
  behindVoltageTransformer?: boolean; // always present since cloud 1.16.0
  level?: string;
  cableLocked: boolean;
  restrictedAccess?: boolean;
  visible: boolean;
  connectionType?: 'CASEB' | 'CASEC'; // CASEB = socket, CASEC = cable
  serviceLocation?: {
    id: number;
    uuid: string;
    latitude?: number;
    longitude?: number;
    name: string;
  };
  paymentTypes: ChargingStationPaymentType[];
  features: ChargingStationFeature[];
  tariffs?: {
    fixed?: number;
    energy?: number;
    time?: number;
    currency?: string;
  };
  csms?: {
    // old properties
    host?: string;
    port?: number;
    // new properties
    url?: string;
    deviceActivated?: boolean;
  };
  maximumCapacity: number;
  operator?: ChargingStationOperator;
  modules: ChargingStationModule[];
  hasWhitelistedTokens?: boolean;
  hasSplitBillingAgreements?: boolean;
  integrations?: {
    advenirUserId?: string;
  };
  offlineCharging?: {
    enabled: boolean;
    failSafe: number;
  };
  version: string;
  commissioning?: {
    state: CommissioningState;
    initiator: IUser;
    completedTimestamp?: number;
  };
}

export const enum ChargingStationFeature {
  LockCable = 'LOCK_CABLE',
  MaxCurrent = 'MAX_CURRENT',
  MaxPower = 'MAX_POWER',
  SmartCharging = 'SMART_CHARGING',
  PauseCharging = 'PAUSE_CHARGING',
  StopCharging = 'STOP_CHARGING',
  PhaseRotation = 'PHASE_ROTATION',
  //Grid = 'GRID', // deprecated
  //VoltageTransformer = 'VOLTAGE_TRANSFORMER', // deprecated
  //OfflineCharging = 'OFFLINE_CHARGING', // deprecated
  OfflineChargingSettings = 'OFFLINE_CHARGING_SETTINGS',
  Automations = 'AUTOMATIONS',
  PubliclyVisible = 'PUBLICLY_VISIBLE',
  ScanAndCharge = 'SCAN_AND_CHARGE',
  ApplianceDetail = 'APPLIANCE_DETAIL',
  DeleteChargingStation = 'DELETE_CHARGING_STATION',
  Commissionable = 'COMMISSIONABLE'
}

export const enum CommissioningState {
  Invalid = 'INVALID',
  Initiated = 'INITIATED',
  Completed = 'COMPLETED'
}

export interface ChargingStationOperator {
  id: number;
  name: string;
  phoneNumber: string;
  countryCode?: string;
  currency?: {
    code: string;
    symbol: string;
  };
  supportsSmappee?: boolean;
  supportsRoaming?: boolean;
}

export class ChargingStation {
  data: IChargingStation;
  forcedState?: {
    state: EVChargingStationControllerState[];
    power?: number;
  };
  forceOnline: boolean;

  serviceLocationId?: number;

  constructor(data: IChargingStation) {
    this.data = data;
    this.serviceLocationId = data.serviceLocation && data.serviceLocation.id;
    this.forceOnline = false;
  }

  get serialNumber() {
    return this.data.serialNumber;
  }

  get name() {
    return this.data.name;
  }

  get connectorNumbers(): number[] {
    return this.data.modules.filter(module => isChargingController(module)).map(module => module.position || 1);
  }

  isLite() {
    return (
      this.data.model === ChargingStationModel.WallLiteSocket || this.data.model === ChargingStationModel.OneLiteSocket
    );
  }

  isThirdParty() {
    return this.data.articleCode.startsWith('THIRDPARTY');
  }

  hasFeature(feature: ChargingStationFeature) {
    return this.data.features.includes(feature);
  }

  hasDisplayImages() {
    const moduleTypes = this.data.modules.map(module => module.type) || [];
    return (
      moduleTypes.includes(DeviceType.DCCarChargeRFIDTripleDisplay) ||
      moduleTypes.includes(DeviceType.DCCarChargeTripleDisplay)
    );
  }

  getChannelIndexes(position: number): number[] | undefined {
    const module = this.data.modules.find(module => isChargingController(module) && module.position === position);
    return (
      module &&
      module.smartDevice &&
      (module.smartDevice.carCharger!.channelIndices.filter(i => i !== null) as number[])
    );
  }

  getChargingStatusChannel(position: number) {
    const module = this.data.modules.find(module => isChargingController(module) && module.position === position);
    return module && module.smartDevice && module.smartDevice.carCharger!.chargingStateUpdateChannel;
  }

  getControllers(): ChargingStationModule[] {
    return this.data.modules.filter(module => isChargingController(module) && module.smartDevice);
  }

  getController(position: number): ChargingStationModule | undefined {
    return this.data.modules.find(module => isChargingController(module) && module.position === position);
  }

  getControllersSorted(): ChargingStationModule[] {
    return this.data.modules
      .filter(module => isChargingController(module) && module.smartDevice)
      .sort((a, b) => (a.position || 0) - (b.position || 0));
  }

  getLedController(): ChargingStationModule | undefined {
    return this.data.modules.find(module => module.smartDevice?.type.category === SmartDeviceTypeCategory.Led);
  }

  findChargingStationSmartDevice(serialNumber: string, smartDevices: ISmartDevice[]) {
    return smartDevices.find(device => device.serialNumber === serialNumber);
  }

  findCarChargerSmartDevice(position: number, smartDevices: ISmartDevice[]) {
    const module = this.data.modules.find(
      module => isChargingController(module) && (module.position || 0) === position
    );
    const smartDeviceId = module && module.smartDevice && module.smartDevice.id;
    if (!smartDeviceId) return;

    return smartDevices.find(device => device.id === smartDeviceId);
  }

  getCarCharger(position: number): ChargingStationControllerDevice | undefined {
    const module = this.data.modules.find(module => isChargingController(module) && module.position === position);
    return module && module.smartDevice && module.smartDevice.carCharger;
  }

  getAnyCarCharger(): ChargingStationControllerDevice | undefined {
    const module = this.data.modules.find(module => isChargingController(module));
    return module && module.smartDevice && module.smartDevice.carCharger;
  }

  isUltra() {
    return this.data.articleCode.startsWith('EVBU') || this.data.articleCode.startsWith('EVU');
  }

  isAwaitingCommissioning() {
    return (
      this.hasFeature(ChargingStationFeature.Commissionable) &&
      this.data.commissioning !== undefined &&
      this.data.commissioning.state === CommissioningState.Initiated
    );
  }
  isCommissioned() {
    return (
      this.hasFeature(ChargingStationFeature.Commissionable) &&
      this.data.commissioning !== undefined &&
      this.data.commissioning.state === CommissioningState.Completed
    );
  }
}

export interface IChargingStationUpdate {
  name: string;
  available: boolean;
  paymentTypes: ChargingStationPaymentType[];
  tariffs?: {
    energy?: number;
    fixed?: number;
    time?: number;
    currency?: string;
  };
  csms?: {
    url?: string;

    // removed
    host?: string;
    port?: number;
  };
  behindVoltageTransformer?: boolean;
  integrations?: {
    advenirUserId?: string;
  };
  operator?: {
    id: number;
  };
  level?: string;
  visible?: boolean;
  cableLocked?: boolean;
  offlineCharging?: {
    enabled: boolean;
    failSafe: number;
  };
  commissioning?: {
    completedTimestamp?: number;
  };
}

export interface CreateChargingStationRequest {
  serialNumber: string;
  articleCode: string;
  modules: {
    type: DeviceType;
    serialNumber: string;
    position?: number;
    label?: string;
  }[];
}

export interface CreateChargingStationResult {
  errors?: {serialNumber?: string; error: string}[];
  serialNumber?: string;
}

export interface IChargingSession {
  id: number;
  uuid: string;
  station?: IChargingStation;
  locationName?: string;
  controller?: {
    serialNumber: string;
    position: number;
    label: string;
  };
  from: number;
  to?: number;
  rfid?: string;
  userName?: string;
  paymentType: ChargingStationPaymentType;
  cost?: number;
  driverCost?: number;
  operatorCost?: number;
  hostCost?: number;
  discount?: number;
  currency: string;
  startMeterReading: number;
  stopMeterReading: number;
  energy: number;
  splitBillingAgreement?: number | null;
  splitBillingRefund?: number | null;
  address?: {
    streetAndNumber: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
  user?: {
    id: number;
    emailAddress: string;
  };
  csmsTransactionId?: number;
  csmsUrl?: string;
  emspFee?: number;
  cpoUplift?: number;
  hostTariffComponents?: TariffComponent[];
}

export interface TariffComponent {
  description: string;
  minimum?: number;
  maximum?: number;
  cost: number;
  unit: string;
  details?: TariffComponentDetail[];
}

export interface TariffComponentDetail {
  prefix: string;
  suffix: string;
  unit: string;
  cost: number;
}

export interface IChargingStationConfiguration {
  controllers: IChargingStationControllerConfiguration[];
}

export interface IChargingStationControllerConfiguration {
  serialNumber: string;
  name: string;
  maxCurrent: number;
  phases?: Phase[]; // if not provided, [L1, L2, L3]
}

export interface IChargingStationCreateModuleRequest {
  type: DeviceType;
  serialNumber: string;
  position?: number;
  label?: string;
}

export interface ChargingStationSummary {
  serviceLocation: ChargingStationLocation;
  parent?: ChargingStationLocation;
  lastInterval?: number;
  connectionType?: ConnectionType;
  available?: boolean;
  chargers: CarChargerSummary[];
}

export const enum ConnectionType {
  Socket = 'CASEB',
  Cable = 'CASEC'
}

export interface ChargingStationLocation {
  id: number;
  name: string;
  latitude?: number;
  longitude?: number;
}

export interface CarChargerSummary {
  id: number;
  serialNumber?: string;
  connectionStatus?: string;
  iecStatus?: CarChargerIECStatus;
  typeName: string;
  chargingMode?: ChargingMode;
  position?: number;
}

export const enum ChargingStationStatus {
  Charging,
  Available,
  Unavailable,
  Disabled
}

export function getChargingStationStatus(chargingStation: ChargingStationSummary): ChargingStationStatus {
  if (chargingStation.chargers.some(x => x.chargingMode === ChargingMode.Stop) || !chargingStation.available) {
    return ChargingStationStatus.Disabled;
  } else if (chargingStation.chargers.some(x => x.iecStatus === 'E' || x.iecStatus === 'F')) {
    return ChargingStationStatus.Unavailable;
  } else if (
    chargingStation.chargers.some(
      x => x.iecStatus === 'C1' || x.iecStatus === 'C2' || x.iecStatus === 'B1' || x.iecStatus === 'B2'
    )
  ) {
    return ChargingStationStatus.Charging;
  } else return ChargingStationStatus.Available;
}

export const enum ChargingStationType {
  EVBox = 'evbox',
  PowerdaleNextenderAdvance = 'powerdale-nextender-advance',
  AlfenModbusTCP = 'alfen-modbus-tcp',
  KebaUDP = 'keba-udp',
  Greenflux = 'greenflux',
  Smappee = 'acchargingcontroller'
}

export function getChargingStationManufacturer(type: ChargingStationType) {
  switch (type) {
    case ChargingStationType.EVBox:
      return 'EVBox';
    case ChargingStationType.PowerdaleNextenderAdvance:
      return 'Powerdale';
    case ChargingStationType.AlfenModbusTCP:
      return 'Alfen';
    case ChargingStationType.KebaUDP:
      return 'Keba';
    case ChargingStationType.Greenflux:
      return 'Greenflux';
    case ChargingStationType.Smappee:
      return 'Smappee';
    default:
      return '?';
  }
}
