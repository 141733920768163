import React, {useState, useMemo, useCallback, useRef} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button as RsButton, Input, SingleActionModal} from '../../components/bootstrap';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {
  migrateTableSettings,
  ServerPagedTable,
  SortOrder,
  sortTableItems,
  useInfiniteTable
} from '../../components/Table';
import {useModals} from '../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import RemoveModal from '../../modals/RemoveModal';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {ITableField} from '../../models/Table';
import {hasPartnerAdminFunctionality, UserType} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {useDebounce} from '../../utils/Hooks';
import {plural, singular, T} from '../../utils/Internationalization';
import {generateRandomString} from '../../utils/StringUtils';
import {testingClasses} from '../../utils/TestingClasses';
import {validateEmail} from '../../utils/Validation';
import {CardCategory, ICardType, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser} from '../CardUtils';
import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';
import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';

import {getTableColumns, rowKey} from './Columns';
import {APIUserModal} from './modals/APIUserModal';
import EditOrganization from './modals/EditOrganization';

import EditSearchFields from './modals/EditSearchFields';

type IOrganizationsSettings = ICardSettingsWithTable;

function CreateApiUserModal(props: IPromiseModalProps<void> & {organization: IOrganization}) {
  const [emailAddress, setEmailAddress] = React.useState<string>('');
  const [isOpen, resolve] = usePromiseModal(props);
  const [error, setError] = useState<string>();
  const modals = useModals();
  const {organization} = props;
  const {api} = useAppContext();

  async function createAPIUser() {
    const password = generateRandomString(10);
    // TODO remove this line
    const deprecated_userName = `${organization.name.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '_')}_API`;

    const clientId = deprecated_userName;
    const clientSecret = generateRandomString(10);

    try {
      const user = await api.findOrCreateUser({
        firstName: deprecated_userName,
        ...(hasFeature(AppFeature.SocialLogin)
          ? {}
          : {
              userName: deprecated_userName
            }),
        lastName: '',
        userName: deprecated_userName,
        emailAddress,
        organization: organization.id,
        password,
        type: UserType.PartnerAPI,
        apiAccess: {
          appName: deprecated_userName,
          clientId,
          clientSecret
        }
      });

      if (!user) return;

      modals.show<void>(props => (
        <APIUserModal user={user} organization={organization} password={undefined} {...props} />
      ));

      return resolve();
    } catch {
      NotificationManager.error(T('viewAPIUser.failed'));
      setError(T('viewAPIUser.failed'));
    }
  }

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve()}
      title={T('apiUser.create')}
      action={createAPIUser}
      actionText="Save"
      size="md"
      error={error}
    >
      <TextInputGroup
        validate={validateEmail}
        name="emailAddress"
        label={T('organizationUsers.field.emailAddress')}
        value={emailAddress}
        onChange={setEmailAddress}
      />
    </SingleActionModal>
  );
}

const Organizations = (props: ICardProps<IOrganizationsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const modals = useModals();
  const {api} = useAppContext();
  const me = useUser();
  const [query, setQuery] = useState('');
  const debouncedSearchTerm = useDebounce(query, 500);
  const actualSearchTerm = me.isServiceDesk() ? debouncedSearchTerm : query;
  const refreshOrganizations = useRef<(force?: boolean) => void>();

  const handleQueryChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 0 && e.currentTarget.value !== '*') {
      setQuery(e.currentTarget.value);
    } else {
      setQuery('');
    }
  };

  const loadPage = useCallback(
    (page: number, pageSize: number, sortColumn?: ITableField<IOrganization>, sortOrder?: SortOrder) => {
      return fetch(
        'organizations',
        api => {
          if (me.isServiceDesk()) {
            return api.organizations.getOrgsBySearchTerm(actualSearchTerm, page + 1, pageSize);
          } else {
            return api.getUserOrganizations(me.userId).then(orgs => {
              const rawItems = orgs.filter(org =>
                org.name.toLocaleUpperCase().includes(actualSearchTerm.toLocaleUpperCase())
              );
              return sortTableItems(rawItems, sortColumn, sortOrder);
            });
          }
        },
        plural('organization')
      );
    },
    [me, actualSearchTerm, fetch]
  );

  const handleCreateOrganization = async () => {
    const saved = await modals.show(props => <EditOrganization organization={null} {...props} />);
    if (saved) refreshOrganizations.current?.();
  };

  const columns = useMemo(() => {
    const handleClickedEdit = async (organization: IOrganization) => {
      const organizationDetails = await fetch(
        'organization',
        () => api.organizations.getById(organization.id, true),
        singular('organization')
      );
      const saved = await modals.show(props => <EditOrganization organization={organizationDetails} {...props} />);
      if (saved) refreshOrganizations.current?.();
    };

    const handleClickedRemove = (organization: IOrganization) => {
      modals
        .show(props => (
          <RemoveModal
            title={T('organizations.remove.title')}
            message={T('organizations.remove.message', {name: organization.name})}
            execute={api =>
              api.organizations.delete(organization.id).then(({success}) => {
                if (!success) throw Error();
              })
            }
            successMessage={T('organizations.remove.success')}
            failureMessage={T('organizations.remove.failed')}
            {...props}
          />
        ))
        .then(success => success && refreshOrganizations.current?.());
    };

    const handleClickedCreateAPIUser = async (organization: IOrganization) => {
      // model for email address
      modals.show<void>(props => <CreateApiUserModal organization={organization} {...props} />);
    };

    const handleClickedManageFields = async (organization: IOrganization) => {
      const fields = await api.getOrganizationSearchFields(organization.id);
      if (fields === undefined) return;

      fields.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      modals.show<void>(props => (
        <EditSearchFields
          organizationId={organization.id}
          multilingual={organization.multilingual}
          fields={fields}
          {...props}
        />
      ));
    };

    return getTableColumns(me.isServiceDesk(), {
      onClickedEdit: handleClickedEdit,
      onClickedManageFields: handleClickedManageFields,
      onClickedCreateAPIUser: handleClickedCreateAPIUser,
      onClickedRemove: handleClickedRemove
    });
  }, [me, fetch, modals, api]);

  const [page, items, onPaginate, refreshOrganizations2] = useInfiniteTable<IOrganization>(
    settings.table,
    columns,
    loadPage
  );
  refreshOrganizations.current = refreshOrganizations2;

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        value={query}
        onChange={handleQueryChanged}
        hint={T('organizations.search.placeholder')}
        style={{width: 250, marginRight: '0.5em'}}
        name="filter"
      />
      <Reload onReload={() => refreshOrganizations.current?.()} />
      {/*TODO -- state.ready && (
        <ExportCsv name={plural('organization')} fields={columns} items={organizations} settings={settings.table} />
      )*/}
      <Spring />
      <RsButton
        onClick={handleCreateOrganization}
        style={{whiteSpace: 'nowrap'}}
        className={testingClasses.create}
        data-testid={testingClasses.create}
      >
        <span className="fal fa-plus" />
        &nbsp;{T('organizations.add')}
      </RsButton>
    </CardActions>
  );

  const customSettings: CustomSettings<IOrganizationsSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser fields={columns} settings={settings.table} updateSettings={table => updateSettings({table})} />
    );
  };

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <ServerPagedTable
        fields={columns}
        items={items}
        page={page}
        onPaginate={onPaginate}
        rowKey={rowKey}
        noun="organization"
        hideNounCountLabel={true}
        className="organizations"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IOrganizationsSettings = {
  table: {
    sortColumn: 'name',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [
      {name: 'id', visible: false},
      {name: 'name', visible: true},
      {name: 'internal', visible: true},
      {name: 'website', visible: true},
      {name: 'logo', visible: true},
      {name: 'supportEmailAddress', visible: true},
      {name: 'supportPhoneNumber', visible: true}
    ]
  }
};
const CARD: ICardType<IOrganizationsSettings> = {
  type: CardTypeKey.Organizations,
  title: 'organizations.title',
  description: 'organizations.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerAdminFunctionality,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: Organizations
};
export default CARD;
