import React from 'react';

import {MiniCardHeader} from '../../components/MiniCardHeader';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {BrandColors} from '../../utils/BrandColors';
import {useLocation} from '../../utils/FunctionalData';
import {getCurrentLanguage, T} from '../../utils/Internationalization';
import {dashboardStyle} from '../../utils/Styles';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {MiniCard, miniCardProperties} from '../MiniCard';
import icons from '../PartnerInfo/Icons.module.scss';
import styles from '../PartnerInfo/index.module.scss';

const MyCardView = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;
  const locationId = useCardLocationId(settings);
  const [locationDetails] = useLocation(fetch, locationId);

  const partner = (locationDetails || {organization: undefined}).organization;
  if (!partner || partner.internal) return <div />;

  const colors = (partner ? partner.colors : undefined) || {
    font: '#000000',
    background: '#ffffff',
    buttonFont: '#ffffff',
    buttonBackground: '#155a55'
  };
  const {font: fontColor, background: backgroundColor, buttonBackground} = colors;

  const borderColor = backgroundColor === '#ffffff' ? BrandColors.Pumice : undefined;

  return (
    <MiniCard
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      settingsHandleColor={colors.buttonBackground}
      {...miniCardProperties(props)}
    >
      <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <MiniCardHeader color={fontColor} title={partner.name} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
          }}
        >
          {partner.logo && <img src={partner.logo} className={styles.icon} alt={T('partnerInfo.logo.alt')} />}
          <p
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '0.5rem',
              color: fontColor
            }}
          >
            {partner.description || partner.descriptions[getCurrentLanguage()]}
          </p>
          <ul className={styles.supportList} style={{color: buttonBackground}}>
            {partner.supportWebsite && (
              <li>
                <a
                  href={partner.supportWebsite}
                  target="_blank"
                  style={{color: buttonBackground}}
                  title={partner.supportWebsite}
                  rel="noopener noreferrer"
                >
                  <span className={icons.iconInternet} />
                </a>
              </li>
            )}
            {partner.supportPhoneNumber && (
              <li>
                <span
                  className={icons.iconTelephone}
                  style={{color: buttonBackground}}
                  title={partner.supportPhoneNumber}
                >
                  <span className={icons.iconPhone} />
                </span>
              </li>
            )}
            {partner.supportEmailAddress && (
              <li>
                <a
                  href={`mailto:${partner.supportEmailAddress}`}
                  target="_blank"
                  style={{color: buttonBackground}}
                  title={partner.supportEmailAddress}
                  rel="noopener noreferrer"
                >
                  <span className={icons.iconMail} />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </MiniCard>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.PartnerInfoMini,
  title: 'partnerInfoMini.title',
  description: 'partnerInfoMini.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  width: 1,
  height: 2,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: {},
  isVisible: location => {
    if (location === undefined) return true;
    if (dashboardStyle.showPartnerInfo === false) return false;

    const partner = (location || {organization: undefined}).organization;
    return partner !== undefined && !partner.internal;
  },
  locationAware: CardLocationAwareness.Aware,
  cardClass: MyCardView
};
export default CARD;
