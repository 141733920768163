import numeral from 'numeral';
import React from 'react';

import {Button as RsButton, Input} from '../../../components/bootstrap';

import {Button} from '../../../components/ui/button';
import {Edit, ExternalLink} from '../../../components/ui-lib/icons/small';
import {GPSCoordinate} from '../../../models/GPSCoordinate';

import {T} from '../../../utils/Internationalization';

interface GPSCoordinateEditorProps {
  readOnly: boolean;
  value: GPSCoordinate;
  update: (value: GPSCoordinate) => Promise<any>;
}
interface GPSCoordinateEditorState {
  originalValue: GPSCoordinate;
  loading: boolean;
  editing: boolean;
  editingLat: string;
  editingLon: string;
}

class GPSCoordinateEditor extends React.Component<GPSCoordinateEditorProps, GPSCoordinateEditorState> {
  constructor(props: GPSCoordinateEditorProps) {
    super(props);

    this.state = {
      originalValue: this.props.value,
      loading: false,
      editing: false,
      editingLat: '',
      editingLon: ''
    };
  }

  componentDidUpdate(oldProps: GPSCoordinateEditorProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({originalValue: this.props.value});
    }
  }

  handleClickedEdit = () => {
    const {originalValue} = this.state;
    this.setState({
      editing: true,
      editingLat: originalValue.latitude ? originalValue.latitude.toFixed(6) : '',
      editingLon: originalValue.longitude ? originalValue.longitude.toFixed(6) : ''
    });
  };

  handleClickedSave = () => {
    this.setState({loading: true});
    const value = {
      latitude: parseFloat(this.state.editingLat),
      longitude: parseFloat(this.state.editingLon)
    };

    this.props.update(value).then(() => {
      this.setState({
        editing: false,
        loading: false,
        originalValue: value
      });
    });
  };

  handleClickedCancel = () => {
    this.setState({editing: false});
  };

  handleLatChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({editingLat: e.currentTarget.value});
  };

  handleLonChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({editingLon: e.currentTarget.value});
  };

  render() {
    const {readOnly} = this.props;
    const {editing, loading, originalValue, editingLat, editingLon} = this.state;

    if (editing) {
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Input
            type="text"
            name="latitude"
            placeholder={T('locationConfiguration.field.latitude')}
            value={editingLat}
            onChange={this.handleLatChanged}
            style={{display: 'inline-block', width: '6em'}}
          />
          <Input
            type="text"
            name="longitude"
            placeholder={T('locationConfiguration.field.longitude')}
            value={editingLon}
            onChange={this.handleLonChanged}
            style={{display: 'inline-block', width: '6em'}}
          />
          <RsButton color="primary" onClick={this.handleClickedSave} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.save')}
            {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
          </RsButton>
          <RsButton onClick={this.handleClickedCancel} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.cancelEdit')}
          </RsButton>
        </div>
      );
    } else {
      let valueAsString, viewOnMap;
      if (originalValue.latitude !== undefined && originalValue.longitude !== undefined) {
        valueAsString = `(${numeral(originalValue.latitude).format('0.0000')}, ${numeral(
          originalValue.longitude
        ).format('0.0000')}) `;
        const mapUrl = `https://www.google.com/maps?q=loc:${originalValue.latitude},${originalValue.longitude}`;
        viewOnMap = (
          <a href={mapUrl} target="_blank" rel="noopener noreferrer" className="tw-flex tw-items-center">
            {T('locationConfiguration.viewOnMap')} <ExternalLink className="tw-flex-inline tw-ml-2 tw-mr-1" />
          </a>
        );
      } else {
        valueAsString = '';
        viewOnMap = '';
      }

      return (
        <span className="tw-flex tw-gap-2">
          {valueAsString}
          {viewOnMap}{' '}
          {!readOnly && (
            <Button variant="ghost_action_btn" size="icon" onClick={this.handleClickedEdit}>
              <Edit />
            </Button>
          )}
        </span>
      );
    }
  }
}

export default GPSCoordinateEditor;
