import React from 'react';

import {OrganizationInput, OrganizationInputQueryState} from '../../../components/inputs/OrganizationInput';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../components/ui/select';
import {IOrganizationActivationCode} from '../../../models/ActivationCode';
import {IOrganization} from '../../../models/Organization';

interface ActivationCodeSelectorProps {
  organizations: IOrganization[];
  activationCodes: IOrganizationActivationCode[];
  organization: IOrganization | undefined;
  activationCode: IOrganizationActivationCode | undefined;
  searchable: boolean;
  onChangeOrganization: (organization?: IOrganization) => void;
  onChangeCode: (code: IOrganizationActivationCode) => void;
  updateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
}
export const ActivationCodeSelector = (props: ActivationCodeSelectorProps) => {
  const {
    organizations,
    activationCodes,
    activationCode,
    organization,
    onChangeOrganization,
    onChangeCode,
    updateQuery
  } = props;

  const hasOrganization = organization !== undefined;
  const hasActivationCodes = activationCodes.length > 0;
  const id = activationCode ? activationCode.code : undefined;

  const options = activationCodes
    .filter(code => code.subOrganizationId === undefined || code.subOrganizationId === organization?.id)
    .map(code => (
      <SelectItem key={code.code} value={code.code}>
        {code.name || code.code}
      </SelectItem>
    ));

  const handleChangeCode = (value: string) => {
    const code = activationCodes.find(c => c.code === value);
    if (code) onChangeCode(code);
  };

  return (
    <>
      <OrganizationInput
        organizations={organizations}
        value={organization}
        onChange={onChangeOrganization}
        onUpdateQuery={updateQuery}
      />
      <Select
        name="activationCode"
        value={id || ''}
        onValueChange={handleChangeCode}
        disabled={!hasOrganization}
        invalid={!hasActivationCodes}
      >
        <SelectTrigger className="tw-pl-1 tw-py-[0.5rem] tw-max-h-[2.5rem] tw-h-[2.5rem] !tw-min-w-28 !tw-leading-[1.375rem] !tw-max-w-60 !tw-text-base">
          <SelectValue placeholder="Default" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>{options}</SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
};
