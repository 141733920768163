import React, {useState, useEffect, useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {SingleActionModal, Form, FormGroup} from '../../components/bootstrap';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IActivationCode, getActivationCodesForOrganization} from '../../models/ActivationCode';
import {ILocationSummary, ILocation} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {useOrganizationActivationCodes} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {validateOrderNr} from '../../utils/Validation';

export interface SetActivationCodeResult {
  activationCode: IActivationCode;
  withDevice: boolean;
  orderNr?: string;
}
interface ISetActivationCodeProps extends IPromiseModalProps<SetActivationCodeResult | undefined> {
  content: string;

  location: ILocationSummary | ILocation | undefined;
  organization?: IOrganization;
  activationCode?: IActivationCode;
  value?: IActivationCode;
  withOrderNumber?: boolean;
  withMoveDevice?: boolean;
}

export const SetActivationCodePromiseModal = (props: ISetActivationCodeProps) => {
  const {
    fetch,
    location,
    organization: defaultOrganization,
    activationCode: defaultActivationCode,
    withOrderNumber = false,
    withMoveDevice = true,
    content
  } = props;
  const {api} = useAppContext();
  const form = useFormState();

  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(undefined);

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState(defaultOrganization || inputOrganizations.defaultOrganization);

  const [ownActivationCodes] = useOrganizationActivationCodes(fetch, organization?.id);
  const [parentActivationCodes] = useOrganizationActivationCodes(fetch, organization?.parentId);
  const availableCodes = useMemo(
    () =>
      organization
        ? getActivationCodesForOrganization(ownActivationCodes, parentActivationCodes, organization, false).filter(
            code => code.reusable
          )
        : [],
    [organization, ownActivationCodes, parentActivationCodes]
  );
  const defaultActivationCodeOrParent = useMemo(
    () =>
      defaultActivationCode && defaultActivationCode.subOrganizationId !== undefined
        ? parentActivationCodes.find(code => code.name === defaultActivationCode.name) || defaultActivationCode
        : defaultActivationCode,
    [defaultActivationCode, parentActivationCodes]
  );
  const [activationCode, setActivationCode] = useState(defaultActivationCodeOrParent);
  useEffect(() => setActivationCode(defaultActivationCodeOrParent), [defaultActivationCodeOrParent]);

  const [withDevice, setWithDevice] = useState(false);
  const [orderNr, setOrderNr] = useState('');

  const activationCodeOptions = useMemo(
    () =>
      availableCodes.map(code => (
        <option key={code.id} value={code.id}>
          {code.name || code.code}
        </option>
      )),
    [availableCodes]
  );

  const handleChangedActivationCode = (value: string) => {
    if (!organization) return;

    const id = parseInt(value);
    const activationCode = availableCodes.find(code => code.id === id);
    setActivationCode(activationCode);
  };

  const handleClickedMove = async () => {
    if (!activationCode || !organization) return;

    const orderNrProcessed = withOrderNumber && orderNr !== 'unknown' ? orderNr : undefined;
    if (organization.parentId && activationCode.subOrganizationId !== organization.id) {
      const childActivationCode = await api.activationCodes.createChild(activationCode.code, organization.id);
      if (!childActivationCode) return;

      resolve({
        activationCode: childActivationCode,
        withDevice,
        orderNr: orderNrProcessed
      });
    } else {
      resolve({activationCode, withDevice, orderNr: orderNrProcessed});
    }
  };

  const handleCheckedWithDeviceChanged = (checked: boolean) => {
    setWithDevice(checked);
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      title={T('locations.moveToOrganization.title', {name: content})}
      action={handleClickedMove}
    >
      <FormContextProvider value={form}>
        <Form>
          <OrganizationInputGroup
            name="organization"
            label={T('locations.moveToOrganization.organization')}
            organizations={inputOrganizations.organizations}
            value={organization}
            onChange={setOrganization}
            onUpdateQuery={updateOrganizationInputQuery}
          />
          <SelectInputGroup
            name="activationCode"
            label={T('locations.moveToOrganization.activationCode')}
            value={activationCode ? activationCode.id.toString() : ''}
            onChange={handleChangedActivationCode}
          >
            <option value="">{T('locationConfiguration.activationCode.select')}</option>
            {activationCodeOptions}
          </SelectInputGroup>
          {withOrderNumber && (
            <TextInputGroup
              name="orderNr"
              label={T('shipment.orderNr')}
              value={orderNr}
              info={T('shipment.orderNr.info')}
              onChange={setOrderNr}
              validate={validateOrderNr}
            />
          )}
          {withMoveDevice && (
            <FormGroup>
              {location && location.serialNumber && (
                <Checkbox
                  id="withDevice"
                  name="withDevice"
                  label="Move device registration"
                  checked={withDevice}
                  onCheckedChange={handleCheckedWithDeviceChanged}
                  wrapperClassName="tw-mb-0"
                  testId="withDevice"
                />
              )}
            </FormGroup>
          )}
        </Form>
      </FormContextProvider>
    </SingleActionModal>
  );
};
