import {InfoWindow} from '@react-google-maps/api';
import dayjs from 'dayjs';
import * as React from 'react';
import {Table} from 'reactstrap';

import cableIcon from '../../assets/icons/charger-cable-2.png';
import socketIcon from '../../assets/icons/charger-socket-2.png';
import {Button as RsButton} from '../../components/bootstrap';
import {Badge as UiBadge} from '../../components/ui/badge';
import {
  CarChargerSummary,
  ChargingMode,
  ChargingStationStatus,
  ChargingStationSummary,
  ConnectionType,
  getChargingStationStatus
} from '../../models/ChargingStation';
import {
  CarChargingStationChargingStateMqttMessage,
  getChargingStatusColor,
  getChargingStatusColors,
  translateCarChargerStatus
} from '../../models/ChargingStatus';
import {BrandColors} from '../../utils/BrandColors';
import {quantity, T} from '../../utils/Internationalization';

import {isLastIntervalAvailable} from '../../utils/TimestampUtils';
import {OnlineStatus} from '../components/OnlineStatus';

import {ChargingHubSummary} from './ChargingHub';

import styles from './index.module.scss';

interface ChargingHubInfoWindowProps {
  hub: ChargingHubSummary;
  loadedAt: dayjs.Dayjs;
  onClickLocation: (locationId: number) => void;
  onClose: () => void;
}
export function ChargingHubInfoWindow(props: ChargingHubInfoWindowProps) {
  const {hub, loadedAt, onClickLocation, onClose} = props;

  const chargers = React.useMemo(() => {
    return hub.chargingStations.flatMap(station =>
      station.chargers.map((charger, index) => ({station, charger, index}))
    );
  }, [hub]);

  if (hub.location.latitude === undefined || hub.location.longitude === undefined) {
    return null;
  }

  return (
    <InfoWindow
      position={{lat: hub.location.latitude, lng: hub.location.longitude}}
      options={{
        pixelOffset: new google.maps.Size(0, -32),
        maxWidth: 750
      }} /* probably a bug due to icon scaling */
      onCloseClick={onClose}
    >
      <div>
        <h5>
          <RsButton color="link" onClick={() => onClickLocation(hub.location.id)}>
            {hub.location.name}
          </RsButton>
        </h5>
        <p style={{padding: '0px 12px'}}>{quantity('chargingStation', hub.chargingStations.length)}</p>
        <Table>
          <tbody>
            {chargers.map(charger => (
              <ChargerRow
                key={charger.charger.id}
                charger={charger.charger}
                station={charger.station}
                index={charger.index}
                loadedAt={loadedAt}
                onClickLocation={onClickLocation}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </InfoWindow>
  );
}

interface ChargerRowProps {
  charger: CarChargerSummary;
  station: ChargingStationSummary;
  index: number;
  loadedAt: dayjs.Dayjs;
  onClickLocation: (locationId: number) => void;
}
function ChargerRow(props: ChargerRowProps) {
  const {charger, station, index, loadedAt, onClickLocation} = props;

  const iecStatus = {current: charger.iecStatus};
  const ultra = charger.serialNumber ? charger.serialNumber.startsWith('63') : false;
  const status: CarChargingStationChargingStateMqttMessage = {iecStatus, chargingState: charger.chargingMode as any};
  let statusTranslated = translateCarChargerStatus(status, ultra);
  let statusColor: string | undefined;
  let iconBackgroundColor = BrandColors.GreenAtlantis as string;
  const isOnline = isLastIntervalAvailable(loadedAt, station.lastInterval);

  const stationStatus = getChargingStationStatus(station);
  switch (stationStatus) {
    case ChargingStationStatus.Available:
      iconBackgroundColor = BrandColors.ChargerAvailable;
      break;
    case ChargingStationStatus.Charging:
      iconBackgroundColor = BrandColors.ChargerInUse;
      break;
    case ChargingStationStatus.Disabled:
      iconBackgroundColor = BrandColors.ChargerUnavailable;
      break;
    case ChargingStationStatus.Unavailable:
    default:
      iconBackgroundColor = BrandColors.ChargerError;
      break;
  }

  if (charger.iecStatus) {
    const colors = getChargingStatusColors(getChargingStatusColor(status, ultra));
    statusColor = colors.background === 'white' ? '#6c757d' : colors.background;
  }
  if (charger.chargingMode === ChargingMode.Stop || station.available === false) {
    statusTranslated = T('chargingStation.status.unavailable');
    statusColor = BrandColors.Red;
  }
  if (!isOnline) {
    iconBackgroundColor = BrandColors.ChargerError;
  }

  return (
    <tr key={charger.id}>
      {index === 0 && (
        <>
          <td rowSpan={station.chargers.length}>
            <div className={styles.connectorType} style={{backgroundColor: iconBackgroundColor}}>
              <img src={station.connectionType === ConnectionType.Socket ? socketIcon : cableIcon} />
            </div>
          </td>
          <td rowSpan={station.chargers.length}>
            <OnlineStatus isOnline={isOnline} />
          </td>
          <td rowSpan={station.chargers.length}>
            <RsButton color="link" onClick={() => onClickLocation(station.serviceLocation.id)}>
              {station.serviceLocation.name}
            </RsButton>
          </td>
        </>
      )}
      <td className="!tw-pl-2 tw-w-[3rem]">
        {charger.position === undefined ? undefined : (
          <UiBadge className={`tw-bg-${statusColor}`}>{charger.position}</UiBadge>
        )}
      </td>
      <td className={`tw-text-[${statusColor}] tw-whitespace-nowrap tw-w-[5.5rem]`}>{statusTranslated}</td>
    </tr>
  );
}
