import {formatCurrencyNumber, getCurrencySymbol} from '../../../utils/Currency';
import {T, singular} from '../../../utils/Internationalization';
import {Icons} from '../../Icon';
import {TariffState, TimedInput} from '../configurePricingPolicy/FormState';
import {formatMinutes} from '../configurePricingPolicy/utils';

import styles from './TariffView.module.scss';

interface TariffViewProps {
  title: string;
  info?: string;
  currency: string;
  tariff: TariffState;
  discount?: number;
}

export function TariffView(props: TariffViewProps) {
  const {title, info, currency, tariff, discount} = props;
  const currencySymbol = getCurrencySymbol(currency);
  const multiplier = discount === undefined ? 1 : 1 - discount * 0.01;

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        {title} {info && <Icons.Info title={info} />}
      </div>
      {tariff.startingFee && tariff.startingFee.numberValue !== null && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.fixedCost')}</div>
          <div>
            <span className={styles.newPrice}>
              {currencySymbol} {formatCurrencyNumber(currency, tariff.startingFee.numberValue * multiplier)} /{' '}
              {T('pricingPolicies.unit.session')}
            </span>
          </div>
        </>
      )}
      {tariff.costPerKwh && tariff.costPerKwh.numberValue !== null && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.energyCost')}</div>
          <div>
            <span className={styles.newPrice}>
              {currencySymbol} {formatCurrencyNumber(currency, tariff.costPerKwh.numberValue * multiplier)} /{' '}
              {T('pricingPolicies.unit.kwh')}
            </span>
          </div>
        </>
      )}
      {tariff.timeComponents && tariff.timeComponents.length > 0 && (
        <>
          <div className={styles.header}>{T('pricingPolicies.add.tariffs.hourlyCost')}</div>
          {tariff.timeComponents.map((component, index) => (
            <div>
              <span className={styles.prefix}>{getTimeComponentPrefix(tariff.timeComponents!, index)}</span>
              <span className={styles.newPrice}>
                {currencySymbol} {formatCurrencyNumber(currency, (component.cost.numberValue || 0) * multiplier)} /{' '}
                {T('pricingPolicies.unit.hour')}
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export function getTimeComponentPrefix(components: TimedInput[], index: number): string | null {
  if (components.length === 1) {
    return null;
  }

  if (index === 0) {
    return `${T('pricingPolicies.timeTariffFirst', {minutes: formatMinutes(components[1].afterMinutes)})}: `;
  } else {
    return `${T('pricingPolicies.timeTariffAfter', {minutes: formatMinutes(components[index].afterMinutes)})}: `;
  }
}

export function formatTimeComponentPricing(currency: string, components: TimedInput[], index: number): string {
  const prefix = getTimeComponentPrefix(components, index);
  return `${prefix || ''} ${getCurrencySymbol(currency)} ${formatCurrencyNumber(
    currency,
    components[index].cost.numberValue || 0
  )} / ${singular('hour')}`;
}
