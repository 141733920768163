import * as React from 'react';

import {useScript} from '../utils/UseScript';

declare const AppleID: any;

interface AppleAuthContextProps {
  authenticate: ({
    onSuccess,
    onError
  }: {
    onSuccess?: (response: AppleIDAuthorizationResponse) => void;
    onError?: (error: any) => void;
  }) => void;
  appleAuthAvailable: boolean;
}

const AppleAuthContext = React.createContext<AppleAuthContextProps | null>(null);
AppleAuthContext.displayName = 'AppleAuthContext';

interface AppleAuthProviderProps {
  clientId: string;
  children: React.ReactNode;
  redirectUrl: string;
}

export interface AppleIDAuthorizationSuccessResponse {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface AppleIDAuthorizationErrorResponse {
  error: string;
  state: string;
}

export type AppleIDAuthorizationResponse = AppleIDAuthorizationSuccessResponse | AppleIDAuthorizationErrorResponse;

export function isAppleIDSuccessResponse(
  response: AppleIDAuthorizationResponse
): response is AppleIDAuthorizationSuccessResponse {
  return (response as AppleIDAuthorizationSuccessResponse).authorization !== undefined;
}

// import { AppleAuthProvider } from "path-to-context/AppleAuthContext"
// use <AppleAuthProvider> as a wrapper around the part you need the context for
function AppleAuthProvider({clientId, children, redirectUrl}: AppleAuthProviderProps) {
  const [scriptLoaded] = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );

  React.useEffect(() => {
    if (scriptLoaded && typeof AppleID !== 'undefined') {
      try {
        AppleID.auth.init({
          scope: 'name email',
          clientId,
          redirectURI: redirectUrl,
          usePopup: true
        });
      } catch (error) {
        console.error('Failed to initialize AppleID', error);
      }
    }
  }, [clientId, redirectUrl, scriptLoaded]);

  const authenticate = React.useCallback(
    ({
      onSuccess,
      onError
    }: {
      onSuccess?: (response: AppleIDAuthorizationResponse) => void;
      onError?: (error: any) => void;
    }) => {
      if (!scriptLoaded || typeof AppleID === 'undefined') {
        const errorMessage = 'Apple Sign-In not available. Try later';
        console.error(errorMessage);
        onError?.(errorMessage);
        return;
      }

      return AppleID.auth.signIn().then(onSuccess).catch(onError);
    },
    [scriptLoaded]
  );

  const appleAuthAvailable = scriptLoaded && typeof AppleID !== 'undefined';

  return <AppleAuthContext.Provider value={{authenticate, appleAuthAvailable}}>{children}</AppleAuthContext.Provider>;
}

// import { useAppleAuth } fron "path-to-context/AppleAuthContext"
// within functional component
// const { sessionToken, ...AppleAuthContext } = useAppleAuth()
function useAppleAuth(): AppleAuthContextProps {
  const context = React.useContext(AppleAuthContext);

  if (!context) {
    throw new Error('You should use useAppleAuth within an AppleAuthContext');
  }

  return context;
}

export {AppleAuthProvider, useAppleAuth};
