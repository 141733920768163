import {PhaseType, getPhasesForType} from '../../models/HighLevelConfiguration';
import {Phase, getPhaseIndex} from '../../models/Phase';

import {LoadWithChannels} from './Data';
import {SVGDisplayBuilder} from './SVGDisplayBuilder';

const MAX_VOLTAGE_LENGTH = 180;
const MAX_CURRENT_LENGTH = 120;
const INNER_CIRCLE = 60;

// Colors
export const VOLTAGE_COLOR = '#000000';
export const CURRENT_COLOR = '#ff0000';

const VOLTAGE_COLORS_PRIMARY = ['#C65B0D', '#BD921F', '#10235D'];
const VOLTAGE_COLORS_SECONDARY = ['#A9541B', '#A2851F', '#000135'];
const CURRENT_COLORS_PRIMARY = ['#EF843C', '#A9541B', '#629FCB'];
const CURRENT_COLORS_SECONDARY = ['#B97D58', '#CAA53A', '#4C789D'];

class BuildSvg {
  target: SVGDisplayBuilder;
  isDelta: boolean;
  useReversers: boolean;
  offsetVectorAngle: number;
  swap: 1 | -1;
  phases: Phase[];

  constructor(target: SVGDisplayBuilder, phaseType: PhaseType, isDelta = false, useReversers = false) {
    this.target = target;
    this.isDelta = phaseType === PhaseType.Delta;
    this.useReversers = useReversers;
    this.offsetVectorAngle = 0;
    this.swap = -1;
    this.phases = getPhasesForType(phaseType);
  }

  process(
    load: LoadWithChannels,
    voltages: number[],
    voltageAngles: number[],
    currents: number[],
    currentAngles: number[]
  ) {
    this.target.addStrokedCircle('voltageCircle', '#cccccc', MAX_VOLTAGE_LENGTH, 2);
    this.target.addStrokedCircle('currentCircle', '#cccccc', MAX_CURRENT_LENGTH, 2);
    this.target.addStrokedCircle('innerCircle', '#cccccc', INNER_CIRCLE, 2);

    this.offsetVectorAngle = voltageAngles[0];

    if (voltages.length > 0) {
      this.buildVoltages(voltages, voltageAngles, load);
    }

    if (currents.length > 0) {
      this.buildCurrents(currents, currentAngles, load);
    }
  }

  buildVoltages(voltages: number[], voltageAngles: number[], load: LoadWithChannels) {
    const scale = MAX_VOLTAGE_LENGTH / (Math.max(...voltages) || 1);
    for (let i = 0; i < this.phases.length; i++) {
      const phase = this.phases[i];
      const phaseIndex = getPhaseIndex(phase);
      if (voltages[phaseIndex] < 0.2) continue;

      this.swap *= -1;
      let angle = this.offsetVectorAngle - voltageAngles[phaseIndex];
      let magnitude = scale * voltages[phaseIndex];

      // Label
      const angleLabel = Math.round(angle * -1).toString();
      const label = `L${phaseIndex + 1} ${angleLabel}°`;
      const primaryColor = VOLTAGE_COLORS_PRIMARY[phaseIndex % VOLTAGE_COLORS_PRIMARY.length];
      const secondaryColor = VOLTAGE_COLORS_SECONDARY[phaseIndex % VOLTAGE_COLORS_SECONDARY.length];
      this.target.addVector(
        `voltage${phaseIndex}`,
        primaryColor,
        secondaryColor,
        label,
        magnitude,
        BuildSvg.toRadians(angle)
      );
    }
  }

  buildCurrents(currents: number[], currentAngles: number[], load: LoadWithChannels) {
    const {channels} = load;

    let maxCurrent = 0;
    channels.forEach(channel => (maxCurrent = Math.max(maxCurrent, currents[channel.channelIndex])));
    const scale = MAX_CURRENT_LENGTH / (maxCurrent || 1);

    for (let i = 0; i < channels.length; i++) {
      let channel = channels[i];

      const {phase, channelIndex, labelName, reversed} = channel;
      const phaseIndex = getPhaseIndex(phase);

      // Not applicable
      if (currents[channelIndex] === 0) continue;

      this.swap *= -1;
      const angle = this.offsetVectorAngle - currentAngles[channelIndex];
      const magnitude = scale * currents[channelIndex] * (this.useReversers && reversed ? -1 : 1);

      // Label
      const primaryColor = CURRENT_COLORS_PRIMARY[phaseIndex % CURRENT_COLORS_PRIMARY.length];
      const secondaryColor = CURRENT_COLORS_SECONDARY[phaseIndex % CURRENT_COLORS_SECONDARY.length];
      this.target.addVector(
        `channel${phaseIndex}`,
        primaryColor,
        secondaryColor,
        labelName,
        magnitude,
        BuildSvg.toRadians(angle)
      );
    }
  }

  static toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }
}

export default BuildSvg;
