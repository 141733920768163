import {useMemo, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import {Icon, SMAPPEE_FONT_ICON_MAP} from '../../components/Icon';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import Table, {migrateTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {useModals} from '../../modals/ModalContext';
import RemoveModal from '../../modals/RemoveModal';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {Invitation} from '../../models/Invitation';
import {IRoleType} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {ITableField, StringField, CalculatedStringField, ComponentField} from '../../models/Table';
import {IUser, getRoleTypeDisplayName, hasPartnerAdminFunctionality} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader, useLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {Reload} from '../components/actions';
import {CardActions} from '../components/CardActions';
import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';

import {LinkUser} from './LinkUser';

interface IOrganizationUsersSettings extends ICardSettingsWithTable {
  organizationId?: number;
}

function renderActionColumn(callbacks: Callbacks, user: IUser) {
  return (
    <>
      {!user.confirmed && user.id && (
        <RowActionButton
          title={T('organizationUsers.resend')}
          icon="Redo"
          onClick={() => callbacks.onClickedResend(user)}
        />
      )}
      {user.id && (
        <RowActionButton
          title={T('organizationUsers.unlink')}
          onClick={() => callbacks.onClickedUnlink(user)}
          className={testingClasses.remove}
          data-testid={testingClasses.remove}
          icon="Unlink"
        />
      )}
    </>
  );
}

interface Callbacks {
  onClickedUnlink: (user: IUser) => void;
  onClickedResend: (user: IUser) => void;
}

function getTableColumns(roles: IRoleType[], callbacks: Callbacks): ITableField<IUser>[] {
  let columns: ITableField<IUser>[] = [];

  if (!hasFeature(AppFeature.SocialLogin)) {
    columns.push(new StringField('userName', T('organizationUsers.field.username')));
  }

  return [
    ...columns,
    new StringField('firstName', T('organizationUsers.field.firstName')),
    new CalculatedStringField('confirmed', T('organizationUsers.field.confirmed'), row =>
      T.generic.yesNo(row.confirmed === undefined ? true : row.confirmed)
    ),
    new StringField('emailAddress', T('organizationUsers.field.emailAddress')),
    new CalculatedStringField('type', T('organizationUsers.field.role'), row => getRoleTypeDisplayName(row.role)),
    new ComponentField('actions', T('organizationUsers.field.actions'), user => renderActionColumn(callbacks, user), {
      autoInsert: 'end'
    })
  ];
}

const rowKey = (row: IUser, index: number) => (row.id ? row.id.toString() : index.toString());

const OrganizationUsers = (props: ICardProps<IOrganizationUsersSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const setOrganization = useCallback(
    (organization: IOrganization | undefined) => {
      updateSettings({organizationId: organization?.id});
    },
    [updateSettings]
  );

  const [roles = None] = useLoader(api => api.getRoleTypes(), []);

  const [entries = [], refreshEntries] = useCardLoader<IUser[]>(
    () => {
      if (!organization) return Promise.resolve(None);

      return api.organizations.getOrganizationUsers(organization.id);
    },
    [organization],
    'organizationUser',
    None
  );

  let error: string | undefined;
  if (!organization) error = T('organizationUsers.error.noneSelected');

  const handleClickedLink = async () => {
    const changed = await modals.show<boolean>(props => <LinkUser organization={organization} {...props} />);
    if (changed) refreshEntries();
  };

  const LinkIconComponent = SMAPPEE_FONT_ICON_MAP[Icon.Link];

  const actions: CustomActions = () => (
    <CardActions>
      {inputOrganizations.showInput && (
        <OrganizationInput
          organizations={inputOrganizations.organizations}
          value={organization}
          name="organization"
          onChange={setOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
          placeholder={T('organizationUsers.selectOrganization')}
        />
      )}
      <Reload onReload={refreshEntries} />
      {/* <Spring /> */}
      <Button variant="secondary_default" onClick={handleClickedLink} className="!tw-mx-0">
        {LinkIconComponent !== null && (
          <span className="tw-mr-2">
            <LinkIconComponent width={16} height={16} />
          </span>
        )}
        {T('organizationUsers.link')}
      </Button>
    </CardActions>
  );

  const columns = useMemo(() => {
    const handleClickedUnlink = async (user: IUser) => {
      if (!organization) return;

      const result = await modals.show<boolean>(props => (
        <RemoveModal
          title={T('users.organizations.confirmUnlink.title')}
          message={T('users.organizations.confirmUnlink.message', {
            user: user.userName || user.emailAddress || '',
            organization: organization.name
          })}
          buttonLabel={T('users.organizations.confirmUnlink.accept')}
          execute={api => api.organizations.removeOrganizationUser(organization.id, user.id)}
          successMessage={T('organizationUsers.unlink.success')}
          failureMessage={T('organizationUsers.unlink.error')}
          {...props}
        />
      ));
      if (result) refreshEntries();
    };

    const handleClickedResend = async (user: IUser) => {
      if (!organization) return;

      try {
        const sent = await api.organizations.resendOrganizationUserConfirmation(organization.id, user.id);
        if (sent?.sent) {
          NotificationManager.success(T('organizationUsers.resend.success'));
        } else {
          NotificationManager.error(T('organizationUsers.resend.failed'));
        }
      } catch {
        NotificationManager.error(T('organizationUsers.resend.failed'));
      }
    };

    return getTableColumns(roles, {
      onClickedUnlink: handleClickedUnlink,
      onClickedResend: handleClickedResend
    });
  }, [api, roles, organization, modals, refreshEntries]);

  const renderSettings: CustomSettings<IOrganizationUsersSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser fields={columns} settings={settings.table} updateSettings={table => updateSettings({table})} />
    );
  };

  return (
    <CardView error={error} customSettings={renderSettings} actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={entries}
        rowKey={rowKey}
        noun="user"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        emptyMessage={T('organizationUsers.error.noUsersLinked')}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IOrganizationUsersSettings = {
  table: {
    sortColumn: hasFeature(AppFeature.SocialLogin) ? 'email' : 'userName',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [
      ...(hasFeature(AppFeature.SocialLogin) ? [{name: 'userName', visible: true}] : []),
      {name: 'firstName', visible: true},
      {name: 'emailAddress', visible: true},
      {name: 'type', visible: true},
      {name: 'status', visible: true}
    ]
  }
};
const CARD: ICardType<IOrganizationUsersSettings> = {
  type: CardTypeKey.OrganizationUsers,
  title: 'organizationUsers.title',
  description: 'organizationUsers.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerAdminFunctionality,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: OrganizationUsers,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table)
};
export default CARD;
