import React, {useEffect, useMemo, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Input, RowActions} from '../../components/bootstrap';
import {useChargingStationForLocation} from '../../components/inputs/LocationInput';
import Table from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Plus} from '../../components/ui-lib/icons/small';
import Exchange from '../../components/ui-lib/legacy-icons/Exchange';
import InfoCircle from '../../components/ui-lib/legacy-icons/InfoCircle';
import TimesCircle from '../../components/ui-lib/legacy-icons/TimesCircle';
import {cn} from '../../lib/utils';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ChargingStationModule} from '../../models/ChargingStation';
import {
  DeviceType,
  getDeviceTypeLabelFor,
  isChargingController,
  isGatewayDevice,
  isReplaceableEVComponent
} from '../../models/DeviceType';
import {isReadOnly} from '../../models/Location';
import {CalculatedStringField, ComponentField, ITableField, NumberField} from '../../models/Table';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import AddComponentModal from './AddComponentModal';
import InfoMIDModal from './InfoMIDModal';
import InfoModal from './InfoModal';
import ReplaceComponentModal from './ReplaceComponentModal';

const noReplacementExceptions = [
  'MID_POLY_PHASE',
  'POWERBOX',
  'AC_CAR_CHARGE_STICKER',
  'DC_CAR_CHARGE_RFID_TRIPLEDISPLAY'
];

function canReplace(module: ChargingStationModule, isStationInstalled: boolean, isServiceDesk: boolean): boolean {
  if (isGatewayDevice(module.type)) {
    return !isStationInstalled;
  }
  if (!isStationInstalled && !isServiceDesk) {
    return false;
  }
  if (!isReplaceableEVComponent(module.type) || noReplacementExceptions.includes(module.type)) {
    return false;
  }
  if (
    !isServiceDesk &&
    (module.replacementCandidateSerialNumbers === undefined || module.replacementCandidateSerialNumbers.length === 0)
  ) {
    return false;
  }
  return true;
}

function MyCardView(props: ICardProps<ICardSettingsWithTable>) {
  const {settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();
  const location = useCardLocation(settings);
  const [chargingStation] = useChargingStationForLocation(api, location);
  const [enteringSerialNumber, setEnteringSerialNumber] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();
  const isReadOnlyUser = isReadOnly(me, location);
  useEffect(() => {
    if (enteringSerialNumber.length === 10) {
      setSerialNumber(enteringSerialNumber);
    }
  }, [enteringSerialNumber]);
  useEffect(() => {
    if (chargingStation) {
      setEnteringSerialNumber(chargingStation.data.serialNumber);
    }
  }, [chargingStation]);

  const [station, refreshStation] = useLoader(
    (api, force) =>
      serialNumber
        ? api.chargingStations.getBySerial(serialNumber, force).catch(x => undefined)
        : Promise.resolve(undefined),
    [serialNumber]
  );
  useEffect(() => {
    const serialNumber = (chargingStation && chargingStation.data.serialNumber) || '';
    setEnteringSerialNumber(serialNumber);
    setSerialNumber(serialNumber);
  }, [chargingStation]);

  const components = useMemo(() => {
    if (!station) return [];

    // Filter out duplicate modules
    return station.modules.filter((module, index, self) => {
      return (
        index ===
        self.findIndex(m => m.serialNumber === module.serialNumber && m.type !== 'DC_CAR_CHARGE_RFID_TRIPLEDISPLAY')
      );
    });
  }, [station]);

  const columns = useMemo(() => {
    const handleClickedReplace = (module: ChargingStationModule) => {
      if (!station) return;

      modals
        .show<boolean>(props => (
          <ReplaceComponentModal stationSerial={station.serialNumber} module={module} {...props} />
        ))
        .then(() => refreshStation(true));
    };

    const handleClickInfoMid = (module: ChargingStationModule) => {
      if (!station) return;
      modals.show<boolean>(props => <InfoMIDModal module={module} {...props} />).then(() => refreshStation(true));
    };

    const handleClickInfoPowerBox = (module: ChargingStationModule) => {
      if (!station) return;
      modals
        .show<boolean>(props => (
          <InfoModal message={T('moduleReplacement.replace.powerBox.info')} module={module} {...props} />
        ))
        .then(() => refreshStation(true));
    };

    const handleClickedRemove = (module: ChargingStationModule) => {
      if (!station) return;

      modals
        .show<ConfirmationResult>(props => (
          <ConfirmationPromiseModal
            title={T('moduleReplacement.removeModule.title')}
            message={`Are you sure you want to remove ${getDeviceTypeLabelFor(module.type)} ${module.serialNumber}?`}
            {...props}
          />
        ))
        .then(confirmed => {
          if (confirmed !== ConfirmationResult.Accept) return;

          api.removeChargingStationModule(station.serialNumber, module.id).then(() => refreshStation(true));
        });
    };

    return [
      new CalculatedStringField('type', T('chargingStationComponents.field.type'), item =>
        getDeviceTypeLabelFor(item.type)
      ),
      new NumberField('position', T('chargingStationComponents.field.connector'), {digitsAfterComma: 0}),
      new ComponentField(
        'serialNumber',
        T('chargingStationComponents.field.serialNumber'),
        item => {
          return item.active ? (
            <span>{item.serialNumber}</span>
          ) : (
            <span style={{color: 'red'}}>{item.serialNumber}</span>
          );
        },
        {alwaysVisible: false}
      ),
      new ComponentField('actions', T('chargingStationComponents.field.actions'), item => {
        const showAllReplaceableModules =
          item.hasOwnProperty('isReplaceable') &&
          item.hasOwnProperty('replacementCandidateSerialNumbers') &&
          item.isReplaceable;

        const hasReplacementCandidates =
          showAllReplaceableModules && item.replacementCandidateSerialNumbers!.length > 0;

        const isStationInstalled = station !== undefined && station.serviceLocation !== undefined;

        return (
          <RowActions>
            {item.type === 'MID_POLY_PHASE' && (
              <Button variant="ghost_action_btn" onClick={() => handleClickInfoMid(item)}>
                <InfoCircle width={16} height={16} />
              </Button>
            )}
            {item.type === 'POWERBOX' && (
              <Button variant="ghost_action_btn" onClick={() => handleClickInfoPowerBox(item)}>
                <InfoCircle width={16} height={16} />
              </Button>
            )}
            {!isReadOnlyUser && canReplace(item, isStationInstalled, isServiceDesk) && (
              <Button
                variant="ghost_action_btn"
                onClick={() => handleClickedReplace(item)}
                title={T('moduleReplacement.replace')}
                className={`!tw-mx-1 !tw-p-0 ${hasReplacementCandidates ? 'tw-text-brandColor' : 'tw-text-disabled-foreground'}`}
              >
                <Exchange width={16} height={16} />
              </Button>
            )}
            {!isReadOnlyUser && item.type === DeviceType.CarChargerSticker && (
              <Button
                variant="ghost_action_btn"
                onClick={() => handleClickedRemove(item)}
                title={T('generic.action.remove')}
                className="!tw-mx-1 !tw-p-0"
              >
                <TimesCircle width={16} height={16} />
              </Button>
            )}
          </RowActions>
        );
      })
    ] as ITableField<ChargingStationModule>[];
  }, [station, modals, refreshStation, api, isServiceDesk, isReadOnlyUser]);

  const handleClickedAdd = () => {
    if (station === undefined) return;

    const validPositions = components.filter(m => isChargingController(m)).map(m => m.position);

    modals
      .show<boolean>(props => (
        <AddComponentModal chargingStationSerial={station.serialNumber} validPositions={validPositions} {...props} />
      ))
      .then(result => result && refreshStation(true));
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        value={enteringSerialNumber}
        onChange={e => setEnteringSerialNumber(e.currentTarget.value)}
        style={{width: 250, marginRight: 10}}
      />
      <Reload onReload={() => refreshStation(true)} />
      <Spring />
      {state.ready && !isReadOnlyUser && (
        <Button
          variant="secondary_default"
          size="lg"
          title={T('moduleReplacement.addModule.title')}
          onClick={handleClickedAdd}
          className={cn(testingClasses.addButton, 'tw-gap-2')}
          data-testid={testingClasses.addButton}
        >
          <Plus width={16} height={16} />
          {T('moduleReplacement.addModule.title')}
        </Button>
      )}
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <Table
        fields={columns}
        items={components}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="module"
      />
    </CardView>
  );
}

const CARD: ICardType<ICardSettingsWithTable> = {
  title: 'chargingStationComponents.title',
  description: 'chargingStationComponents.description',
  type: CardTypeKey.ChargingStationComponents,
  locationAware: CardLocationAwareness.Unaware,
  categories: [CardCategory.SERVICEDESK, CardCategory.EV],
  rights: UserRights.User,
  width: 4,
  height: 3,
  cardClass: MyCardView,
  defaultSettings: {
    table: {
      pageSize: 10,
      columns: [
        {name: 'type', visible: true},
        {name: 'position', visible: true},
        {name: 'serialNumber', visible: true}
      ]
    }
  }
};
export default CARD;
