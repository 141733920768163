import {NumberValue} from '../../../components/inputs/NumberInput';
import {Address} from '../../../models/Countries';
import {InstallationPartner} from '../../../models/InstallationPartner';
import {IOrganization, OrganizationLanguage, OrganizationLanguages} from '../../../models/Organization';
import {None} from '../../../utils/Arrays';
import {getCurrentLanguage, Language} from '../../../utils/Internationalization';

export interface OrganizationFieldTranslation {
  language: OrganizationLanguage;
  value: string;
}

function getTranslations(translations: {[key: string]: string} | undefined) {
  if (translations === undefined) return [];

  const result: OrganizationFieldTranslation[] = [];
  for (const language of OrganizationLanguages) {
    if (language === OrganizationLanguage.English) continue;
    if (translations[language]) {
      result.push({
        language: language as OrganizationLanguage,
        value: translations[language]
      });
    }
  }
  return result;
}

export interface EditOrganizationFormState {
  parentId: number | undefined;
  internal: boolean;
  name: string;
  website: string;
  description: string;
  descriptionTranslations: OrganizationFieldTranslation[];
  backgroundColor: string;
  fontColor: string;
  buttonBackgroundColor: string;
  buttonFontColor: string;
  logo: string;
  privacyPolicyUrl: string;
  supportEmail: string;
  supportPhone: string;
  supportWebsite: string;

  linkAccounting: boolean;

  vat: string;
  address: Address;

  contactLanguage: Language;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;

  billingSameAsCompany: boolean;
  billingFirstName: string;
  billingLastName: string;
  billingEmail: string;
  billingPhone: string;
  billingAddress: Address;

  selfBillFooterDetails: string[];
  cpoFeePercentage?: NumberValue;
  acConnectorFee?: NumberValue;
  dcConnectorFee?: NumberValue;
  paymentTerminalFee?: NumberValue;
  cpoBillReference: string;
  roamingBeta: boolean;

  emspSessionFee?: NumberValue;
  emspBillReference: string;

  splitBillingBillReference: string;

  installationPartner?: InstallationPartner;

  isSupportValid: boolean;
  supportInvalidVisible: boolean;

  vatExempt: boolean;
  vatExemptNote: string;
}

export function getDefaultFormState(): EditOrganizationFormState {
  return {
    parentId: undefined,
    internal: false,
    name: '',
    website: '',
    description: '',
    descriptionTranslations: None,
    backgroundColor: '',
    fontColor: '',
    buttonBackgroundColor: '',
    buttonFontColor: '',
    logo: '',
    privacyPolicyUrl: '',
    supportEmail: '',
    supportPhone: '',
    supportWebsite: '',

    linkAccounting: false,

    vat: '',
    address: {
      streetAndNumber: '',
      city: '',
      postalCode: '',
      countryCode: 'BE'
    },

    contactLanguage: getCurrentLanguage(),
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',

    billingSameAsCompany: true,
    billingFirstName: '',
    billingLastName: '',
    billingEmail: '',
    billingPhone: '',
    billingAddress: {
      streetAndNumber: '',
      city: '',
      postalCode: '',
      countryCode: 'BE'
    },

    selfBillFooterDetails: [],
    cpoBillReference: '',
    roamingBeta: false,
    emspBillReference: '',
    splitBillingBillReference: '',

    isSupportValid: false,
    supportInvalidVisible: false,

    vatExempt: false,
    vatExemptNote: ''
  };
}

function getDefaultTranslation(value: string | undefined, translations: {[key: string]: string} | undefined) {
  if (value !== undefined) return value;
  if (translations !== undefined) {
    return translations[OrganizationLanguage.English] || '';
  }

  return '';
}

export function getFormStateForOrganization(organization: IOrganization): EditOrganizationFormState {
  const odooCompany = organization.odooCompany;
  const odooContact = odooCompany?.contact;
  const odooBillingContact = odooCompany?.billingContact;

  const billingSameAsCompany =
    (odooCompany !== undefined && odooBillingContact === undefined) ||
    (odooContact?.firstName === odooBillingContact?.firstName &&
      odooContact?.lastName === odooBillingContact?.lastName &&
      odooContact?.language === odooBillingContact?.language &&
      odooContact?.email === odooBillingContact?.email &&
      odooContact?.phone === odooBillingContact?.phone &&
      odooCompany?.address?.streetAndNumber === odooBillingContact?.address?.streetAndNumber &&
      odooCompany?.address?.postalCode === odooBillingContact?.address?.postalCode &&
      odooCompany?.address?.city === odooBillingContact?.address?.city &&
      odooCompany?.address?.countryCode === odooBillingContact?.address?.countryCode);

  return {
    parentId: organization.parentId,
    internal: organization.internal,
    name: organization.name,
    website: organization.website || '',
    description: getDefaultTranslation(organization.description, organization.descriptions),
    descriptionTranslations: getTranslations(organization.descriptions),
    backgroundColor: organization.colors?.background || '',
    fontColor: organization.colors?.font || '',
    buttonBackgroundColor: organization.colors?.buttonBackground || '',
    buttonFontColor: organization.colors?.buttonFont || '',
    logo: organization.logo,
    privacyPolicyUrl: organization.privacyPolicy || '',
    supportEmail: organization.supportEmailAddress || '',
    supportPhone: organization.supportPhoneNumber || '',
    supportWebsite: organization.supportWebsite || '',

    linkAccounting: odooCompany !== undefined,

    vat: odooCompany?.vat || '',
    address: {
      streetAndNumber: odooCompany?.address?.streetAndNumber || '',
      city: odooCompany?.address?.city || '',
      postalCode: odooCompany?.address?.postalCode || '',
      countryCode: odooCompany?.address?.countryCode || 'BE'
    },

    contactLanguage: odooContact?.language || getCurrentLanguage(),
    contactFirstName: odooCompany?.contact?.firstName || '',
    contactLastName: odooCompany?.contact?.lastName || '',
    contactEmail: odooCompany?.contact?.email || '',
    contactPhone: odooCompany?.contact?.phone || '',

    billingSameAsCompany,
    billingFirstName: odooBillingContact?.firstName || '',
    billingLastName: odooBillingContact?.lastName || '',
    billingEmail: odooBillingContact?.email || '',
    billingPhone: odooBillingContact?.phone || '',
    billingAddress: {
      streetAndNumber: odooBillingContact?.address?.streetAndNumber || '',
      city: odooBillingContact?.address?.city || '',
      postalCode: odooBillingContact?.address?.postalCode || '',
      countryCode: odooBillingContact?.address?.countryCode || 'BE'
    },

    selfBillFooterDetails: organization.selfBillFooterDetails || None,
    cpoBillReference: organization.cpo?.billReference || '',
    cpoFeePercentage:
      organization.cpo?.cpoFeePercentage === undefined
        ? undefined
        : NumberValue.create(organization.cpo.cpoFeePercentage),
    acConnectorFee:
      organization.cpo?.acConnectorFee === undefined ? undefined : NumberValue.create(organization.cpo.acConnectorFee),
    dcConnectorFee:
      organization.cpo?.dcConnectorFee === undefined ? undefined : NumberValue.create(organization.cpo.dcConnectorFee),
    paymentTerminalFee:
      organization.cpo?.paymentTerminalFee === undefined
        ? undefined
        : NumberValue.create(organization.cpo.paymentTerminalFee),
    roamingBeta: organization.cpo?.roamingBeta || false,
    emspBillReference: organization.chargingCustomer?.billReference || '',
    splitBillingBillReference: organization.splitBillingEmployer?.billReference || '',
    installationPartner: organization.installationPartner,

    isSupportValid: false,
    supportInvalidVisible: false,

    vatExempt: organization.cpo?.vatExempt || false,
    vatExemptNote: organization.cpo?.vatExemptNote || ''
  };
}

export enum EditOrganizationTab {
  General,
  Accounting,
  Contracts,
  SelfBilling,
  CPO,
  PublicCharging,
  SplitBilling
}
