import React, {useMemo, useCallback, useEffect, useState} from 'react';

import {SelectInput} from '../../components/inputs/SelectInput';
import PeriodSelector, {Period} from '../../components/PeriodSelector';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType} from '../../models/CardSettings';
import {ChargingStationFeature} from '../../models/ChargingStation';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {
  isChargingParent as isChargingStationParent,
  hasChargingStationFunctionality,
  isEVWallLocation
} from '../../models/Location';
import {ITableField} from '../../models/Table';
import {Interval} from '../../models/UsageValue';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {
  useChargingStationForLocation,
  useOverloadProtectionConfiguration,
  useLoadRetentionPolicy,
  useLocationIdWithGrid
} from '../../utils/FunctionalData';
import {useAutoRefresh} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardTypeKey, CardCategory, ICardType, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {ConsumptionData, fetchChargingConsumption} from './CardData';
import {ChargingGroupCurrentGraph} from './ChargingGroupCurrentGraph';
import {ChargingGroupGraph} from './ChargingGroupGraph';
import {ChargingStationGraph} from './ChargingStationGraph';

import {generateStationColumns, generateStationGroupColumns, generateStationGroupCurrentColumns} from './Columns';
import {ChargingStationConsumptionSettings, ChargingStationConsumptionMode} from './Settings';

function generateTableSettings(
  fields: ITableField<unknown>[],
  settings: IPersistedTableSettings
): IPersistedTableSettings {
  return {
    ...settings,
    columns: fields.map(field => ({name: field.name, visible: true}))
  };
}

function ChargingStationConsumptionCard(props: ICardProps<ChargingStationConsumptionSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {cardType, table, mode} = settings;
  const {from, to, period, interval} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;
  const [chargingStation] = useChargingStationForLocation(fetch, location);
  const isServiceDesk = useUser().isServiceDesk();

  const retentionPromise = useLoadRetentionPolicy(fetch, locationId);

  const [data, setData] = useState<ConsumptionData>();
  const reload = useCallback(
    async (forceRefresh?: boolean) => {
      if (!location) return;

      const data = await fetch(
        'data',
        async api =>
          fetchChargingConsumption(api, retentionPromise, location, period, from, to, interval, forceRefresh),
        plural('electricityValue')
      );
      setData(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location?.id, period, from, to, interval]
  );
  useEffect(() => {
    reload();
  }, [reload]);

  const locationIdWithGrid = useLocationIdWithGrid(fetch, location);
  const [overloadProtectionConfig, refreshOverloadProtection] = useOverloadProtectionConfiguration(
    fetch,
    locationIdWithGrid
  );
  const maximumLoad = overloadProtectionConfig && overloadProtectionConfig.maximumLoad;

  const isChargingStation = location !== undefined && hasChargingStationFunctionality(location);
  const isChargingParent =
    location !== undefined && (isChargingStationParent(location.functionType) || isEVWallLocation(location));
  let error: string | undefined;
  if (!isChargingStation && !isChargingParent) {
    error = T('chargingStationConsumption.invalidLocationType');
  }

  const refresh = () => {
    reload(true);
    refreshOverloadProtection();
  };
  useAutoRefresh(refresh);

  const stationColumns = useMemo(
    () =>
      chargingStation &&
      generateStationColumns(
        locationTimezone || 'UTC',
        chargingStation,
        interval,
        data?.highLevelConfig?.phaseType || PhaseType.Single,
        isServiceDesk
      ),
    [locationTimezone, chargingStation, interval, isServiceDesk, data?.highLevelConfig]
  );
  const stationGroupColumns = useMemo(() => {
    if (!data || data.type !== 'group') return [];

    if (mode === ChargingStationConsumptionMode.Regular) {
      return generateStationGroupColumns(data.period.timezone, data.stations, data.period.interval);
    } else {
      return generateStationGroupCurrentColumns(
        data.period.timezone,
        data.period.interval,
        mode,
        data.stations,
        maximumLoad,
        data.highLevelConfig
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, interval, maximumLoad, mode]);

  let title = T('chargingStationConsumption.title');
  if (data && data.type === 'station' && chargingStation) {
    title = T('chargingStationConsumption.titleOf', {
      name: chargingStation.data.name || ''
    });
  }
  if (data && data.type === 'group' && location) {
    title = T('chargingStationConsumption.titleOf', {
      name: location.name || ''
    });
  }

  const actions: CustomActions = state => (
    <CardActions>
      {state.ready && stationColumns !== undefined && data && data.type === 'station' && (
        <ExportCsv
          fields={stationColumns}
          items={data.stationData}
          name={title}
          settings={generateTableSettings(stationColumns, table)}
        />
      )}
      {state.ready && data && data.type === 'group' && (
        <ExportCsv
          fields={stationGroupColumns}
          items={data.stationsDataRearranged}
          name={title}
          settings={generateTableSettings(stationGroupColumns, table)}
        />
      )}
      <Reload onReload={refresh} />
      <PeriodSelector settings={settings} onChanged={updates => updateSettings(updates)} />
    </CardActions>
  );

  const customSettings: CustomSettings<ChargingStationConsumptionSettings> = (settings, updateSettings) => {
    return (
      <>
        <CardTypeSelector value={settings.cardType} onChange={cardType => updateSettings({cardType})} />
        {isChargingParent && (
          <SelectInput
            value={settings.mode}
            onChange={mode => updateSettings({mode: mode as ChargingStationConsumptionMode})}
          >
            <option value={ChargingStationConsumptionMode.Regular}>{T('chargingStationConsumption.mode.watt')}</option>
            <option value={ChargingStationConsumptionMode.OverloadIncludingConsumption}>
              {T('chargingStationConsumption.mode.ampsInclGrid')}
            </option>
            <option value={ChargingStationConsumptionMode.OverloadExcludingConsumption}>
              {T('chargingStationConsumption.mode.ampsExclGrid')}
            </option>
          </SelectInput>
        )}
      </>
    );
  };

  const updateTableSettings = (tableSettings: IPersistedTableSettings) => {
    tableSettings.columns = [];
    updateSettings({table: tableSettings});
  };

  if (data && data.empty) {
    error = T('generic.noData');
  } else if (data && data.type === 'group' && data.stations.length === 0) {
    error = T('card.error.noChargingStationsAvailable');
  } else if (!isServiceDesk && !chargingStation?.hasFeature(ChargingStationFeature.ApplianceDetail)) {
    error = T('chargingStationConsumption.notSupported');
  }

  return (
    <CardView title={title} error={error} actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      {cardType === CardDisplayType.Chart && chargingStation && data && data.type === 'station' && (
        <ChargingStationGraph
          key="station"
          consumption={data.stationData}
          chargingStation={chargingStation}
          period={data.period}
        />
      )}
      {cardType === CardDisplayType.Table && data && data.type === 'station' && (
        <Table
          key="station"
          items={data.stationData}
          settings={generateTableSettings(stationColumns || [], table)}
          fields={stationColumns || []}
          updateSettings={updateTableSettings}
          noun="electricityValue"
        />
      )}
      {cardType === CardDisplayType.Chart &&
        data &&
        data.type === 'group' &&
        (mode === ChargingStationConsumptionMode.Regular ? (
          <ChargingGroupGraph key="groupWatts" data={data} />
        ) : (
          <ChargingGroupCurrentGraph
            key="groupCurrents"
            mode={settings.mode}
            maximumLoad={maximumLoad}
            highLevelConfig={data.highLevelConfig}
            stations={data.stationsData}
            period={data.period}
            parentConsumption={data.parentConsumption}
          />
        ))}
      {cardType === CardDisplayType.Table && data && data.type === 'group' && (
        <Table
          items={data.stationsDataRearranged}
          settings={generateTableSettings(stationGroupColumns, table)}
          fields={stationGroupColumns}
          updateSettings={updateTableSettings}
          noun="electricityValue"
        />
      )}
    </CardView>
  );
}

const DEFAULT_CARD_SETTINGS: ChargingStationConsumptionSettings = {
  cardType: CardDisplayType.Chart,
  mode: ChargingStationConsumptionMode.Regular,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  table: {
    pageSize: 10,
    columns: []
  }
};
const CARD: ICardType<ChargingStationConsumptionSettings> = {
  type: CardTypeKey.ChargingStationConsumption,
  title: 'chargingStationConsumption.title',
  description: 'chargingStationConsumption.description',
  categories: [CardCategory.ELECTRICITY, CardCategory.EV],
  rights: UserRights.User,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Required,
  cardClass: ChargingStationConsumptionCard
};
export default CARD;
