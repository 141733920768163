import {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {MoveMode} from '../../api/ContractsAPI';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {Info} from '../../components/Info';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Label} from '../../components/ui/label';
import {RadioGroup, RadioGroupItem} from '../../components/ui/radio-group';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract, ContractDetails} from '../../models/Contract';
import {IOrganization} from '../../models/Organization';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {validateSpecialChars} from '../../utils/Validation';

import styles from '../CommonCardStyles.module.scss';

interface MoveContractModalProps extends IPromiseModalProps<boolean> {
  contract: ContractDetails;
}

function checkInput(mode: MoveMode, organization: IOrganization | undefined): string | undefined {
  switch (mode) {
    default:
      return T('contracts.move.error.selectMode');
    case MoveMode.ContractOnly:
      if (organization === undefined) {
        return T('contracts.move.error.selectOrganization');
      } else if (!organization.linkedToOdoo) {
        return T('contracts.move.error.mustHaveOdooPartner');
      } else return undefined;
    case MoveMode.ContractAndPartner:
      if (organization === undefined) {
        return T('contracts.move.error.selectOrganization');
      } else if (organization.linkedToOdoo) {
        return T('contracts.move.error.mustNotHaveOdooPartner');
      } else return undefined;
    case MoveMode.NewOrganization:
      return undefined;
  }
}

function getMoveMode(value: string): MoveMode {
  switch (value) {
    case 'contractOnly':
      return MoveMode.ContractOnly;
    case 'contractAndPartner':
      return MoveMode.ContractAndPartner;
    case 'newOrganization':
      return MoveMode.NewOrganization;
    default:
      return MoveMode.Choose;
  }
}

export function MoveContractModal(props: MoveContractModalProps) {
  const {contract} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [mode, setMode] = useState<MoveMode>(MoveMode.Choose);
  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState<IOrganization>();

  const [name, setName] = useState<string>('');
  const [vat, setVAT] = useState<string>('');
  const [error, setError] = useState<string>();

  const handleSave = () => {
    return api.contracts
      .move(contract.id, {
        mode,
        organizationId: organization?.id,
        companyName: name,
        companyVat: vat
      })
      .then(() => {
        NotificationManager.success(T('contracts.move.success'));
        resolve(true);
        return undefined;
      })
      .catch(e => {
        setError(translateError(e, T('contracts.move.error')));
        return undefined;
      });
  };

  const inputError = checkInput(mode, organization);

  const handleModeChange = (value: string) => {
    if (value?.length) {
      setMode(getMoveMode(value));
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      title={T('contracts.move.title')}
      onToggle={() => resolve(false)}
      action={handleSave}
      error={error || inputError}
      disabled={inputError !== undefined}
    >
      {contract.contract === Contract.eMSPPersonal && (
        <p style={{color: 'gray', marginTop: '1rem'}}>{T('contracts.move.emspBecomesBusiness')}</p>
      )}
      <Label className="tw-font-bold">{T('contracts.move.mode.label')}</Label>
      <RadioGroup defaultValue={mode} onValueChange={handleModeChange}>
        <div className={styles.radioWrapper}>
          <RadioGroupItem
            id="contract_only"
            value={MoveMode.ContractOnly}
            checked={mode === MoveMode.ContractOnly}
            className={styles.radio}
          />
          <Label className={styles.radioLabel} htmlFor="contract_only">
            {T('contracts.move.mode.contractOnly')}
          </Label>
        </div>
        <div className={styles.radioWrapper}>
          <RadioGroupItem
            id="contract_and_partner"
            value={MoveMode.ContractAndPartner}
            checked={mode === MoveMode.ContractAndPartner}
            className={styles.radio}
          />
          <Label className={styles.radioLabel} htmlFor="contract_and_partner">
            {T('contracts.move.mode.contractAndPartner')}
          </Label>
        </div>
        <div className={styles.radioWrapper}>
          <RadioGroupItem
            id="new_organization"
            value={MoveMode.NewOrganization}
            checked={mode === MoveMode.NewOrganization}
            className={styles.radio}
          />
          <Label className={styles.radioLabel} htmlFor="new_organization">
            {T('contracts.move.mode.newOrganization')}
          </Label>
        </div>
      </RadioGroup>
      {mode === MoveMode.ContractOnly && <Info>{T('contracts.move.mode.contractOnly.info')}</Info>}
      {mode === MoveMode.ContractAndPartner && <Info>{T('contracts.move.mode.contractAndPartner.info')}</Info>}
      {mode === MoveMode.NewOrganization && <Info>{T('contracts.move.mode.newOrganization.info')}</Info>}
      {(mode === MoveMode.ContractOnly || mode === MoveMode.ContractAndPartner) && (
        <OrganizationInputGroup
          name="organization"
          label={T('contracts.move.organization.label')}
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={setOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      {(mode === MoveMode.NewOrganization ||
        (mode === MoveMode.ContractAndPartner && contract.contract === Contract.eMSPPersonal)) && (
        <>
          <TextInputGroup
            name="name"
            label={T('contracts.move.organization.name')}
            value={name}
            onChange={setName}
            validate={validateSpecialChars}
          />
          <TextInputGroup
            name="vat"
            label={T('contracts.move.organization.vat')}
            value={vat}
            onChange={setVAT}
            validate={validateSpecialChars}
          />
        </>
      )}
      {error && <Info>{error}</Info>}
    </SingleActionModal>
  );
}
