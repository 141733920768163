import React from 'react';

import {Provider} from 'react-redux';

import {AuthProviders} from '../context/AuthProviders';

import {IAppContext, ContextProvider} from './context';

interface IAppProps {
  context: IAppContext;
  children: JSX.Element | null;
}

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
export default function App(props: IAppProps) {
  const {children, context} = props;
  return (
    <Provider store={context.store}>
      <ContextProvider value={context}>
        <AuthProviders>{children}</AuthProviders>
      </ContextProvider>
    </Provider>
  );
}
