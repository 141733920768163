import React from 'react';

import {useAppContext} from '../../../app/context';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {useModals} from '../../../modals/ModalContext';
import {IActivationCode, IOrganizationActivationCode} from '../../../models/ActivationCode';
import {ILocationSummary, ILocation} from '../../../models/Location';
import {T} from '../../../utils/Internationalization';
import {SetActivationCodePromiseModal, SetActivationCodeResult} from '../../Locations/SetActivationCodePromiseModal';

interface ActivationCodeFieldProps {
  location: ILocationSummary | ILocation | undefined;
  value: IActivationCode | undefined;
  update: (value: IOrganizationActivationCode, withDevice: boolean) => Promise<unknown>;
  readOnly: boolean;
  displayedValue?: string;
  isDevice: boolean;
}

export const ActivationCodeField = (props: ActivationCodeFieldProps) => {
  const {location, value, update, readOnly, isDevice, displayedValue} = props;
  const {api} = useAppContext();

  const modals = useModals();
  const handleClickedEdit = async () => {
    const organization =
      value &&
      (value.organizationId === undefined
        ? undefined
        : await api.organizations.getById(value.subOrganizationId || value.organizationId));
    const updates = await modals.show<SetActivationCodeResult | undefined>(props => (
      <SetActivationCodePromiseModal
        organization={organization}
        activationCode={value}
        location={location}
        content={location ? location.name || 'location' : 'device'}
        withMoveDevice={!isDevice}
        {...props}
      />
    ));
    if (!updates) return;

    const {activationCode, withDevice} = updates;
    update(activationCode, withDevice);
  };

  let displayValue = displayedValue || (value === undefined ? T.generic.na() : value.name || value.code);
  let reusable = value && value.reusable;
  if (reusable === false && !readOnly) {
    displayValue += ` (${T('locationConfiguration.activationCode.nonreusable')})`;
  }

  return (
    <span>
      {displayValue}{' '}
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
          <Edit />
        </Button>
      )}
    </span>
  );
};
