import {SeriesColumnOptions, SeriesLineOptions} from 'highcharts';
import React, {useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {createIntervalChart, processIntervalData} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {useChargingStationForLocation} from '../../components/inputs/LocationInput';
import PeriodSelector, {
  ActivePeriod,
  getPeriodRangeForTimezone,
  Period,
  PeriodRoundingMode,
  PeriodSettings
} from '../../components/PeriodSelector';
import Table from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {IChargingStationInternalCondition} from '../../models/ChargingStationInternalCondition';
import {ITableField, NumberField, TimestampFieldWithTimezone} from '../../models/Table';
import {Interval, showAsLineGraph} from '../../models/UsageValue';
import {BrandColors} from '../../utils/BrandColors';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';

interface LoadedData {
  period: ActivePeriod;
  data: IChargingStationInternalCondition[];
}

interface ChargingStationTemperatureSettings extends ICardSettingsWithTable {
  cardType: CardDisplayType;
  period: PeriodSettings;
}

const allowedIntervals: Interval[] = [Interval.MINUTES_5, Interval.HOUR, Interval.DAY, Interval.MONTH, Interval.YEAR];

function ChargingStationTemperatureCard(props: ICardProps<ChargingStationTemperatureSettings>) {
  const {settings, updateSettings} = props;

  const {api} = useAppContext();
  const location = useCardLocation(settings);
  const [chargingStation] = useChargingStationForLocation(api, location);
  const chargingStationSerial = chargingStation && chargingStation.data.serialNumber;
  const locationTimezone = location && location.timeZoneId;

  const [data, refreshData] = useCardLoader<LoadedData | undefined>(
    api => {
      if (locationTimezone === undefined || chargingStationSerial === undefined) {
        return Promise.resolve(undefined);
      }

      const activePeriod = getPeriodRangeForTimezone(
        settings.period,
        locationTimezone,
        undefined,
        PeriodRoundingMode.EXCLUSIVE
      );
      return api
        .getChargingStationInternalConditions(
          chargingStationSerial,
          activePeriod.from,
          activePeriod.to,
          activePeriod.interval
        )
        .then(data => ({period: activePeriod, data}));
    },
    [chargingStationSerial, settings.period, locationTimezone],
    plural('temperatureValue'),
    undefined
  );

  const hasMultipleChargers = chargingStation !== undefined && chargingStation.getControllers().length > 1;

  const columns = useMemo(() => {
    const hasHumidity = data === undefined ? false : data.data.some(x => x.humidity !== undefined);
    const hasTemperature1 = data === undefined ? false : data.data.some(x => x.temperature1 !== undefined);
    const hasTemperature2 = data === undefined ? false : data.data.some(x => x.temperature2 !== undefined);

    const result: ITableField<IChargingStationInternalCondition>[] = [];
    result.push(
      new TimestampFieldWithTimezone(
        'timestamp',
        'timestamp',
        T('electricityValues.field.timestamp'),
        locationTimezone || 'UTC'
      )
    );
    if (hasHumidity) {
      const humidityLabel = T('chargingStationTemperature.humidity');
      result.push(
        new NumberField('humidity', humidityLabel, {
          unit: '%',
          digitsAfterComma: 0
        })
      );
    }
    if (hasTemperature1) {
      const temperature1Label = hasMultipleChargers
        ? T('chargingStationTemperature.temperatureX', {index: '1'})
        : T('chargingStationTemperature.temperature');
      result.push(
        new NumberField('temperature1', temperature1Label, {
          unit: '°C',
          digitsAfterComma: 0
        })
      );
    }
    if (hasTemperature2) {
      const temperature2Label = hasMultipleChargers
        ? T('chargingStationTemperature.temperatureX', {index: '2'})
        : T('chargingStationTemperature.temperature');
      result.push(
        new NumberField('temperature2', temperature2Label, {
          unit: '°C',
          digitsAfterComma: 0
        })
      );
    }
    return result;
  }, [data, locationTimezone, hasMultipleChargers]);

  const series: (SeriesColumnOptions | SeriesLineOptions)[] = [];
  const humidityColumn = columns.find(c => c.name === 'humidity');
  const temperature1Column = columns.find(c => c.name === 'temperature1');
  const temperature2Column = columns.find(c => c.name === 'temperature2');

  if (data && humidityColumn) {
    series.push({
      id: 'humidity',
      name: `${humidityColumn.label} [%]`,
      type: showAsLineGraph(data.period.interval) ? 'line' : 'column',
      data: processIntervalData(
        data.data.map(item => [item.timestamp, item.humidity]),
        data.period
      ),
      color: BrandColors.Blue,
      marker: {enabled: false},
      tooltip: {
        pointFormat: `<span style="color:{point.color}">\u25CF</span> ${humidityColumn.label}: <b>{point.y} %</b><br/>`
      },
      yAxis: 0
    } as SeriesLineOptions | SeriesColumnOptions);
  }
  if (data && temperature1Column) {
    series.push({
      id: 'temperature1',
      name: `${temperature1Column.label} [°C]`,
      type: showAsLineGraph(data.period.interval) ? 'line' : 'column',
      data: data.data.map(item => [item.timestamp, item.temperature1]),
      color: BrandColors.Red,
      marker: {enabled: false},
      tooltip: {
        pointFormat: `<span style="color:{point.color}">\u25CF</span> ${temperature1Column.label}: <b>{point.y} °C</b><br/>`
      },
      yAxis: 1
    } as SeriesLineOptions | SeriesColumnOptions);
  }
  if (data && temperature2Column) {
    series.push({
      id: 'temperature2',
      name: `${temperature2Column.label} [°C]`,
      type: showAsLineGraph(data.period.interval) ? 'line' : 'column',
      data: processIntervalData(
        data.data.map(item => [item.timestamp, item.temperature2]),
        data.period
      ),
      color: '#DA6262',
      marker: {enabled: false},
      tooltip: {
        pointFormat: `<span style="color:{point.color}">\u25CF</span> ${temperature2Column.label}: <b>{point.y} °C</b><br/>`
      },
      yAxis: 1
    } as SeriesLineOptions | SeriesColumnOptions);
  }

  const [config, actualFrom, actualTo] = data
    ? createIntervalChart({
        period: data.period,
        series,
        yAxis: [
          {
            title: {text: '%'},
            opposite: false
          },
          {
            title: {text: '°C'},
            opposite: true
          }
        ],
        navigatorSeries: ['temperature1', 'temperature2']
      })
    : [];

  const actions: CustomActions = state => (
    <CardActions>
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        allowedIntervals={allowedIntervals}
      />
      <Reload onReload={refreshData} />
      {state.ready && data && (
        <ExportCsv fields={columns} items={data.data} settings={settings.table} name="temperatures" />
      )}
    </CardActions>
  );

  const customSettings: CustomSettings<ChargingStationTemperatureSettings> = (settings, updateSettings) => {
    const cardType = settings.cardType;

    const handleCardTypeChanged = (cardType: CardDisplayType) => {
      updateSettings({cardType});
    };

    return (
      <div>
        <CardTypeSelector value={cardType} onChange={handleCardTypeChanged} />
      </div>
    );
  };

  let error: string | undefined;
  if (chargingStation === undefined) {
    error = T('card.error.noChargingStationSelected');
  } else if (data && data.data.length === 0) {
    error = T('chargingStationTemperature.noData');
  }

  return (
    <CardView error={error} actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      {settings.cardType === CardDisplayType.Chart && data && config && (
        <HighStockChart config={config} from={actualFrom} to={actualTo} />
      )}
      {settings.cardType === CardDisplayType.Table && data && (
        <Table
          fields={columns}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
          items={data.data}
        />
      )}
    </CardView>
  );
}

const DEFAULT_CARD_SETTINGS: ChargingStationTemperatureSettings = {
  cardType: CardDisplayType.Chart,
  period: {
    period: Period.DAYS_7,
    interval: Interval.INTERVAL
  },
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'temperature1', visible: true},
      {name: 'temperature2', visible: true},
      {name: 'humidity', visible: true}
    ]
  }
};
const CARD: ICardType<ChargingStationTemperatureSettings> = {
  type: CardTypeKey.ChargingStationTemperature,
  title: 'chargingStationTemperature.title',
  description: 'chargingStationTemperature.description',
  categories: [CardCategory.ELECTRICITY, CardCategory.EV],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.RequiresChargingStation,
  cardClass: ChargingStationTemperatureCard
};
export default CARD;
