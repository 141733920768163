import React from 'react';

import {useAppContext} from '../../app/context';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {setContextLocationId} from '../../redux/actions/location';
import {BrandColors} from '../../utils/BrandColors';
import {useChargingStations} from '../../utils/FunctionalData';
import {CardCategory, CardTypeKey, ICardType, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardChargingStationGroup, useCardLocation} from '../CardUtils';
import {LocationGoogleMap} from '../Locations/LocationGoogleMap';
import {MiniCard} from '../MiniCard';

import styles from './index.module.scss';

type ILocationMapSettings = ICardSettings;
const LocationMapMiniCard = (props: ICardProps<ILocationMapSettings>) => {
  const {fetch, settings} = props;
  const {api, store} = useAppContext();
  const location = useCardLocation(settings);

  const chargingStationGroup = useCardChargingStationGroup(settings);
  const [stations] = useChargingStations(fetch, chargingStationGroup && chargingStationGroup.id);
  return (
    <MiniCard
      backgroundColor="#fff"
      borderColor={BrandColors.Pumice}
      settingsHandleClass={styles.settingsHandle}
      {...props}
    >
      {location && (
        <LocationGoogleMap
          locations={[location]}
          chargingStations={stations}
          currentLocation={location}
          zoom={chargingStationGroup ? 17 : 10}
          center={{
            lat: location.latitude || 50.822937,
            lng: location.longitude || 3.311227
          }}
          mapContainerStyle={{height: '100%'}}
          onClickedChargingStation={station =>
            station.serviceLocationId !== undefined && setContextLocationId(store, api, station.serviceLocationId)
          }
        />
      )}
    </MiniCard>
  );
};

const CARD: ICardType<ILocationMapSettings> = {
  type: CardTypeKey.LocationMapMini,
  title: 'locationMap.title',
  description: 'locationMap.description',
  categories: [CardCategory.LOCATIONS],
  rights: UserRights.User,
  width: 1,
  height: 1,
  minWidth: 1,
  minHeight: 1,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Required,
  cardClass: LocationMapMiniCard
};
export default CARD;
