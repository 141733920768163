import numeral from 'numeral';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {Chart} from '../../components/Chart';
import {SMAPPEE_FONT_ICON_MAP} from '../../components/Icon';
import {useCardContext} from '../CardContext';

import styles from './Summary.module.scss';

function getGaugeConfig(value: number | undefined, reference: number): any {
  const radius = Math.min(0.55, 0.2 + (50.0 * Math.sqrt(Math.abs(value || 0.0))) / reference);
  const outerRadiusPercent = `${(2 * 100 * radius).toFixed(1)}%`;
  const innerRadiusPercent = `${(2 * 90 * radius).toFixed(1)}%`;

  return {
    credits: false,
    chart: {
      type: 'solidgauge'
    },
    title: false,
    tooltip: {
      enabled: false
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          outerRadius: outerRadiusPercent,
          innerRadius: innerRadiusPercent,
          borderWidth: 0
        }
      ]
    },
    yAxis: {
      min: 0,
      max: 1,
      lineWidth: 0,
      tickPositions: []
    },
    plotOptions: {
      solidgauge: {
        animation: {
          duration: 5000
        },
        dataLabels: {
          enabled: true,
          y: -60,
          borderWidth: 0,
          useHTML: true,
          shadow: false
        }
      }
    },
    series: [
      {
        animation: false,
        name: ' ',
        data: [
          {
            radius: outerRadiusPercent,
            innerRadius: innerRadiusPercent,
            y: value
          }
        ]
      }
    ]
  };
}

interface GaugeProps {
  value: number | undefined;
  unit: string;
  backgroundColor: string;
  color: string;
  iconClassName: string;
  icon: string;
  bigConsumer: boolean;
}
export const Gauge = (props: GaugeProps) => {
  const {value, unit, backgroundColor, color, iconClassName, icon, bigConsumer} = props;
  const {cardDimensions} = useCardContext();

  // Construct config
  const reference = bigConsumer ? 1000 : 10000;
  const config = getGaugeConfig(value, reference);
  const radius = (50.0 * Math.sqrt(Math.abs(value || 0.0))) / reference;
  const fontSize = Math.min(48, 16 + radius * 43);
  //const iconSize = Math.min(36, 12 + radius * 30);

  // Apply specific consumption configuration
  config.pane.background[0].backgroundColor = backgroundColor;
  config.series[0].data[0].color = color;

  // Get the corresponding SVG component
  const getSvgMarkup = (iconClassName: string): string => {
    // const defaultViewBox = '0 0 24 24';
    const IconComponent = SMAPPEE_FONT_ICON_MAP[iconClassName];
    let iconMarkup = ReactDOMServer.renderToStaticMarkup(
      // We can not use variable fontSize anymore with fixed sized Svg icons for now
      // because the viewBox attribute is stripped in HighCharts
      <IconComponent width={24} height={24} />
    );
    return iconMarkup;
  };
  let iconMarkup = getSvgMarkup(icon);

  config.plotOptions.solidgauge.dataLabels.formatter = function () {
    const label = typeof this.y === 'number' ? numeral(this.y).format(bigConsumer ? '0.0' : '0') : '---';
    return (
      `<div class="${styles.labelWrapper}">` +
      `<div class="${iconClassName}"><span>${iconMarkup}</span></div>` +
      `<span class="${styles.gaugeValue}" style="font-size: ${fontSize}px; line-height: ${fontSize}px;">${label}&nbsp;${unit}</span>` +
      `</div>`
    );
  };
  config.chart.height = 300;

  return (
    <div className={styles.gaugeInnerContainer}>
      <Chart className={styles.gauge} config={config} isHighstock={false} cardDimensions={cardDimensions} />
    </div>
  );
};
