import {Col, Row} from '../../../components/bootstrap';
import {NumberInputGroup, NumberValue} from '../../../components/inputs/NumberInput';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Checkbox} from '../../../components/ui/checkbox';
import {AppFeature, hasFeature} from '../../../utils/AppParameters';
import {FormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';
import {validateSpecialChars} from '../../../utils/Validation';

import {useUser} from '../../CardUtils';

import {EditOrganizationFormState} from './EditOrganizationModels';

interface EditOrganizationCPOTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
}

export default function EditOrganizationCPOTab(props: EditOrganizationCPOTabProps) {
  const {state, updateState} = props;
  const me = useUser();

  return (
    <div style={{marginTop: '0.5rem'}}>
      <Row>
        <Col xl={4}>
          <TextInputGroup
            name="cpoBillReference"
            label={T('organizations.tab.cpo.billReference')}
            info={T('organizations.tab.cpo.billReference.info')}
            value={state.cpoBillReference}
            onChange={cpoBillReference => updateState({cpoBillReference})}
            validate={validateSpecialChars}
          />
          {me.isServiceDesk() && (
            <>
              <Checkbox
                id="cpoSpecificFee"
                name="cpoSpecificFee"
                label={T('organizations.tab.cpo.specificFeePercentage')}
                checked={state.cpoFeePercentage !== undefined}
                onCheckedChange={checked => updateState({cpoFeePercentage: checked ? NumberValue.none() : undefined})}
                testId="cpoSpecificFee"
                wrapperClassName="!tw-mb-4"
              />
              {state.cpoFeePercentage && (
                <NumberInputGroup
                  name="cpoFeePercentage"
                  label={T('organizations.tab.cpo.specificFeePercentage.label')}
                  info={T('organizations.tab.cpo.specificFeePercentage.info')}
                  value={state.cpoFeePercentage}
                  min={0}
                  max={50}
                  onChange={cpoFeePercentage => updateState({cpoFeePercentage})}
                />
              )}
              <Checkbox
                name="cpoSpecificConnectorFee"
                label={T('organizations.tab.cpo.specificConnectorFee')}
                checked={state.acConnectorFee !== undefined || state.dcConnectorFee !== undefined}
                onCheckedChange={checked =>
                  updateState({
                    acConnectorFee: checked ? NumberValue.none() : undefined,
                    dcConnectorFee: checked ? NumberValue.none() : undefined
                  })
                }
                testId="cpoSpecificConnectorFee"
                wrapperClassName="!tw-mb-4"
              />
              {state.acConnectorFee && (
                <NumberInputGroup
                  name="acConnectorFee"
                  label={T('organizations.tab.cpo.specificConnectorFee.ac.label')}
                  info={T('organizations.tab.cpo.specificConnectorFee.ac.info')}
                  value={state.acConnectorFee}
                  min={0}
                  onChange={acConnectorFee => updateState({acConnectorFee})}
                />
              )}
              {state.dcConnectorFee && (
                <NumberInputGroup
                  name="dcConnectorFee"
                  label={T('organizations.tab.cpo.specificConnectorFee.dc.label')}
                  info={T('organizations.tab.cpo.specificConnectorFee.dc.info')}
                  value={state.dcConnectorFee}
                  min={0}
                  onChange={dcConnectorFee => updateState({dcConnectorFee})}
                />
              )}
              {hasFeature(AppFeature.PaymentTerminals) && (
                <Checkbox
                  id="cpoSpecificPaymentTerminalFee"
                  name="cpoSpecificPaymentTerminalFee"
                  label={T('organizations.tab.cpo.specificPaymentTerminalFee')}
                  checked={state.paymentTerminalFee !== undefined}
                  onCheckedChange={checked =>
                    updateState({
                      paymentTerminalFee: checked ? NumberValue.none() : undefined
                    })
                  }
                  testId="cpoSpecificPaymentTerminalFee"
                  wrapperClassName="!tw-mb-4"
                />
              )}
              {state.paymentTerminalFee && (
                <NumberInputGroup
                  name="paymentTerminalFee"
                  label={T('organizations.tab.cpo.specificPaymentTerminalFee.label')}
                  info={T('organizations.tab.cpo.specificPaymentTerminalFee.info')}
                  value={state.paymentTerminalFee}
                  min={0}
                  onChange={paymentTerminalFee => updateState({paymentTerminalFee})}
                />
              )}
            </>
          )}
        </Col>
        <Col xl={4}>
          {me.isServiceDesk() && (
            <Checkbox
              id="roamingBeta"
              name="roamingBeta"
              label={T('organizations.tab.cpo.roamingBeta')}
              info={T('organizations.tab.cpo.roamingBeta.info')}
              checked={state.roamingBeta}
              onCheckedChange={roamingBeta => updateState({roamingBeta})}
              testId="roamingBeta"
              wrapperClassName="!tw-mb-4"
            />
          )}
          {(state.vatExempt || state.address.countryCode === 'BE') && (
            <Checkbox
              id="vatExempt"
              name="vatExempt"
              label={T('organization.tab.cpo.vatExempt')}
              checked={state.vatExempt}
              onCheckedChange={vatExempt => updateState({vatExempt})}
              testId="vatExempt"
              wrapperClassName="!tw-mb-4"
              wrap
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
