import {useCallback, useRef, useState, useEffect, DependencyList, useMemo} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {Dispatch} from 'redux';

import {useAppContext} from '../app/context';
import {useCardContext} from '../cards/CardContext';
import API from '../core/api';
import {AnyAppAction} from '../redux/actions';
import {IAppState} from '../redux/AppState';

import {IFetchingItem} from './Fetcher';

export function useFieldUpdater(setter: (value: string) => void) {
  return useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setter(e.currentTarget.value);
    },
    [setter]
  );
}

export function useUpdatedRef<T>(value: T) {
  const result = useRef(value);
  result.current = value;
  return result;
}

export function useRefCallback<T extends (...args: any[]) => any>(value: T) {
  const result = useRef(value);
  result.current = value;
  return useCallback((...args: any[]) => result.current(...args), []);
}

export function usePromiseResult<T>(promise: Promise<T>): T | undefined {
  const [result, setResult] = useState<T>();
  useMemo(() => promise.then(setResult), [promise]);
  return result;
}

export enum ModalState {
  GONE,
  CLOSED,
  OPENED
}

function toggleModalState(state: ModalState) {
  return state === ModalState.OPENED ? ModalState.CLOSED : ModalState.OPENED;
}

export function useModalToggle(): [boolean, boolean, () => void, (state: ModalState) => void] {
  const [state, setState] = useState(ModalState.GONE);
  const toggle = () => setState(toggleModalState(state));
  return [state !== ModalState.GONE, state === ModalState.OPENED, toggle, setState];
}

export function useDelayedEffect(callback: React.EffectCallback, dependencies: React.DependencyList, delay: number) {
  const loading = useRef(false);
  useEffect(() => {
    loading.current = true;
    const timeout = setTimeout(() => {
      loading.current = false;
      callback();
    }, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return loading.current;
}

export function useThrottledEffect(callback: React.EffectCallback, dependencies: React.DependencyList, delay: number) {
  const active = useRef(true);
  return useEffect(() => {
    const immediate = active.current;
    active.current = false;
    if (immediate) callback();

    const timeout = setTimeout(() => {
      if (!immediate) callback();
      active.current = true;
    }, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useAppSelector<T>(selector: (state: IAppState) => T) {
  return useSelector<IAppState, T>(selector);
}

export function useAppDispatch() {
  return useDispatch<Dispatch<AnyAppAction>>();
}

let idCounter = 0;

export function useCardLoader<T>(
  loader: (api: API, force: boolean) => Promise<T | undefined>,
  dependencies: DependencyList,
  name: string,
  initialValue: T
): [T, () => void, (data: T) => void] {
  const [result, setResult] = useState<T>(initialValue);
  const context = useCardContext();
  const {api} = useAppContext();
  const id = useMemo(() => `load${idCounter++}`, []);

  const load = (force: boolean) => {
    const localAPI = api.withAbort(context.abortFetching);
    context.onLoadStarted(id, context.abortFetching, name);
    loader(localAPI, force)
      .then(result => {
        setResult(result === undefined ? initialValue : result);
        context.onLoadFinished(id);
      })
      .catch(() => {
        context.onLoadErrored(id);
      });
    return () => context.onLoadCanceled(id);
  };
  const loadRef = useRef(load);
  loadRef.current = load;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadRef.current(false), dependencies);

  const refresh = useCallback(() => loadRef.current(true), []);
  return [result, refresh, setResult];
}

export function useLoader<T>(
  loader: (api: API, force?: boolean) => Promise<T | undefined>,
  dependencies: DependencyList
): [T | undefined, (force?: boolean) => void] {
  const [result, setResult] = useState<T>();
  const {api} = useAppContext();
  const refresh = useCallback((force?: boolean) => {
    loader(api, force).then(setResult);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(refresh, [refresh]);
  return [result, refresh];
}

// Will automatically refresh data if in presentation mode
export function useAutoRefresh(refresh: () => void) {
  const presenting = useAppSelector(state => state.uiState.presenting);
  const ref = useRef(refresh);
  ref.current = refresh;

  useEffect(() => {
    if (!presenting) return;

    const interval = setInterval(() => ref.current(), 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [presenting]);
}

export function useDelayedLoader<T>(
  callback: () => Promise<T>,
  dependencies: React.DependencyList,
  delay: number,
  name: string
): [T | undefined, IFetchingItem, () => void] {
  const lastCallback = useRef<() => Promise<T>>();
  lastCallback.current = callback;

  const [value, setValue] = useState<[T | undefined, IFetchingItem]>([undefined, {key: '', name, loading: false}]);
  const refresh = () => callback().then(result => setValue([result, {key: '', name, loading: false}]));

  useEffect(() => {
    setValue(value => [value[0], {key: '', name, loading: true}]);
    const timeout = setTimeout(async () => {
      lastCallback.current!().then(result => {
        setValue([result, {key: '', name, loading: false}]);
      });
    }, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return [...value, refresh];
}

export function useThrottled<T>(value: T, delay: number) {
  const [throttled, setThrottled] = useState(value);
  const nextValue = useRef<T>();

  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timeout.current !== undefined) {
      nextValue.current = value;
      return;
    }

    setThrottled(value);
    timeout.current = setTimeout(() => {
      if (nextValue.current) {
        setThrottled(nextValue.current);
        nextValue.current = undefined;
      }
      timeout.current = undefined;
    }, delay);
  }, [value, delay]);
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    },
    []
  );

  return throttled;
}

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
