import {ChargingStationSummary, IChargingSession} from '../models/ChargingStation';
import {Contract} from '../models/Contract';
import {Address} from '../models/Countries';
import {ILocationSummary} from '../models/Location';
import {SSOLink, UserDetails, UserType} from '../models/User';

import {APIClient} from './APIClient';

export class UserAPICache {
  userLocations?: Promise<ILocationSummary[]>;
}

export class UserAPI {
  private client: APIClient;
  private cache: UserAPICache;

  constructor(client: APIClient, cache: UserAPICache) {
    this.client = client;
    this.cache = cache;
  }

  validateRegistration(uuid: string) {
    const url = `/api/v10/user/validateregistration/${uuid}`;
    return this.client.doGet(url);
  }

  completePasswordReset(password: string, code: string) {
    const url = `/api/v10/user/password/resetWithCode`;
    return this.client.doPost(url, {
      code,
      password,
      passwordVerification: password
    });
  }

  userPasswordResetSSO(emailAddress: string, sso: boolean) {
    const url = `/api/v10/user/password/reset`;
    return this.client.doPost(url, {
      emailAddress,
      sso
    });
  }

  updatePassword(password: string) {
    const url = `/api/v7/user/password`;
    return this.client.doPut(url, {password});
  }

  getLocations(refresh?: boolean): Promise<ILocationSummary[]> {
    if (!this.cache.userLocations || refresh) {
      const url = `/api/v10/user/servicelocations?fullDetails=true`;
      this.cache.userLocations = this.client.doGet(url);
    }

    return this.cache.userLocations;
  }

  getChargingStations(area?: LatLonArea): Promise<ChargingStationSummary[]> {
    let url = `/api/v10/user/chargingstations`;
    if (area) {
      url += `?area=${area.maxLat},${area.maxLon},${area.minLat},${area.minLon}`;
    }
    return this.client.doGet(url);
  }

  getChargingSessions(from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/user/chargingsessions?range=${from},${to}`;
    return this.client.doGet(url);
  }

  getUserChargingSessions(userId: number, from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/users/${userId}/sessions?range=${from},${to}&rangeMode=stop_or_start`;
    return this.client.doGet(url);
  }

  completeRegistration(request: CompleteRegistrationRequest): Promise<void> {
    const url = `/api/v10/user`;
    return this.client.doPost(url, request);
  }

  delete(): Promise<void> {
    const url = `/api/v10/user`;
    return this.client.doDelete(url);
  }

  register(request: RegisterUserRequest): Promise<void> {
    const url = `/api/v10/user`;
    return this.client.doPost(url, request);
  }

  verifyEmailAddress(emailAddress: string): Promise<void> {
    const url = `/api/v10/user/email/verify`;
    return this.client.doPost(url, {emailAddress});
  }

  activateSubscription(request: ActivateSubscriptionRequest): Promise<unknown> {
    const url = '/api/v10/user/activate-subscription';
    return this.client.doPost(url, request);
  }

  getDashboard(): Promise<unknown> {
    const url = '/api/v10/user/dashboard';
    return this.client.doGet(url);
  }

  sendTestMail(): Promise<unknown> {
    const url = `/api/v10/user/testmail`;
    return this.client.doPost(url, {});
  }

  getDetails(id: number): Promise<UserDetails> {
    const url = `/api/v10/users/${id}/details`;
    return this.client.doGet(url);
  }

  getSSOLinks(): Promise<SSOLink[]> {
    const url = '/api/v10/login/sso/links';

    return this.client.doGet(url.toString());
  }

  disconnectSSO(provider: 'google' | 'microsoft' | 'apple' | 'smappee') {
    return this.client.doDelete(`/api/v10/login/sso/links/${provider}`, {});
  }

  connectGoogle(idToken: string) {
    return this.client.doPost('/api/v10/login/sso/links/google', {
      idToken
    });
  }

  connectMicrosoft(idToken: string) {
    return this.client.doPost('/api/v10/login/sso/links/microsoft', {
      idToken
    });
  }

  connectApple(idToken: string) {
    return this.client.doPost('/api/v10/login/sso/links/apple', {
      idToken
    });
  }
}

export interface CompleteRegistrationRequest {
  firstName: string;
  lastName?: string;
  userName: string;
  password: string;
  emailAddress: string;
  registrationId?: string;
}

export interface RegisterUserRequest {
  firstName: string;
  lastName: string | undefined;
  userName: string;
  password: string;
  emailAddress: string;
  country: string;
  type?: UserType;
  organization?: number;

  apiAccess?: {
    appName: string;
    clientId: string;
    clientSecret: string;
  };
  billing?: {
    language: string;
    lastName: string;
    address: Address;
    accountHolder: string;
    bic: string;
    iban: string;
  };
  contracts: Contract[];
  publicChargingCard?: {
    request: boolean;
    value?: string;
  };
}

export interface ActivateSubscriptionRequest {
  billing?: {
    language: string;
    lastName: string;
    address: Address;
    accountHolder: string;
    bic: string;
    iban: string;
  };
  contracts: Contract[];
  publicChargingCard?: {
    request: boolean;
    value?: string;
  };
}

export interface LatLonArea {
  maxLat: number; // north
  maxLon: number; // east
  minLat: number; // south
  minLon: number; // west
}
