import type {SVGProps} from 'react';
const SvgWarningTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fillRule="evenodd"
    {...props}
  >
    <path d="M17.253 2.75a1.5 1.5 0 0 0-2.599 0L1.98 24.702a1.5 1.5 0 0 0 1.3 2.25h25.35a1.5 1.5 0 0 0 1.299-2.25zM14.68 18.317v-7.636h2.505v7.636zm0 5.091v-2.545h2.505v2.545z" />
  </svg>
);
export default SvgWarningTriangle;
