import {Col, Row} from '../../../components/bootstrap';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {FormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';
import {validateSpecialChars} from '../../../utils/Validation';

import {EditOrganizationFormState} from './EditOrganizationModels';

interface EditOrganizationSplitBillingTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
}

export default function EditOrganizationSplitBillingTab(props: EditOrganizationSplitBillingTabProps) {
  const {state, updateState} = props;

  return (
    <div style={{marginTop: '0.5rem'}}>
      <Row>
        <Col xl={4}>
          <TextInputGroup
            name="splitBillingBillReference"
            label={T('organizations.tab.splitBilling.billReference')}
            info={T('organizations.tab.splitBilling.billReference.info')}
            value={state.splitBillingBillReference}
            onChange={splitBillingBillReference => updateState({splitBillingBillReference})}
            validate={validateSpecialChars}
          />
        </Col>
      </Row>
    </div>
  );
}
