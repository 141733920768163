import React, {useEffect, useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {PopoverBody} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';

import {useAppContext} from '../../app/context';
import positionsImage from '../../assets/evbase-sides.png';
import {Input, SingleActionModal} from '../../components/bootstrap';
import {StickyPopover} from '../../components/bootstrap/StickyPopover';
import FormInputGroup from '../../components/inputs/FormInputGroup';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Button} from '../../components/ui/button';
import InfoCircle from '../../components/ui-lib/legacy-icons/InfoCircle';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {DeviceType} from '../../models/DeviceType';
import {
  getChargingStationModuleLabel,
  postProcessModuleSerial,
  validateChargingStationModuleSerial
} from '../CreateChargingStations/ModuleInput';

interface ModalProps extends IPromiseModalProps<boolean> {
  chargingStationSerial: string;
  validPositions: (number | undefined)[];
}

const sideLabels = [
  '',
  'etc.smart.device.type.car.charger.smappee.controller.right',
  'etc.smart.device.type.car.charger.smappee.controller.left'
];

function getPlaceholderForType(type: DeviceType) {
  switch (type) {
    case DeviceType.CarChargerSticker:
      return 'https://ev.smappee.net/A00000001';
    default:
      return '';
  }
}

export default function AddComponentModal(props: ModalProps) {
  const {chargingStationSerial, validPositions} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve(false);

  const [type, setType] = useState<DeviceType>(DeviceType.CarChargerSticker);
  const [position, setPosition] = useState<number | undefined>(validPositions[0]);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [infoShown, setInfoShown] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const handlePositionChanged = (value: string) => {
    setPosition(value === 'none' ? undefined : parseInt(value));
  };

  const handleClickedInfo = () => {
    setInfoShown(true);
    setInfoOpen(true);
  };

  const handleTypeChanged = (value: string) => setType(value as DeviceType);
  const handleConfirm = () => {
    return api
      .createChargingStationModule(chargingStationSerial, {
        type,
        position,
        serialNumber: postProcessModuleSerial(serialNumber, type),
        label: position === undefined ? undefined : sideLabels[position]
      })
      .then(() => resolve(true))
      .catch(() => {
        NotificationManager.error('Could not create component - QR code already in use?');
      });
  };

  useEffect(() => {
    if (infoOpen) {
      const windowClickListener = () => setInfoOpen(false);
      window.document.addEventListener('click', windowClickListener, true);
      return () => window.document.removeEventListener('click', windowClickListener);
    }
  }, [infoOpen]);

  const infoButtonId = useMemo(() => `info${uuidv4()}`, []);

  return (
    <SingleActionModal isOpen={isOpen} onToggle={handleToggle} title="Add component" action={handleConfirm}>
      {validPositions.length > 1 && (
        <FormInputGroup name="connector" label="Connector">
          <div style={{display: 'flex'}}>
            <Input
              name="position"
              type="select"
              value={position === undefined ? 'none' : position.toString()}
              onChange={e => handlePositionChanged(e.currentTarget.value)}
            >
              {validPositions.map(position => (
                <option value={position === undefined ? 'none' : position.toString()}>
                  {position === undefined ? 'unspecified' : position.toString()}
                </option>
              ))}
            </Input>
            <Button
              variant="ghost_action_btn"
              id={infoButtonId}
              className="tw-p-0 tw-h-4 tw-w-4 tw-relative"
              onClick={handleClickedInfo}
            >
              <InfoCircle width={16} height={16} />
            </Button>
          </div>
        </FormInputGroup>
      )}
      <SelectInputGroup label="Type" name="type" value={type} onChange={handleTypeChanged}>
        <option value={DeviceType.CarChargerSticker}>QR Sticker</option>
      </SelectInputGroup>
      <TextInputGroup
        name="serialNumber"
        label={getChargingStationModuleLabel(type, position)}
        placeholder={getPlaceholderForType(type)}
        value={serialNumber}
        onChange={setSerialNumber}
        validate={value => validateChargingStationModuleSerial(type, value)}
      />
      {infoShown && (
        <StickyPopover isOpen={infoOpen} target={infoButtonId} placement="bottom-end">
          <PopoverBody>
            <img src={positionsImage} style={{width: 250}} />
          </PopoverBody>
        </StickyPopover>
      )}
    </SingleActionModal>
  );
}
