import {useCallback} from 'react';

import {Modal, ModalBody, ModalHeader} from '../../../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../../../modals/PromiseModal';
import {ChargingDisplayImage} from '../../../../models/ChargingDisplayImage';
import {T} from '../../../../utils/Internationalization';
import {formatUnixTime} from '../../../../utils/TimestampUtils';
import {IMAGE_STATUS_MAP, TYPE_MAP} from '../../models/ChargingStationConfiguration.model';

interface ServiceDeskInfoModalProps extends IPromiseModalProps<void> {
  image?: ChargingDisplayImage;
}

export default function ServiceDeskInfoModal(props: ServiceDeskInfoModalProps) {
  const {image} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = useCallback(() => resolve(), [resolve]);

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={handleClose}>
        {T('chargingStationConfiguration.manageDisplayImages.imageInfoServiceDesk')}
      </ModalHeader>
      <ModalBody>
        <div>
          {image?.type && (
            <>
              <p>
                {T('chargingStationConfiguration.manageDisplayImages.imageType')}: {TYPE_MAP[image.type]}
              </p>
              <p>ID: {image.id}</p>
              <p>
                {T('chargingStationConfiguration.manageDisplayImages.numberOfAttempts')}: {image.numberOfAttempts}
              </p>
              <p>
                {T('chargingStationConfiguration.manageDisplayImages.lastAttempt')}:{' '}
                {formatUnixTime(image.lastAttempt, 'Europe/Brussels')}
              </p>
            </>
          )}
          {image && image.statusFlags.length > 0 && (
            <p>
              {T('chargingStationConfiguration.manageDisplayImages.statusFlags')}:{' '}
              {image?.statusFlags.map(status => IMAGE_STATUS_MAP[status] || status).join(', ')}
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
