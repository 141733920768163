import {InvitationType, LocationInvitation} from '../../models/Invitation';
import {ILocationUser} from '../../models/Location';

export interface SharingEntry {
  status: 'SHARED' | 'INVITED' | 'EXPIRED';
  id: string;
  type: InvitationType;
  firstName: string;
  lastName?: string;
  emailAddress: string;
  userName?: string;
  sharedUser?: ILocationUser;
  invitation?: LocationInvitation;
}

export function getShareEntries(users: ILocationUser[], invitations: LocationInvitation[]): SharingEntry[] {
  const userEntries: SharingEntry[] = users.map(user => ({
    status: 'SHARED',
    id: `S${user.id.toString()}`,
    type: user.role === 'OBSERVER' ? InvitationType.ShareReadOnly : InvitationType.ShareReadWrite,
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.emailAddress,
    userName: user.userName,
    sharedUser: user
  }));
  const invitationEntries: SharingEntry[] = invitations.map(invitation => ({
    status: invitation.expired ? 'EXPIRED' : 'INVITED',
    id: `I${invitation.id}`,
    type: invitation.type,
    firstName: invitation.invitee.firstName,
    lastName: invitation.invitee.lastName,
    emailAddress: invitation.invitee.emailAddress,
    invitation
  }));
  return userEntries.concat(invitationEntries);
}
