import React, {useState, useRef, useMemo, useEffect} from 'react';
import {Dropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';

import {useAppContext} from '../../../app/context';
import {Closeable} from '../../../components/Closeable';
import {Icons} from '../../../components/Icon';
import styles from '../../../components/SearchableSelectInput.module.scss';

import {InstallationPartner} from '../../../models/InstallationPartner';
import {None} from '../../../utils/Arrays';
import {useThrottledEffect} from '../../../utils/Hooks';

interface ISearchInstallationPartnerProps {
  name: string;
  value?: InstallationPartner;
  onChange: (value?: InstallationPartner) => void;
  placeholder: string;
}

export function SearchInstallationPartner(props: ISearchInstallationPartnerProps) {
  const {name, value, onChange, placeholder} = props;
  const [query, setQuery] = useState(value ? value.name : '');
  const [results, setResults] = useState<InstallationPartner[]>(None);
  const {api} = useAppContext();

  const [isOpen, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => setQuery(value ? value.name : ''), [value]);
  useThrottledEffect(
    () => {
      if (!isOpen || query.length < 3) {
        setResults(None);
      } else {
        api.organizations.searchInstallationPartners(query).then(setResults);
      }
    },
    [query, isOpen],
    500
  );

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => setQuery(e.currentTarget.value);
  const ref = useRef<HTMLInputElement>(null);
  const handleClickedClear = () => {
    setQuery('');
    if (ref.current) ref.current.focus();
  };

  const handleClickedClearValue = () => {
    onChange(undefined);
  };

  const handleToggle = () => {
    const newIsOpen = focused || !isOpen;
    setOpen(newIsOpen);
  };

  const handleClickedDropdown = () => setOpen(true);

  const handleFocus = () => {
    setFocused(true);
    setOpen(true);
  };

  const handleClose = () => {
    setFocused(false);
    setOpen(false);
  };

  const optionItems = useMemo(() => {
    const handleClickedOption = (option: InstallationPartner) => {
      setQuery(option.name);
      onChange(option);
      setTimeout(() => setOpen(false));
    };

    return results.map(option => (
      <DropdownItem key={option.id} onClick={() => handleClickedOption(option)} data-value={option.id}>
        {option.name}
      </DropdownItem>
    ));
  }, [results, onChange]);

  return (
    <Closeable isOpen={isOpen} onClose={handleClose}>
      <Dropdown isOpen={isOpen} toggle={handleToggle} style={{position: 'relative'}}>
        {query && isOpen ? (
          <div onClick={handleClickedClear} className={styles.clear}>
            {Icons.Delete}
          </div>
        ) : (
          <div className={styles.downToggleButton} onClick={handleToggle}>
            {Icons.Dropdown}
          </div>
        )}
        {!isOpen && value && (
          <div onClick={handleClickedClearValue} className={styles.clearValue}>
            {Icons.Delete}
          </div>
        )}
        <DropdownToggle tag="div" onClick={handleClickedDropdown}>
          <input
            name={name}
            className="form-control"
            autoComplete="off"
            type="text"
            ref={ref}
            value={isOpen ? query : value ? value.name : placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </DropdownToggle>
        <DropdownMenu
          className={styles.dropdown}
          flip={false}
          modifiers={{
            preventOverflow: {padding: 50}
          }}
        >
          {optionItems}
        </DropdownMenu>
      </Dropdown>
    </Closeable>
  );
}
