import {SeriesColumnOptions} from 'highcharts';
import React from 'react';

import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {ActivePeriod, PeriodSettings} from '../../components/PeriodSelector';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {ISensorReading} from '../../models/SensorReadings';
import {createPointFormatter} from '../../utils/GraphUtils';

import styles from './index.module.scss';

export interface IGasOrWaterSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  sensorId?: number;
}

interface GasOrWaterChartProps {
  items: ISensorReading[];
  precision: number;
  unit: string;
  period: ActivePeriod;
  color: string;
  label: string;
}

export const GasOrWaterChart = React.memo((props: GasOrWaterChartProps) => {
  const {items, precision, unit, period, color, label} = props;
  const data = items.map(item => [item.timestamp, item.value]);
  const gas: SeriesColumnOptions = {
    name: `${label} [${unit}]`,
    color,
    type: 'column',
    data,
    tooltip: {
      pointFormatter: createPointFormatter(label, unit, precision)
    }
  };

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series: [gas],
    yAxis: [
      {
        title: {text: unit},
        opposite: false
      }
    ]
  });
  return <HighStockChart className={styles.stretch} config={config} from={actualFrom} to={actualTo} />;
});
