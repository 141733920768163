import React from 'react';

import {useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {IOrganizationActivationCode} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ILocationSummary} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {ITableField} from '../../models/Table';
import {hasPartnerFunctionality} from '../../models/User';
import {useOrganization, useOrganizationActivationCodes} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {ExportCsv, Reload} from '../components/actions';
import {ActivationCodeSelector} from '../components/actions/ActivationCodeSelector';
import {Spring} from '../components/CardActions';

import {cardViewProps, ICardState} from '../components/CardView';
import {BaseLocationsSettings, DEFAULT_BASE_LOCATION_SETTINGS, LocationsListCard} from '../Locations/LocationListCard';

interface LocationsSettings extends BaseLocationsSettings {
  organizationId?: number;
  activationCodeId?: number;
}

const ActivationCodeLocations = (props: ICardProps<LocationsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {activationCodeId} = settings;

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();

  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, settings.organizationId);
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [activationCodes, refreshActivationCodes] = useOrganizationActivationCodes(fetch, organizationId);
  const activationCode = activationCodes.find(code => code.id === activationCodeId) || activationCodes[0];

  const [locations, refreshLocations] = useCardLoader(
    async api => activationCode && api.activationCodes.getLocations(activationCode.code),
    [activationCode],
    plural('location'),
    [] as ILocationSummary[]
  );

  const handleChangeOrganization = (organization?: IOrganization) => {
    updateSettings({organizationId: organization?.id});
  };

  const handleChangeCode = (activationCode: IOrganizationActivationCode) => {
    updateSettings({activationCodeId: activationCode.id});
  };

  const refresh = () => {
    refreshLocations();
    refreshActivationCodes();
  };

  const actions = (defaultActions: React.ReactNode, columns: ITableField<ILocationSummary>[], state: ICardState) => {
    return (
      <>
        <ActivationCodeSelector
          organizations={inputOrganizations.organizations}
          activationCodes={activationCodes}
          organization={organization}
          activationCode={activationCode}
          searchable={true}
          onChangeOrganization={handleChangeOrganization}
          onChangeCode={handleChangeCode}
          updateQuery={updateOrganizationInputQuery}
        />
        <Reload onReload={refresh} />
        <ExportCsv name={state.title} fields={columns} items={locations || []} settings={settings.table} />
        <Spring />
        {defaultActions}
      </>
    );
  };

  return (
    <LocationsListCard
      locations={locations || []}
      actions={actions}
      refresh={refresh}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
};

const CARD: ICardType<LocationsSettings> = {
  type: CardTypeKey.ActivationCodeLocations,
  title: 'activationCodeLocations.title',
  description: 'activationCodeLocations.description',
  categories: [CardCategory.LOCATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_BASE_LOCATION_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: ActivationCodeLocations
};
export default CARD;
