import React, {useCallback} from 'react';

import {useAppContext} from '../app/context';
import {Button} from '../components/ui/button';

import API from '../core/api';
import {AuthUser} from '../models/AuthUser';
import {addCardToLayout, IUpdateBoardRequest, Board} from '../models/Board';
import {ICardSettings, ICardSettingsWithTable} from '../models/CardSettings';
import {
  ILocationSummary,
  ILocation,
  optionalLocationToSummary,
  LocationFunctionType,
  isChargingParent,
  hasChargingStationFunctionality
} from '../models/Location';
import {ITableField} from '../models/Table';
import {AppStore} from '../redux';
import {updateBoard} from '../redux/actions/boards';
import {IAppState} from '../redux/AppState';
import {useLocationUsingAPI} from '../utils/FunctionalData';
import {useAppSelector, useCardLoader} from '../utils/Hooks';
import {singular, T} from '../utils/Internationalization';

import {useCardContext} from './CardContext';
import {CardTypeKey, ICardType, CardLocationAwareness} from './CardType';

import {ColumnChooser} from './components';
import {CustomSettings} from './components/CardView';

export function getLocationSummary(
  state: IAppState,
  cardProps: {settings: ICardSettings}
): ILocationSummary | undefined {
  const locationId = cardProps.settings.locationId || state.preferences.locationId;
  let location =
    state.locations.locations.find(location => location.id === locationId) ||
    state.locations.fetchedLocations.find(location => location.id === locationId);
  if (location === undefined && state.preferences.locationId === locationId) {
    return optionalLocationToSummary(state.location.location);
  } else {
    return location;
  }
}

export function useCardTitle(settings: ICardSettings) {
  const {cardType} = useCardContext();
  return settings.name || T(cardType.title);
}

export function addCardToBoard(api: API, store: AppStore, board: Board, cardType: CardTypeKey, settings?: any) {
  const {id: boardId} = board;

  api.dashboard.createCard(boardId, {type: cardType, settings}).then(board => {
    if (!board) return;

    if (board.settings === undefined) {
      board.settings = {layouts: undefined, custom: true, version: 2};
    }
    addCardToLayout(board, board.cards[board.cards.length - 1]);
    api.dashboard.updateBoard(board as IUpdateBoardRequest);

    store.dispatch(updateBoard(board));
  });
}

export function getCardLocationError(
  cardType: ICardType<any>,
  location: ILocationSummary | undefined,
  user: AuthUser,
  onClickedParent: () => void
) {
  switch (cardType.locationAware) {
    case CardLocationAwareness.Required:
      if (location === undefined) return T('card.error.noLocationSelected');
      break;
    case CardLocationAwareness.RequiresChargingStation:
      if (location === undefined) return T('card.error.noLocationSelected');
      else if (!hasChargingStationFunctionality(location)) {
        return T('card.error.noChargingStationSelected');
      }
      break;
    case CardLocationAwareness.RequiresRegularOrServiceDesk:
    case CardLocationAwareness.RequiresRegular:
      if (location === undefined) {
        return T('card.error.noLocationSelected');
      } else if (
        cardType.locationAware === CardLocationAwareness.RequiresRegularOrServiceDesk &&
        user.isServiceDesk()
      ) {
        return undefined;
      } else if (location.functionType === LocationFunctionType.ChargingStation) {
        return (
          <span>
            {T('card.error.requiresRegularLocation')}
            <br />
            {location.parentId !== undefined && (
              <Button
                variant="tertiary_link"
                onClick={onClickedParent}
                className="tw-relative tw-bottom-[0.1875rem] tw-p-0 !tw-mt-4"
              >
                {T('card.error.navigateToChargingParkInstead')}
              </Button>
            )}
          </span>
        );
      }
      break;
  }
}

export function useCardLocation(settings: ICardSettings): ILocationSummary | undefined {
  return useAppSelector(state => getLocationSummary(state, {settings}));
}

export function useCardLocationDetails(settings: ICardSettings): ILocation | undefined {
  const stateLocation = useAppSelector(state => state.location.location);
  const [loaded] = useCardLoader(
    api =>
      settings.locationId === undefined ? Promise.resolve(stateLocation) : api.locations.get(settings.locationId),
    [stateLocation, settings.locationId],
    singular('location'),
    undefined
  );
  return loaded;
}

export function useCardLocationId(settings: ICardSettings): number | undefined {
  return useAppSelector(state => settings.locationId || state.preferences.locationId);
}

export function useCardChargingStation(settings: ICardSettings): ILocationSummary | undefined {
  const location = useCardLocation(settings);
  if (location === undefined) return undefined;
  if (hasChargingStationFunctionality(location)) return location;

  return undefined;
}

export function useCardChargingStationGroup(settings: ICardSettings): ILocationSummary | undefined {
  const {api} = useAppContext();
  const location = useCardLocation(settings);
  const childLocation = location && location.chargingStation !== undefined ? location : undefined;

  const parentLocationId = childLocation && childLocation.parentId;
  const [parentLocation] = useLocationUsingAPI(api, parentLocationId);

  if (parentLocationId) return optionalLocationToSummary(parentLocation);
  else if (location === undefined) return undefined;
  else if (location.chargingStation && location.functionType === LocationFunctionType.Standard) {
    return location;
  } else if (isChargingParent(location.functionType)) return location;
  else return undefined;
}

export function useCardChargingStationGroupId(settings: ICardSettings): number | undefined {
  const location = useCardLocation(settings);
  const childLocation = location && location.chargingStation !== undefined ? location : undefined;
  const parentLocationId = childLocation && childLocation.parentId;

  if (location === undefined) return undefined;
  else if (isChargingParent(location.functionType)) return location.id;
  else if (location.chargingStation && location.functionType === LocationFunctionType.Standard) {
    return location.id;
  } else return parentLocationId;
}

export function useCardLocationParent(settings: ICardSettings): ILocationSummary | undefined {
  const {api} = useAppContext();
  const location = useCardLocation(settings);

  const parentLocationId = location && location.parentId;
  const [parentLocation] = useLocationUsingAPI(api, parentLocationId);

  if (parentLocationId) return optionalLocationToSummary(parentLocation);
  else if (location === undefined) return undefined;
  else return undefined;
}

export function useUser() {
  return useAppSelector(state => state.me.me);
}

export function useUserLocale() {
  const language = useAppSelector(state => state.me?.me?.language);
  if (language) {
    switch (language) {
      case 'nl':
        return 'nl-BE';
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      case 'en':
        return 'en-GB';
      case 'it':
        return 'it-IT';
      default:
        return 'en-GB';
    }
  } else {
    return `en-GB`;
  }
}

export function useCardColumnSettings<T>(
  fields: ITableField<T>[],
  title?: string
): CustomSettings<ICardSettingsWithTable> {
  return useCallback(
    (settings, updateSettings) => (
      <ColumnChooser
        title={title}
        fields={fields}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    ),
    [fields, title]
  );
}
