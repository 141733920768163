import React, {useState, useMemo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';
import Table, {migrateTableSettings, SortOrder} from '../../components/Table';

import {Button} from '../../components/ui/button';
import {Clear, Unlink} from '../../components/ui-lib/icons/small';
import API from '../../core/api';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ILocationSummary} from '../../models/Location';
import {IQueriedLocationUser} from '../../models/LocationUser';
import {ITableField, StringField, NumberField, CheckboxField} from '../../models/Table';
import {AppStore} from '../../redux';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {useDelayedEffect} from '../../utils/Hooks';
import {T, quantity} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import ColumnChooser from '../components/ColumnChooser';

import {SelectLocationField} from './SelectLocationField';

type IUsersSettings = ICardSettingsWithTable;

const rowKey = (item: IQueriedLocationUser) => `${item.userId}.${item.serviceLocationId}`;

function getTableColumns(
  api: API,
  store: AppStore,
  location: ILocationSummary | undefined,
  selected: IQueriedLocationUser[],
  setSelected: (selection: IQueriedLocationUser[]) => void
): ITableField<IQueriedLocationUser>[] {
  const isChecked = (row: IQueriedLocationUser) => {
    return selected.some(item => item === row);
  };

  const setChecked = (row: IQueriedLocationUser, checked: boolean) => {
    const currentlyChecked = isChecked(row);
    if (checked === currentlyChecked) return;

    if (checked) {
      setSelected([...selected, row]);
    } else {
      setSelected(selected.filter(item => item !== row));
    }
  };

  let columns: ITableField<IQueriedLocationUser>[] = [
    new CheckboxField('selected', '', isChecked, setChecked, {
      autoInsert: 'start',
      width: 20
    }),

    new StringField('email', T('userChargingSessions.email')),
    new NumberField('serviceLocationId', T('userChargingSessions.serviceLocationId')),
    new SelectLocationField(
      'location',
      T('userChargingSessions.location'),
      location === undefined ? undefined : location.id,
      api,
      store
    ),
    new StringField('serialNumber', T('userChargingSessions.serialNumber'))
  ];

  // TODO: DEPRECATED column username SSO
  if (!hasFeature(AppFeature.SocialLogin)) {
    columns.push(
      new StringField('userName', T('userChargingSessions.userName'), {
        alwaysVisible: true,
        autoInsert: 'start'
      })
    );
  }

  return columns;
}

function UserLocations(props: ICardProps<IUsersSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {store, api} = useAppContext();
  const modals = useModals();

  const location = useCardLocation(settings);
  const [error, setError] = useState<string | undefined>(T('userChargingSessions.error.notYetLoaded'));
  const [items, setItems] = useState<IQueriedLocationUser[]>([]);
  const [selected, setSelected] = useState<IQueriedLocationUser[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = () => {
    setError(undefined);

    if (hasFeature(AppFeature.SocialLogin) || searchQuery.includes('@')) {
      return fetch('users', api => api.findServiceLocationsByEmail(searchQuery), 'users').then(itemsForEmail => {
        const items = [...itemsForEmail];
        setItems(items);
      });
    }

    if (searchQuery.includes('@')) {
      fetch(
        'users',
        api =>
          Promise.all([api.findServiceLocationsByEmail(searchQuery), api.findServiceLocationsByUsername(searchQuery)]),
        'users'
      ).then(([itemsForEmail, itemsForUsername]) => {
        const items = [...itemsForEmail, ...itemsForUsername];
        setItems(items);
      });
    } else {
      fetch('users', api => api.findServiceLocationsByUsername(searchQuery), 'users').then(setItems);
    }
  };

  useDelayedEffect(
    () => {
      fetchData();
    },
    [searchQuery],
    1000
  );

  const handleChangedSearch = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value);
  };

  const handleClickedClearSelection = () => {
    setSelected([]);
    setRefreshKey(key => key + 1);
  };

  const handleClickedUnshare = async () => {
    const confirmation = await modals.show(props => (
      <ConfirmationPromiseModal
        title={T('userLocations.unshare.title')}
        message={T('userLocations.unshare.message', {
          locations: quantity('location', selected.length)
        })}
        {...props}
      />
    ));
    if (confirmation !== ConfirmationResult.Accept) return;

    await Promise.all(
      selected.map(async item => {
        try {
          if (hasFeature(AppFeature.SocialLogin)) {
            await api.locations.removeUserById(item.serviceLocationId, item.userId);
          } else {
            await api.locations.removeUser(item.serviceLocationId, item.userName);
          }
          NotificationManager.success(
            T('userLocations.unshare.success', {
              name: item.serviceLocationName,
              username: item.userName
            })
          );
        } catch {
          NotificationManager.error(
            T('userLocations.unshare.failed', {
              name: item.serviceLocationName,
              username: item.userName
            })
          );
        }
      })
    );
    setSelected([]);
    fetchData();
  };

  const columns = useMemo(
    () => getTableColumns(api, store, location, selected, setSelected),
    [api, store, location, selected]
  );

  const renderSettings: CustomSettings<IUsersSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser fields={columns} settings={settings.table} updateSettings={table => updateSettings({table})} />
    );
  };

  const actions: CustomActions = () => (
    <CardActions>
      <Reload onReload={() => fetchData()} />
      <Input
        name="query"
        type="text"
        autoWidth={true}
        placeholder={T('users.search')}
        onChange={handleChangedSearch}
        value={searchQuery}
        className={testingClasses.search}
        data-testid={testingClasses.search}
      />
      <Spring />
      {selected.length > 0 && (
        <Button
          variant="secondary_default"
          size="lg"
          onClick={handleClickedClearSelection}
          title={T('userLocations.clearSelection.tooltip')}
        >
          <Clear width={16} height={16} />
        </Button>
      )}
      {selected.length > 0 && (
        <Button
          variant="secondary_default"
          size="lg"
          onClick={handleClickedUnshare}
          title={T('userLocations.unshare.tooltip')}
        >
          <Unlink width={16} height={16} />
        </Button>
      )}
    </CardActions>
  );

  return (
    <CardView error={error} actions={actions} customSettings={renderSettings} {...cardViewProps(props)}>
      <Table
        key={refreshKey}
        fields={columns}
        items={items}
        rowKey={rowKey}
        hasPaging={true}
        noun="location"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        emptyMessage="No locations found"
        selected={selected.length}
      />
    </CardView>
  );
}

const DEFAULT_SETTINGS: IUsersSettings = {
  table: {
    sortColumn: 'userName',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [
      {name: 'email', visible: true},
      {name: 'serviceLocationId', visible: true},
      {name: 'location', visible: true},
      {name: 'serialNumber', visible: true}
    ]
  }
};

const CARD: ICardType<ICardSettingsWithTable> = {
  type: CardTypeKey.UserLocations,
  title: 'userLocations.title',
  description: 'userLocations.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: UserLocations
};

export default CARD;
