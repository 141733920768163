import React, {useRef, useCallback, useEffect, useState} from 'react';

import {ITableField, ITableCellProps, FieldAlignment} from '../../models/Table';

import {classes} from '../../utils/Styles';

import {Button as RsButton} from '../bootstrap';

import {SMAPPEE_FONT_ICON_MAP} from '../Icon';

import {IPersistedTableSettings, SortOrder} from '.';
import styles from './index.module.scss';

interface ColumnResizeHandleProps {
  onResize: (width: number) => void;
  onResizeFinished: () => void;
  onRevertToDefault: () => void;
}

interface IDragState {
  dragging: boolean;
  startWidth: number;
  startMouseX: number;
}

const ColumnResizeHandle = (props: ColumnResizeHandleProps) => {
  const {onResize, onResizeFinished, onRevertToDefault} = props;

  const dragState = useRef<IDragState>();
  const dragMoved = useRef<boolean>(false);

  const handleDraggingMouseMoveRef = useRef<(e: MouseEvent) => void>(() => {});
  handleDraggingMouseMoveRef.current = (event: MouseEvent) => {
    const state = dragState.current;
    if (!state) return;

    const newWidth = state.startWidth + (event.clientX - state.startMouseX);
    onResize(newWidth);
  };
  const handleDraggingMouseMove = useCallback((event: MouseEvent) => handleDraggingMouseMoveRef.current(event), []);

  const stopDragRef = useRef<() => void>(() => {});
  const finishedRef = useRef<() => void>(() => {});
  const revertToDefaultRef = useRef<() => void>(() => {});

  finishedRef.current = onResizeFinished;
  revertToDefaultRef.current = onRevertToDefault;
  stopDragRef.current = useCallback(() => {
    window.removeEventListener('mousemove', handleDraggingMouseMove);
    window.removeEventListener('mouseup', stopDragRef.current);
    finishedRef.current();
  }, [handleDraggingMouseMove]);

  const handlePointerDown = (e: React.PointerEvent<HTMLElement>) => {
    (e.target as any).setPointerCapture(e.pointerId);

    dragState.current = {
      dragging: true,
      startWidth: e.currentTarget.parentElement!.clientWidth,
      startMouseX: e.clientX
    };
    dragMoved.current = false;
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLElement>) => {
    const state = dragState.current;
    if (!state) return;

    const newWidth = state.startWidth + (e.clientX - state.startMouseX);
    dragMoved.current = true;
    onResize(newWidth);
  };

  const handlePointerUp = (e: React.PointerEvent<HTMLElement>) => {
    (e.target as any).releasePointerCapture(e.pointerId);

    if (dragMoved) {
      finishedRef.current();
    }
    dragState.current = undefined;
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
    revertToDefaultRef.current();
  };

  useEffect(() => {
    // make sure dragging is stopped if this component is unmounted
    return () => stopDragRef.current();
  }, []);

  return (
    <div
      className={styles.resizeHandle}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onDoubleClick={handleDoubleClick}
    >
      ǁ
    </div>
  );
};

interface ColumnHeaderProps<T> {
  field: ITableField<T>;
  settings?: IPersistedTableSettings;
  defaultSortColumn?: string;
  onSortClicked?: (field: ITableField<T>, order: SortOrder) => void;
  onResizing?: (field: ITableField<T>, width: number | undefined) => void;
  onResize?: (field: ITableField<T>, width: number | undefined) => void;
}

export default function TableColumnHeader<T>(props: ColumnHeaderProps<T>) {
  const {field, settings, defaultSortColumn, onSortClicked, onResize, onResizing} = props;
  const {label, options} = field;
  const {unit, sortable} = options;

  const customWidth = settings && settings.columnSizes && settings.columnSizes[field.name];
  const [resizingWidth, setResizingWidth] = useState<number>();
  const tooltip = options.tooltip || (unit ? `${label} [${unit}]` : label);

  if (field.options.header) {
    const props: ITableCellProps = {
      key: field.name,
      className: classes(styles.columnHeaderWrapper, styles[field.options.align]),
      title: tooltip,
      'data-name': field.name
    };
    return field.options.header(props);
  }

  const handleResize = (width: number) => {
    if (width === undefined) {
      setResizingWidth(undefined);
      return;
    }

    if (width < 20) return;

    if (onResizing) {
      onResizing(field, width);
      setResizingWidth(width);
    }
  };

  const handleResizeFinished = () => {
    if (onResize && resizingWidth !== undefined) {
      onResize(field, resizingWidth);
      setResizingWidth(undefined);
    }
  };

  const handleRevertToDefault = () => {
    setResizingWidth(undefined);
    onResize && onResize(field, undefined);
  };

  if (sortable && settings) {
    const sortColumn = settings.sortColumn || defaultSortColumn;
    const sorted = settings.sortOrder || SortOrder.ASCENDING;
    let iconCls;

    // Default sort direction
    let nextOrder = SortOrder.ASCENDING;

    // Determine icon and next sort direction
    if (sortColumn === field.name) {
      switch (sorted) {
        case SortOrder.ASCENDING:
          iconCls = `fal fa-sort-amount-up`;
          nextOrder = SortOrder.DESCENDING;
          break;
        case SortOrder.DESCENDING:
          iconCls = `fal fa-sort-amount-down`;
          nextOrder = SortOrder.ASCENDING;
          break;
      }
    }

    const SortIconComponent = iconCls !== undefined ? SMAPPEE_FONT_ICON_MAP[iconCls] : null;

    const width = resizingWidth || customWidth;
    return (
      <th
        key={field.name}
        className={classes(styles.columnHeaderWrapper, styles[field.options.align])}
        title={tooltip}
        data-name={field.name}
        style={{width}}
      >
        <span className={styles.columnHeader} style={{width: width === undefined ? undefined : width + 11}}>
          {field.options.align === FieldAlignment.Right && <div style={{flexGrow: 1}} />}
          <RsButton
            color="link"
            withoutPadding
            className={styles.sortColumn}
            onClick={onSortClicked && (() => onSortClicked(field, nextOrder))}
          >
            <span className={styles.columnHeader}>{label}</span>
            {unit && <span>&nbsp;[{unit}]</span>}{' '}
            {settings.sortColumn === field.name && SortIconComponent && <SortIconComponent width={16} height={16} />}
          </RsButton>
          {field.options.align !== FieldAlignment.Right && <div style={{flexGrow: 1}} />}
          {onResize && (
            <ColumnResizeHandle
              onResize={handleResize}
              onResizeFinished={handleResizeFinished}
              onRevertToDefault={handleRevertToDefault}
            />
          )}
        </span>
      </th>
    );
  } else {
    return (
      <th key={field.name} className={styles.columnHeaderWrapper} title={tooltip} data-name={field.name}>
        <span className={classes(styles.columnHeader, styles[field.options.align])}>
          <span className={styles.columnHeader}>{label}</span>
          {unit && <span>&nbsp;[{unit}]</span>}
          {onResize && (
            <ColumnResizeHandle
              onResize={handleResize}
              onResizeFinished={handleResizeFinished}
              onRevertToDefault={handleRevertToDefault}
            />
          )}
        </span>
      </th>
    );
  }
}
