import {useEffect, useState, useRef, useReducer} from 'react';

import {useAppContext} from '../app/context';
import {useUser} from '../cards/CardUtils';
import {ChargingStation} from '../models/ChargingStation';
import {CarChargingStationChargingStateMqttMessage, CarChargerIECStatus} from '../models/ChargingStatus';
import {ILoadUpdateChannel} from '../models/Load';

import {getOnlineStatus, OnlineStatus} from '../models/OnlineStatus';
import {None} from '../utils/Arrays';

import {TrackingType, IPowerMessage} from './LiveDataModels';
import {MqttSubscription} from './MqttConnector';

interface CarChargerStatusRequest {
  serialNumber?: string;
  serialNumbers?: string[];
  trackingUuid: string;
  channels: {
    position: number;
    statusChannel: ILoadUpdateChannel;
  }[];
}

export function useLiveChargingStatus(
  req: CarChargerStatusRequest | undefined
): (CarChargingStationChargingStateMqttMessage | undefined)[] {
  const {mqtt} = useAppContext();
  const me = useUser();

  const [lastMessages, setLastMessages] = useState<(CarChargingStationChargingStateMqttMessage | undefined)[]>([]);
  const subscriptions = useRef<(MqttSubscription | undefined)[]>([]);

  useEffect(() => {
    const setStatusSubscription = (index: number, subscription: MqttSubscription | undefined) => {
      const newSubscriptions = [...subscriptions.current];
      while (index >= newSubscriptions.length) newSubscriptions.push(undefined);

      const current = newSubscriptions[index];
      if (current) current.close();

      newSubscriptions[index] = subscription;
      subscriptions.current = newSubscriptions;
    };

    const setLastStatusMessage = (index: number, message: CarChargingStationChargingStateMqttMessage | undefined) => {
      setLastMessages(lastMessages => {
        const newMessages = [...lastMessages];
        while (index >= newMessages.length) newMessages.push(undefined);

        newMessages[index] = message;
        return newMessages;
      });
    };

    const handleStatusConnected = (subscription: MqttSubscription, index: number, topic: string) => {
      if (req === undefined || index >= req.channels.length || req.channels[index].statusChannel.name !== topic) {
        return;
      }

      setStatusSubscription(index, subscription);
    };

    const disconnect = () => {
      setLastMessages([]);

      const subscription = subscriptions.current;
      for (var index = 0; index < subscription.length; index++) {
        const statusSubscription = subscription[index];
        if (statusSubscription) {
          statusSubscription.close();
          setLastStatusMessage(index, undefined);
        }
      }
      subscriptions.current = [];
    };

    disconnect();

    if (!req) return;

    const {serialNumber, channels} = req;
    if (!serialNumber) return;

    for (var i = 0; i < channels.length; i++) {
      const index = channels[i].position - 1;
      const statusChannel = channels[i].statusChannel;

      mqtt.subscribe(
        me.userId.toString(),
        statusChannel.userName || '',
        statusChannel.password || '',
        statusChannel.name,
        undefined,
        true,
        subscription => handleStatusConnected(subscription, i, statusChannel.name), // eslint-disable-line no-loop-func
        message => setLastStatusMessage(index, message)
      );
    }

    return disconnect;
  }, [mqtt, req, setLastMessages, subscriptions, me.userId]);

  return lastMessages;
}

export function createCarChargingStatusRequest(
  chargingStationGroupUuid: string,
  station: ChargingStation
): CarChargerStatusAndPowerRequest {
  return {
    serialNumber: station.data.trackingSerialNumber,
    serialNumbers: station.data.trackingSerialNumbers,
    trackingUuid: chargingStationGroupUuid,
    stationSerial: station.data.serialNumber,
    channels: station.getControllers().map(channel => ({
      deviceId: channel.smartDevice!.id,
      position: channel.position || 0,
      statusChannel: channel.smartDevice!.carCharger!.chargingStateUpdateChannel,
      powerChannel: channel.smartDevice!.carCharger!.powerUpdateChannel
      //initialStatus: channel.smartDevice!.carCharger!.iecStatus
    }))
  };
}

export function createConnectorStatusRequest(
  chargingStationGroupUuid: string,
  station: ChargingStation,
  connector: number
): CarChargerStatusAndPowerRequest | undefined {
  const controller = station.getController(connector);
  const carCharger = controller?.smartDevice?.carCharger;
  if (carCharger === undefined) return undefined;

  return {
    serialNumber: station.data.trackingSerialNumber,
    serialNumbers: station.data.trackingSerialNumbers,
    trackingUuid: chargingStationGroupUuid,
    stationSerial: station.data.serialNumber,
    channels: [
      {
        deviceId: controller!.smartDevice!.id,
        position: connector,
        statusChannel: carCharger.chargingStateUpdateChannel,
        powerChannel: carCharger.powerUpdateChannel
        //initialStatus: carCharger.iecStatus
      }
    ]
  };
}

enum CarChargerStatesActionType {
  Reset,
  SetState,
  SetPower,
  SetOffline
}

interface BaseCarChargerStatesAction {
  type: CarChargerStatesActionType;
}

interface CarChargerStatesResetAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.Reset;
}

interface CarChargerStatesSetStateAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.SetState;
  serialNumber: string;
  position: number;
  state: CarChargingStationChargingStateMqttMessage;
}

interface CarChargerStatesSetPowerAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.SetPower;
  serialNumber: string;
  position?: number;
  power: IPowerMessage;
}

interface CarChargerStatesSetOfflineAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.SetOffline;
  serialNumber: string;
}

type CarChargingStatesAction =
  | CarChargerStatesResetAction
  | CarChargerStatesSetStateAction
  | CarChargerStatesSetPowerAction
  | CarChargerStatesSetOfflineAction;

export class CarChargerState {
  id: string;
  state: (CarChargingStationChargingStateMqttMessage | undefined)[];
  power?: IPowerMessage;
  perSidePower?: (IPowerMessage | undefined)[];
  offline: boolean;

  constructor(
    id: string,
    state: (CarChargingStationChargingStateMqttMessage | undefined)[] = None,
    power: IPowerMessage | undefined = undefined,
    perSidePower: (IPowerMessage | undefined)[] = None,
    offline: boolean = false
  ) {
    this.id = id;
    this.state = state;
    this.power = power;
    this.perSidePower = perSidePower;
    this.offline = offline;
  }

  withState(position: number, state: CarChargingStationChargingStateMqttMessage) {
    const newStates = [...this.state];
    while (newStates.length <= position) newStates.push(undefined);

    newStates[position] = state;
    return new CarChargerState(this.id, newStates, this.power, this.perSidePower, this.offline);
  }

  withPower(message: IPowerMessage, position?: number) {
    if (position === undefined) {
      return new CarChargerState(this.id, this.state, message, undefined, this.offline);
    } else {
      const newPower = [...(this.perSidePower || None)];
      const position2 = position || 0;
      while (newPower.length <= position2) newPower.push(undefined);

      newPower[position2] = message;
      return new CarChargerState(this.id, this.state, undefined, newPower, false);
    }
  }

  withOffline() {
    return new CarChargerState(this.id, this.state, undefined, undefined, true);
  }
}

export class CarChargerStates {
  states: Map<string, CarChargerState>;

  constructor(states?: Map<string, CarChargerState>) {
    this.states = states || new Map();
  }

  getState(id: string) {
    return this.states.get(id) || new CarChargerState(id);
  }

  withState(id: string, state: CarChargerState) {
    const result = new Map(this.states);
    result.set(id, state);
    return new CarChargerStates(result);
  }
}

function carChargingMessagesReducer(state: CarChargerStates, action: CarChargingStatesAction): CarChargerStates {
  switch (action.type) {
    default:
    case CarChargerStatesActionType.Reset:
      return new CarChargerStates();
    case CarChargerStatesActionType.SetOffline:
      return state.withState(action.serialNumber, state.getState(action.serialNumber).withOffline());
    case CarChargerStatesActionType.SetPower:
      return state.withState(
        action.serialNumber,
        state.getState(action.serialNumber).withPower(action.power, action.position)
      );
    case CarChargerStatesActionType.SetState:
      return state.withState(
        action.serialNumber,
        state.getState(action.serialNumber).withState(action.position, action.state)
      );
  }
}

interface CarChargerStatusAndPowerRequest {
  serialNumber?: string;
  serialNumbers?: string[];
  trackingUuid: string;
  stationSerial: string;
  channels: {
    deviceId: string;
    position: number;
    statusChannel: ILoadUpdateChannel;
    powerChannel?: ILoadUpdateChannel;
    initialStatus?: CarChargerIECStatus;
  }[];
}

class ChargerInternalState {
  gatewaySerialNumber: string;
  stationSerialNumber: string;
  onOffline: (gatewaySerialNumber: string) => void;

  statusSubscriptions: (MqttSubscription | undefined)[];
  powerSubscriptions: (MqttSubscription | undefined)[];
  offlineTimeout?: NodeJS.Timeout;
  resendTrackingTimeout?: NodeJS.Timeout;

  constructor(
    gatewaySerialNumber: string,
    stationSerialNumber: string,
    onOffline: (gatewaySerialNumber: string) => void,
    powerEnabled: boolean
  ) {
    this.gatewaySerialNumber = gatewaySerialNumber;
    this.stationSerialNumber = stationSerialNumber;
    this.onOffline = onOffline;

    this.statusSubscriptions = [];
    this.powerSubscriptions = [];
    this.handleOfflineTimeout = this.handleOfflineTimeout.bind(this);
    this.handleResendTrackingTimeout = this.handleResendTrackingTimeout.bind(this);

    if (powerEnabled) {
      this.offlineTimeout = setTimeout(this.handleOfflineTimeout, 65000);
      this.resendTrackingTimeout = setInterval(this.handleResendTrackingTimeout, 20000);
    }
  }

  onMessageReceived() {
    if (this.offlineTimeout) clearTimeout(this.offlineTimeout);
    if (this.resendTrackingTimeout) clearInterval(this.resendTrackingTimeout);

    this.offlineTimeout = setTimeout(this.handleOfflineTimeout, 65000);
    this.resendTrackingTimeout = setInterval(this.handleResendTrackingTimeout, 20000);
  }

  close() {
    for (var powerSubscription of this.powerSubscriptions) {
      if (powerSubscription) powerSubscription.close();
    }

    for (var statusSubscription of this.statusSubscriptions) {
      if (statusSubscription) statusSubscription.close();
    }

    if (this.offlineTimeout) clearTimeout(this.offlineTimeout);
    if (this.resendTrackingTimeout) clearInterval(this.resendTrackingTimeout);
  }

  private handleOfflineTimeout() {
    this.onOffline(this.gatewaySerialNumber);
  }

  private handleResendTrackingTimeout() {
    console.log(`Resend tracking on ${this.stationSerialNumber}`);
    for (var powerSubscription of this.powerSubscriptions) {
      powerSubscription && powerSubscription.resendTrackingMessage();
    }
  }
}

class ChargersInternalState {
  stations: Map<string, ChargerInternalState>;
  onOffline: (id: string) => void;

  constructor(onOffline: (id: string) => void) {
    this.stations = new Map<string, ChargerInternalState>();
    this.onOffline = onOffline;
  }

  close() {
    this.stations.forEach(value => value.close());
    this.stations.clear();
  }

  register(id: string, stationSerialNumber: string, powerEnabled: boolean) {
    if (!this.stations.has(id)) {
      this.stations.set(id, new ChargerInternalState(id, stationSerialNumber, this.onOffline, powerEnabled));
    }
  }

  setStatusSubscription(id: string, index: number, subscription: MqttSubscription) {
    const charger = this.stations.get(id);
    if (!charger) return;

    while (index >= charger.statusSubscriptions.length) {
      charger.statusSubscriptions.push(undefined);
    }

    const current = charger.statusSubscriptions[index];
    if (current) current.close();

    charger.statusSubscriptions[index] = subscription;
  }

  setPowerSubscription(id: string, index: number, subscription: MqttSubscription) {
    const charger = this.stations.get(id);
    if (!charger) return;

    while (index >= charger.powerSubscriptions.length) {
      charger.powerSubscriptions.push(undefined);
    }

    const current = charger.powerSubscriptions[index];
    if (current) current.close();

    charger.powerSubscriptions[index] = subscription;
  }

  onMessageReceived(id: string) {
    const charger = this.stations.get(id);
    if (!charger) return;

    charger.onMessageReceived();
  }
}

export function useCarChargingStatuses(
  requests: CarChargerStatusAndPowerRequest[],
  statusEnabled: boolean,
  powerEnabled: boolean
): CarChargerStates {
  const {mqtt} = useAppContext();
  const me = useUser();

  const [states, dispatcher] = useReducer<typeof carChargingMessagesReducer>(
    carChargingMessagesReducer,
    new CarChargerStates()
  );

  useEffect(() => {
    const setLastStatusMessage = (
      serialNumber: string,
      position: number,
      message: CarChargingStationChargingStateMqttMessage
    ) => {
      dispatcher({
        type: CarChargerStatesActionType.SetState,
        serialNumber,
        position,
        state: message
      });
    };

    const handleTimeout = (serialNumber: string) => {
      dispatcher({type: CarChargerStatesActionType.SetOffline, serialNumber});
    };

    const internalState = new ChargersInternalState(handleTimeout);

    const setLastPowerMessage = (serialNumber: string, message: IPowerMessage, position?: number) => {
      internalState.onMessageReceived(serialNumber);
      dispatcher({
        type: CarChargerStatesActionType.SetPower,
        serialNumber,
        position,
        power: message
      });
    };

    const handleStatusConnected = (subscription: MqttSubscription, id: string, index: number) => {
      internalState.setStatusSubscription(id, index, subscription);
    };

    const handlePowerConnected = (subscription: MqttSubscription, id: string, index: number) => {
      internalState.setPowerSubscription(id, index, subscription);
    };

    for (var request = 0; request < requests.length; request++) {
      const {serialNumber, serialNumbers, trackingUuid, channels, stationSerial} = requests[request];
      if (!statusEnabled && !powerEnabled) return;
      const id = serialNumber || stationSerial;

      internalState.register(id, stationSerial, powerEnabled && serialNumber !== undefined);
      for (var i = 0; i < channels.length; i++) {
        const index = channels[i].position - 1;
        const statusChannel = channels[i].statusChannel;
        const powerChannel = channels[i].powerChannel;
        if (channels[i].initialStatus) {
          setLastStatusMessage(id, index, {
            iecStatus: {
              current: channels[i].initialStatus
            }
          });
        }

        if (statusEnabled) {
          mqtt.subscribe(
            me.userId.toString(),
            statusChannel.userName || '',
            statusChannel.password || '',
            statusChannel.name,
            {
              type: TrackingType.CarCharging,
              serialNumber,
              serialNumbers,
              trackingLocationUUID: trackingUuid
            },
            true,
            subscription => handleStatusConnected(subscription, id, index),
            message => setLastStatusMessage(id, index, message)
          );
        }
        if (powerEnabled && powerChannel && serialNumbers && serialNumbers.length > 1) {
          mqtt.subscribe(
            me.userId.toString(),
            powerChannel.userName || '',
            powerChannel.password || '',
            powerChannel.name,
            {
              type: TrackingType.CarCharging,
              serialNumber,
              serialNumbers,
              trackingLocationUUID: trackingUuid
            },
            false,
            subscription => handlePowerConnected(subscription, id, index),
            message => setLastPowerMessage(id, message, index)
          );
        } else if (i === 0 && powerEnabled && powerChannel && serialNumber) {
          mqtt.subscribe(
            me.userId.toString(),
            powerChannel.userName || '',
            powerChannel.password || '',
            powerChannel.name,
            {
              type: TrackingType.CarCharging,
              serialNumber,
              serialNumbers,
              trackingLocationUUID: trackingUuid
            },
            false,
            subscription => handlePowerConnected(subscription, id, 0),
            message => setLastPowerMessage(id, message)
          );
        }
      }
    }

    return () => {
      internalState.close();
      dispatcher({type: CarChargerStatesActionType.Reset});
    };
  }, [mqtt, requests, statusEnabled, powerEnabled, dispatcher, me.userId]);

  return states;
}

export function useLiveChargerPower(
  chargingStationGroupUuid: string | undefined,
  station: ChargingStation | undefined
): [IPowerMessage | undefined, OnlineStatus | undefined] {
  const {mqtt} = useAppContext();
  const me = useUser();

  const [lastMessage, setLastMessage] = useState<IPowerMessage>();
  const [offline, setOffline] = useState<boolean>(false);
  const unavailable =
    station?.data.trackingSerialNumber === undefined && station?.data.trackingSerialNumbers === undefined;

  useEffect(() => {
    setLastMessage(undefined);
    setOffline(false);

    if (station === undefined || chargingStationGroupUuid === undefined) return;

    const charger = station.getAnyCarCharger();
    if (charger === undefined || charger.powerUpdateChannel === undefined) {
      return;
    }

    if (unavailable) return;

    const handleOffline = () => {
      setOffline(true);
      setLastMessage(undefined);
    };

    const internalState = new ChargerInternalState(
      station.data.trackingSerialNumbers
        ? station.data.trackingSerialNumbers.join(',')
        : station.data.trackingSerialNumber || '',
      station.data.serialNumber,
      handleOffline,
      true
    );
    const powerChannel = charger.powerUpdateChannel;
    mqtt.subscribe(
      me.userId.toString(),
      powerChannel.userName || '',
      powerChannel.password || '',
      powerChannel.name,
      {
        type: TrackingType.Realtime,
        serialNumber: station.data.trackingSerialNumber,
        serialNumbers: station.data.trackingSerialNumbers,
        trackingLocationUUID: chargingStationGroupUuid
      },
      false,
      sub => (internalState.powerSubscriptions = [sub]),
      message => {
        internalState.onMessageReceived();
        setOffline(false);
        setLastMessage(message);
      }
    );

    return () => internalState.close();
  }, [mqtt, chargingStationGroupUuid, station, me.userId, unavailable]);

  const status = unavailable ? undefined : getOnlineStatus(lastMessage, offline, unavailable);
  return [lastMessage, status];
}
