import {Invitation} from '../models/Invitation';

import {APIClient} from './APIClient';

export class InvitationAPI {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getInvitation(token: string): Promise<Invitation> {
    const url = `/api/v10/invitations/${token}`;
    return this.client.doGet(url);
  }

  acceptInvitation(token: string): Promise<void> {
    const url = `/api/v10/invitations/${token}/accept`;
    return this.client.doPost(url, {});
  }
}
